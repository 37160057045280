import { Formik, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import AppLoader from '../../../components/loaders/AppLoader';
import TextInput from '../../../components/UI/TextInput';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import config from '../../../../config';
import TextareaInput from '../../../components/UI/TextareaInput';
import convertJsonToFormData from '../../../helpers/helper';
import { LeftArrowSvg } from '../../../helpers/CommanUI';

const AddEditTestimonial = (props) => {
  const dispatch = useDispatch();
  const [record, setRecords] = useState(null);
  const [ready, setReady] = useState(false);
  const [images, setImages] = useState({});

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (params.id) {
      dispatch(showLoading());

      GET(`/users/getTestimonial/${params.id}`)
        .then(({ data }) => {
          setImages({
            ...images,
            testimonialImage: data.testimonial.image,
          });
          setRecords(data.testimonial);
          setReady(true);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();
        });
    } else {
      setReady(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!ready) {
    return <AppLoader />;
  }

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
          <div className="page-title">
            <h1 className="page-heading d-flex align-items-center">
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <LeftArrowSvg />
              </span>
              {record ? 'Edit' : 'Add'} Testimonial
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  title: record ? record.title : '',
                  comment: record ? record.comment : '',
                  name: record ? record.name : '',
                  designation: record ? record.designation : '',
                }}
                onSubmit={(values, { resetForm }) => {
                  dispatch(showLoading());
                  const payload = { ...values };

                  if (record) {
                    // update part here
                    payload.testimonial_uuid = record.uuid;
                    const editformData = convertJsonToFormData(payload);
                    POST(`/users/updateTestimonial`, editformData)
                      .then(({ data }) => {
                        dispatch(hideLoading());
                        dispatch(showModal('Testimonial data updated', true));
                        history.goBack();
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  } else {
                    const formData = convertJsonToFormData(payload);
                    POST('/users/addTestimonial', formData)
                      .then(({ data }) => {
                        dispatch(hideLoading());
                        dispatch(showModal('Testimonial Added successfully', true));
                        history.goBack();
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  }
                }}
                validationSchema={object().shape({
                  title: string().required('Title is required'),
                  comment: string().required('Comment is required'),
                  name: string().required('Customer Name is required'),
                  designation: string().required('Customer Designation is required'),
                })}
              >
                {(renderProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  } = renderProps;
                  return (
                    <form className="f-c-sm" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Customer Name</label>
                            <TextInput
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              error={touched.name && errors.name}
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Customer Designation</label>
                            <TextInput
                              type="text"
                              name="designation"
                              id="designation"
                              className="form-control"
                              error={touched.designation && errors.designation}
                              value={values.designation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Title</label>
                            <TextInput
                              type="text"
                              name="title"
                              id="title"
                              className="form-control"
                              error={touched.title && errors.title}
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Comment</label>
                            <Field
                              name="comment"
                              id="comment"
                              render={({
                                field: { name, onChange, value },
                                form: { touched, errors },
                              }) => (
                                <TextareaInput
                                  name="comment"
                                  id="comment"
                                  error={touched[name] && errors[name]}
                                  onChange={onChange}
                                  value={value}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Customer Photo</label>
                            {images.testimonialImage && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${
                                  images.testimonialImage
                                }`}
                              >
                                Customer Photo
                              </a>
                            )}
                            <input
                              type="file"
                              name="image"
                              id="image"
                              onChange={(event) => setFieldValue('image', event.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 ">
                        <button type="submit" className="btn btn-sm btn-primary px-5">
                          {record ? 'Update' : 'Save'}
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditTestimonial;
