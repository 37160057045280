import React, { useState, useEffect } from 'react';
import { capitalize, isEmpty } from 'lodash';
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
// import Chat from '../../../components/UI/Chat';
import { LeftArrowSvg } from '../../../helpers/CommanUI';
import { getDisputeStatusClassname } from '../../../helpers/helper';

const DisputeDetails = (props) => {
  const [details, setDetails] = useState({});
  const [chatbox, setChatbox] = useState(false);
  const [chatlistclient, setChatlistclient] = useState([]);
  const [msgclient, setMsgclient] = useState('');
  const [chatboxclient, setChatboxclient] = useState(false);
  const [chatlistclearingagent, setChatlistclearingagent] = useState([]);
  const [chatlistforwardingagent, setChatlistforwardingagent] = useState([]);
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();
  const params = new URLSearchParams(props.location.search).get('id');
  const serviceData = JSON.parse(localStorage.getItem('globalEnum'));
  const { user } = useSelector((state) => state.user);
  // console.log(user);
  const bid_id = params;
  useEffect(() => {
    getDisputesDetails();
  }, []);
  const getDisputesDetails = () => {
    // console.log('type', type);
    dispatch(showLoading());
    POST(`/jobs/getDispute`, { dispute_uuid: bid_id })
      .then(({ data }) => {
        // console.log('data', data);

        setDetails(data.dispute);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  //puser notification
  const envent_name = `Illuminate\\Notifications\\Events\\BroadcastNotificationCreated`;

  useEffect(() => {
    const pusher = new Pusher('2c4a0502ac9f198e2eaa', {
      cluster: 'ap2',
      encrypted: true,
    });
    //5edd2728-7c0f-4b9e-ae63-4272cc86c831
    const channel = pusher.subscribe(`Chat.User.${user.uuid}`);
    channel.bind(envent_name, (data1) => {
      if (data1.chat_type === 'clearing') {
        setChatlistclearingagent((prevState) => [...prevState, data1]);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
      }
      if (data1.chat_type === 'forwarding') {
        setChatlistforwardingagent((prevState) => [...prevState, data1]);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
      }
      if (data1.chat_type === 'client') {
        setChatlistclient((prevState) => [...prevState, data1]);
        const objDiv = document.getElementById('chat-tops');
        objDiv.scrollTop = objDiv.scrollHeight;
      }
      // console.log('get', data1);
      //console.log('unseenlist', unseenList);
      // setUnseenList((prevState) => [...prevState, { data: data1, create_at: date }]);
      // getChat();

      dispatch(showModal('you have a new message', true));
    });
    // Bind the event listener
  }, []);
  //chat box implemented code
  const getChat = () => {
    GET(`/chats/getByJob/${details.job.uuid}`)
      .then(({ data }) => {
        // console.log(data);
        setChatlistclient(data.chats.client);
        setChatlistclearingagent(data.chats.clearing_agent);
        setChatlistforwardingagent(data.chats.forwarding_agent);
        const objDiv = document.getElementById('chat-top');
        const objDivs = document.getElementById('chat-tops');
        objDiv.scrollTop = objDiv.scrollHeight;
        objDivs.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const chatToggle = () => {
    setChatbox(!chatbox);
    getChat();
    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };
  const chatToggleclient = () => {
    setChatboxclient(!chatboxclient);
    getChat();
    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  const sendMessage = () => {
    let message = 'Hi';
    if (!isEmpty(msg)) {
      message = msg;
    }
    const payLoad = {
      job_uuid: details.job.uuid,
      sender_uuid: user.uuid,
      receiver_uuid:
        details.job && details.job.clearing_agent_uuid
          ? details.job.clearing_agent_uuid
          : details.job.forwarding_agent_uuid,
      chat_type: details.job && details.job.clearing_agent_uuid ? 'clearing' : 'forwarding',
      message: message,
    };

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        // console.log('submit', data);
        if (details.job.clearing_agent_uuid) {
          setChatlistclearingagent((prevState) => [...prevState, data.chat]);
        } else {
          setChatlistforwardingagent((prevState) => [...prevState, data.chat]);
        }
        // getChat();
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButton = () => {
    sendMessage();
    setMsg('');
    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  // Press enter to send a message
  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      setMsg('');
      // const objDiv = document.getElementById('chat-top');
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  };
  //msg state change
  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };
  //end chat code
  //client chat
  const sendMessageclient = () => {
    let message = 'Hi';
    if (!isEmpty(msgclient)) {
      message = msgclient;
    }
    const payLoad = {
      job_uuid: details.job.uuid,
      sender_uuid: user.uuid,
      receiver_uuid: details.job.created_by,
      chat_type: 'client',
      message: message,
    };

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        setChatlistclient((prevState) => [...prevState, data.chat]);
        const objDiv = document.getElementById('chat-tops');
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButtonclient = () => {
    sendMessageclient();
    setMsgclient('');
    // const objDiv = document.getElementById('chat-tops');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  // Press enter to send a message
  const handleTextChangeclient = (e) => {
    if (e.key === 'Enter') {
      sendMessageclient();
      setMsgclient('');
      // const objDiv = document.getElementById('chat-tops');
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  };
  //msg state change
  const handleOnChangeclient = (e) => {
    setMsgclient(e.target.value);
  };

  return (
    <>
      <div className="page-content">
        <div className="page-title">
          <h1 className="page-heading d-flex align-items-center">
            <span onClick={() => props.history.goBack()}>
              <LeftArrowSvg />
            </span>
            Disputes
          </h1>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body p-5">
                {/* <h2>My Disputes</h2> */}
                <div className="operator-info">
                  <div className="disputes-block">
                    <div className="row">
                      <div className="col-sm-8">
                        <h5>
                          {(details.job &&
                            capitalize(serviceData.job.category[details.job.category])) ||
                            'N/A'}
                        </h5>
                        <h6>
                          {details.job && details.job.job_id}-
                          {details.job && details.job.is_forwarding_only ? 'F' : 'C'}
                        </h6>
                      </div>
                      <div className="col-sm-4" style={{ textAlign: 'right' }}>
                        <h4>
                          {details.job && details.job.is_forwarding_only
                            ? 'Transport Service'
                            : 'Clearing Service'}
                        </h4>
                      </div>
                    </div>
                    <p>
                      <strong>Description:</strong> {details && details.message}
                    </p>
                    <p>
                      <strong>Status: </strong>
                      <span
                        className={getDisputeStatusClassname(
                          details.status,
                          'badge  badge-success',
                        )}
                      >
                        {details &&
                          details.status &&
                          capitalize(serviceData.dispute_status[details.status])}
                      </span>
                    </p>
                  </div>
                  <div>
                    <h5>Evidence</h5>
                    {/* {details &&
                  details.files &&
                  details.files.map((obj, i) => (
                    <div className="disputes-img" key={i}>
                      <img src={`${baseURL}/${obj}`} title="GoAgent" alt="" />
                    </div>
                  ))} */}

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Chat
        name="Client"
        chatbox={chatboxclient}
        user={user}
        chatToggle={chatToggleclient}
        chatlist={chatlistclient}
        value={msgclient}
        handleTextChange={handleTextChangeclient}
        handleOnChange={handleOnChangeclient}
        sendButton={sendButtonclient}
      />
      {details.job && details.job.clearing_agent_uuid && (
        <Chat
          name="Clearing Agent"
          chatbox={chatbox}
          user={user}
          chatToggle={chatToggle}
          chatlist={chatlistclearingagent}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
        />
      )}
      {details.job && details.job.forwarding_agent_uuid && (
        <Chat
          name="Forwarding Agent"
          chatbox={chatbox}
          user={user}
          chatToggle={chatToggle}
          chatlist={chatlistforwardingagent}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
        />
      )} */}
    </>
  );
};

export default DisputeDetails;
