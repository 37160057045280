import React from 'react';
import classnames from 'classnames';

export const Label = ({ error, className, children, ...props }) =>
  children ? (
    <label className="form-label" {...props}>
      {children}
    </label>
  ) : null;

export const InputFeedback = ({ error }) => (error ? <div className="invalid-feedback">{error}</div> : null);

const TextField = ({ type, id, formGroupClass, label, error, value, onChange, className, ...props }) => {
  const InputClasses = classnames(
    'form-control',
    {
      'is-invalid': !!error,
    },
    className,
  );
  const FormGroup = classnames('form-group', formGroupClass);

  return (
    <div className={FormGroup}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input id={id} className={InputClasses} type={type ? type : 'text'} value={value} onChange={onChange} {...props} />
      <InputFeedback error={error} />
    </div>
  );
};

export default TextField;
