import { Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Services
import { Redirect } from 'react-router-dom';
import { object, string } from 'yup';
// Images
import TextInput from '../components/UI/TextInput';
import { showLoading } from '../redux/loader/LoaderActions';
import { login } from '../redux/user/UserActions';
import PasswordField from '../components/Password';

const LoginForm = (props) => (
  <Formik
    initialValues={{ email: '', password: '' }}
    onSubmit={(values) => {
      props.onSubmit(values);
    }}
    validationSchema={object().shape({
      email: string()
        .email('Enter a valid email')
        .required('Email is required'),
      password: string()
        .min(8, 'Password must contain at least 8 characters')
        .required('Enter your password'),
    })}
  >
    {(renderProps) => {
      const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      } = renderProps;
      return (
        <form onSubmit={handleSubmit}>
          <TextInput
            id="email"
            type="email"
            name={'email'}
            label=""
            placeholder="Email"
            className="form-control input-outline"
            error={touched.email && errors.email}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <PasswordField
            id="password"
            type="password"
            name={'password'}
            placeholder="Password"
            error={touched.password && errors.password}
            password={values.password}
            showPassword={values.showP}
            onChange={handleChange}
            onBlur={handleBlur}
            toggleShowPw={() => setFieldValue('showP', values.showP ? false : true)}
          />

          <div className="form-group mt-3">
            <button className="btn btn-primary btn-block btn-sm">Sign in</button>
          </div>
        </form>
      );
    }}
  </Formik>
);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLoginSubmit = (formData) => {
    const { dispatch } = this.props;
    dispatch(showLoading());
    dispatch(login(formData));
  };

  render() {
    if (localStorage.getItem('_GATKN') && localStorage.getItem('_GATKN') !== undefined) {
      return <Redirect from={`/`} to={`/admin`} />;
    }

    return (
      <div className="main-wrapper auth-wrapper">
        <div className="auth-content">
          <div className="content-form">
            <div className="card-form">
              <div className="auth-heading text-center">
                {/* <img src={'/images/logo.png'} className="img-fluid " width="60px" alt="" /> */}
                <h2>GoAgent</h2>

                <h2>
                  {/* Sign in */}
                  <span className="font-weight-light text-muted d-block">
                    Sign in to access dashboard.
                  </span>
                </h2>
              </div>
              <LoginForm onSubmit={this.handleLoginSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps, null)(Login);
