import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { getQueryParamValue } from '../../../helpers/helper';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { ActionButton } from '../../../helpers/CommanUI';
import { usePagination } from '../../../helpers/customHooks';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'Title', value: 'action', class: '' },
  { width: '', name: 'Details', value: 'action', class: '' },
  { width: '', name: 'responsibilities', value: 'action', class: '' },
  { width: '', name: 'requirements', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

const JobList = (props) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const getJobList = (page, perPage) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };
    POST(`/career/careers`, payload)
      .then(({ data }) => {
        setTotalPages(data.count / perPage);
        setRecords(data.career);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    getJobList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  const handleDelete = (id) => {
    dispatch(showLoading());
    POST(`/career/deleteCareer`, { career_uuid: id })
      .then(() => {
        dispatch(hideLoading());
        dispatch(showModal('Career Job deleted successfully', true));
        getJobList(selectedPage, itemsPerPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const formatList = (data) => {
    let list = 'N/A';
    if (!isEmpty(data)) {
      list = (
        <ul style={{ paddingLeft: 10 }}>
          {data.map((i, index) => (index <= 2 ? <li key={index}>{i}</li> : null))}
        </ul>
      );
    }
    return list;
  };

  return (
    <div className="page-content">
      <div className="page-title page-title-flex">
        <h1 className="page-heading">Career Jobs</h1>
        <span
          onClick={() => {
            history.push('/admin/career/add');
          }}
          className="btn btn-sm btn-primary"
        >
          Add Career Job
        </span>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead">
                    <tr>
                      {TABLEHEADER.map((x, index) => {
                        return (
                          <th className={x.class} width={x.width} key={index}>
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {records.length > 0 && (
                    <tbody className="tbody">
                      {records.map((x, index) => (
                        <tr key={index}>
                          <td>{x.title || 'N/A'}</td>
                          <td className="cjdetailsColumn">
                            <div>{x.details}</div>
                          </td>
                          <td className="cjListColumn">
                            <>
                              {formatList(x.responsibilities)}
                              ...
                            </>
                          </td>
                          <td className="cjListColumn">
                            <>
                              {formatList(x.requirements)}
                              ...
                            </>
                          </td>
                          <td className="actionColumn">
                            <ActionButton
                              name="career"
                              id={x.uuid}
                              editRoute={`/admin/career/edit/${x.uuid}`}
                              onDeleteRecord={handleDelete}
                              onView={() => history.push(`/admin/career/view/${x.uuid}`)}
                              hideSuspendBtn
                              hideRemoveSuspensionBtn
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {records.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobList;
