import React, { Component } from 'react';
import { connect } from 'react-redux';
// Services
import { Redirect } from 'react-router-dom';
import history from '../helpers/history';

class Page404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (localStorage.getItem('_GATKN') && localStorage.getItem('_GATKN') !== undefined) {
      return <Redirect from={`/`} to={`/admin`} />;
    }
    return (
      <div className="main-wrapper auth-wrapper">
        <div className="auth-content">
          <div className="content-form">
            <div className="card-form">
              <div className="auth-heading text-center">
                <h2>
                  <span className="font-weight-light text-muted d-block">404 Page Not Found!</span>
                  <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
                    <span className="text-d-line tdl-lg c-pointer" onClick={() => history.push('/')}>
                      Back to home
                    </span>
                  </p>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps, null)(Page404);
