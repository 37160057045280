import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';
import swal from 'sweetalert';
import { DeleteSvg, ViewSvg } from '../../../helpers/CommanUI';
import { user_types } from '../../../helpers/constants';
import {
  CSSSTRING,
  _dateFormatter,
  getCreatedBy,
  addQueryParamToUrl,
  getQueryParamValue,
  displayAmount,
} from '../../../helpers/helper';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST, DELETE } from '../../../services/rest.service';
import Pagination from '../../../components/Pagination';
import BidDetailsModal from '../../../components/BidDetailsModal';
import BidList from './BidList';
import TruckDetailsModal from './TruckInformationModal';
import JobReviewsListModal from './JobReviewsListModal';
import SearchBar from '../../../components/SearchBar';
import AssignAgentToJobModal from './AssignAgentToJobModal';

const TABLEHEADER = [
  { width: '', name: 'Job Id', value: 'action', class: '' },
  { width: '', name: 'Description', value: 'action', class: '' },
  { width: '', name: 'Bid Details', value: 'action', class: '' },
  { width: '', name: 'Created At', value: 'action', class: '' },
  { width: '', name: 'Created By', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

const JOB_TYPE_ALL = 'all';
const JOB_TYPE_ONGOING = 'ongoing';
const JOB_TYPE_UNDER_BIDDING = 'under-biding';
const JOB_TYPE_UNDER_REVIEW = 'under-review';
const JOB_TYPE_COMPLETED = 'completed';

const jobStatusOptions = [
  {
    value: JOB_TYPE_ALL,
    label: 'All',
  },
  {
    value: JOB_TYPE_ONGOING,
    label: 'Ongoing',
  },
  {
    value: JOB_TYPE_UNDER_BIDDING,
    label: 'Under Biding',
  },
  {
    value: JOB_TYPE_UNDER_REVIEW,
    label: 'Under Review',
  },
  {
    value: JOB_TYPE_COMPLETED,
    label: 'Completed',
  },
];

class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      currentPage: getQueryParamValue(props.location, 'page') || 0,
      pageLimit: getQueryParamValue(this.props.location, 'perPage') || 10,
      totalRecords: 0,
      isSearching: false,
      searchText: getQueryParamValue(this.props.location, 'searchText') || '',
      sortingSequence: 'desc',
      sortingValue: 'createdAt',
      region: null,
      acceptsMarketing: false,
      userType: user_types[0],
      jobStatus: getQueryParamValue(this.props.location, 'jobStatus') || JOB_TYPE_ALL,
      viewBid: null,
      jobId: '',
    };
  }

  componentDidMount() {
    const queryParams = this.props.location.search;
    let { page } = queryString.parse(queryParams);

    if (page) {
      this.getPage(page);
    } else {
      this.getPage(1);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      const queryParams = this.props.location.search;
      let { page } = queryString.parse(queryParams);

      if (page) {
        this.getPage(page);
      } else {
        this.getPage(1);
      }
    }
  }

  getPage = (page) => {
    const { dispatch } = this.props;
    const queryParams = this.props.location.search;
    let { searchText, jobStatus } = queryString.parse(queryParams);
    const { pageLimit } = this.state;
    const searchname = searchText || '';

    const payload = {
      page: Number(page),
      per_page: pageLimit,
    };

    if (jobStatus) {
      const statuses = [];

      // 1. Jobs under Review : 6,3,11, 12 (3 - Suspended, 11 - Forwarding only, 12- forwarding only rejected)
      // 2. Jobs under Bidding : 5,9 (9 - Bid Selected)
      // 3. Jobs in Progress : 1
      // 4. Completed Jobs : 8

      switch (jobStatus) {
        case JOB_TYPE_ALL:
          break;
        case JOB_TYPE_ONGOING:
          statuses.push(1);
          break;
        case JOB_TYPE_UNDER_BIDDING:
          statuses.push(5, 9);
          break;
        case JOB_TYPE_UNDER_REVIEW:
          statuses.push(6, 3, 11, 12);
          break;
        case JOB_TYPE_COMPLETED:
          statuses.push(8);
          break;
        default:
          break;
      }
      payload.status = statuses;
    }

    if (searchText) payload.search = searchText;

    dispatch(showLoading());
    POST(`/jobs`, payload)
      .then(({ data }) => {
        //console.log(data);
        this.setState({
          records: data.jobs,
          totalRecords: Math.ceil(data.total_count / pageLimit),
          isSearching: false,
          currentPage: Number(page),
          jobStatus: jobStatus,
          searchText: searchname,
        });

        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());

        dispatch(showModal(err.message));
      });
  };

  toggleSortingOrder = (column) => {
    if (column !== 'action' && column !== 'status') {
      let seq = '';
      if (this.state.sortingValue === column) {
        seq = this.state.sortingSequence === 'asc' ? 'desc' : 'asc';
      } else {
        seq = 'asc';
      }
      this.setState(
        {
          sortingSequence: seq,
          sortingValue: column,
        },
        () => {
          this.getPage(this.state.currentPage);
        },
      );
    }
  };

  handlePageClick = (e) => {
    const page = e.selected + 1;
    addQueryParamToUrl(history, 'page', page);
    this.setState({
      currentPage: page,
    });
  };

  handleSetPageLimit = (e) => {
    const val = e.target.value;
    addQueryParamToUrl(history, 'perPage', val);
    this.setState({
      pageLimit: val,
    });
    this.handlePageClick({ selected: 0 });
  };

  closeBidDetailsModal = () => this.setState({ viewBid: null, jobCustomDuty: 0 });

  toggleJobId = (id) => {
    if (id !== this.state.jobId) {
      this.setState({ jobId: id });
    } else {
      this.setState({ jobId: '' });
    }
  };

  toggleReviewListModal = (id) => {
    this.setState((ps) => {
      return {
        showReviewListModal: !ps.showReviewListModal,
        reviewAgentId: id || '',
      };
    });
  };

  toggleAssignAgentModal = (job) => {
    this.setState((ps) => {
      return {
        showAssignAgentModal: !ps.showAssignAgentModal,
        jobDetails: job || null,
      };
    });
  };

  toggleTruckDetailsModal = (id) => {
    this.setState((ps) => {
      return {
        showTruckDetailsModal: !ps.showTruckDetailsModal,
        truckId: id || '',
      };
    });
  };

  handleJobStausFilterChange = (e) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.jobStatus = e.value;
    parsed.page = 1;
    history.push({
      pathname: `/admin/jobs`,
      search: queryString.stringify(parsed),
    });
  };

  handleSearch = (text) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.searchText = text;
    parsed.page = 1;
    history.push({
      pathname: `/admin/jobs`,
      search: queryString.stringify(parsed),
    });
  };

  handleSearchClear = () => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.searchText = '';
    parsed.page = 1;
    history.push({
      pathname: `/admin/jobs`,
      search: queryString.stringify(parsed),
    });
  };

  onAssignAgent = () => {
    this.setState({
      showAssignAgentModal: false,
      jobDetails: null,
      jobId: null,
    });
  };

  // handle delete job
  handleDeleteJob = (jobId) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Delete this Job?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        const { dispatch } = this.props;
        dispatch(showLoading());
        DELETE(`/jobs/${jobId}`)
          .then(() => {
            dispatch(showModal('Job Deleted Sucessfully!', true));
            dispatch(hideLoading());
            // re fetch jobs
            const queryParams = this.props.location.search;
            let { page } = queryString.parse(queryParams);
            if (page) this.getPage(page || 1);
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
          });
      }
    });
  };

  handleCompleteJob = (job) => {
    const jobType = job.is_forwarding_only ? 'forwarding' : 'clearing';
    const div = document.createElement('div');
    const para = document.createElement('p');
    const para2 = document.createElement('p');
    para2.style = 'font-weight: bold';

    const msg1 = `Are you sure you want to Complete this ${jobType} Job?`;
    para.textContent = msg1;

    if (jobType === 'clearing') {
      let allClearingMilestonesCompleted = true;
      const milestones = JSON.parse(job.clearing_cargo_status);
      Object.keys(milestones).forEach((m) => {
        if (milestones[m] == null) {
          allClearingMilestonesCompleted = false;
        }
      });
      if (!allClearingMilestonesCompleted)
        para2.textContent = 'Note: All clearing milestones are not completed!';
    }
    div.append(para, para2);
    swal({
      title: 'Are you sure?',
      content: div,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        const { dispatch } = this.props;
        dispatch(showLoading());

        const payLoad = {
          job_type: jobType,
          job_uuid: job.uuid,
          status: 8, // done
        };

        POST(`/jobs/changeStatus`, payLoad)
          .then(({ data }) => {
            dispatch(showModal('job completed', true));
            dispatch(hideLoading());
            // re fetch jobs
            const queryParams = this.props.location.search;
            let { page } = queryString.parse(queryParams);
            if (page) this.getPage(page || 1);
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
          });
      }
    });
  };

  render() {
    const { records, sortingSequence, sortingValue } = this.state;
    const serviceData = JSON.parse(localStorage.getItem('globalEnum'));

    return (
      <div className="page-content" id="job-list">
        <div className="page-title page-title-flex">
          <h1 className="page-heading">Jobs</h1>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group mb-3">
                      <label>Job Status</label>
                      <Select
                        options={jobStatusOptions}
                        isMulti={false}
                        name="selectedService"
                        value={jobStatusOptions.find((js) => js.value == this.state.jobStatus)}
                        onChange={this.handleJobStausFilterChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group mb-3">
                      <label>Search Job</label>
                      <SearchBar
                        id="search"
                        onSearch={this.handleSearch}
                        onClearSearchText={this.handleSearchClear}
                        value={this.state.searchText}
                        className="height-38px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead">
                      <tr>
                        {TABLEHEADER.map((x, index) => {
                          return (
                            <th className={x.class} width={x.width} key={index}>
                              {x.name}
                              {x.value !== 'action' && x.value !== 'status' && (
                                <span
                                  className={
                                    x.value !== 'action' && x.value !== 'status'
                                      ? `sort-icon ${CSSSTRING(x, sortingSequence, sortingValue)}`
                                      : 'sort-icon'
                                  }
                                  onClick={() => {
                                    if (x.value !== 'action' && x.value !== 'status') {
                                      this.toggleSortingOrder(x.value);
                                    }
                                  }}
                                ></span>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    {records.length > 0 && (
                      <tbody>
                        {records.map((x, index) => (
                          <Fragment key={index}>
                            <tr
                              className={classnames('nopadding', {
                                'bidlist-open-tr1': this.state.jobId === x.uuid,
                              })}
                            >
                              <td>
                                {x.job_id}-{x.is_forwarding_only ? 'T' : 'C'}
                              </td>
                              <td>
                                <h6>
                                  {x.category &&
                                    serviceData &&
                                    capitalize(serviceData.job.category[x.category])}
                                </h6>
                                <p>
                                  {x.type &&
                                    serviceData &&
                                    capitalize(serviceData.job.vehicle_type[x.type])}
                                </p>
                                <p>
                                  {x.type_of_cargo &&
                                    serviceData &&
                                    capitalize(serviceData.job.type_of_cargo[x.type_of_cargo])}
                                </p>
                              </td>
                              <td>
                                <p>
                                  Winning Bid:
                                  {x.higest_bid ? (
                                    <strong className="text-success">
                                      {displayAmount(x.winning_bid)}
                                    </strong>
                                  ) : (
                                    'N/A'
                                  )}
                                </p>
                                <p>
                                  Highest Bid:{' '}
                                  {x.higest_bid ? (
                                    <strong>{displayAmount(x.higest_bid)}</strong>
                                  ) : (
                                    'N/A'
                                  )}
                                </p>
                                {(x.clearing_accepted_bid || x.forwarding_accepted_bid) && (
                                  <a
                                    className="detailsdiv color-secondary"
                                    onClick={() =>
                                      this.setState({
                                        viewBid:
                                          x.clearing_accepted_bid || x.forwarding_accepted_bid,
                                        jobCustomDuty: x.custom_duty,
                                      })
                                    }
                                  >
                                    View Accepted Bid
                                  </a>
                                )}
                              </td>
                              <td>{_dateFormatter(x.created_at)}</td>
                              <td>{getCreatedBy(x.client)}</td>
                              <td>
                                <button
                                  className="btn btn-clear p-0 mr-2"
                                  type="button"
                                  onClick={() => {
                                    this.props.history.push(`/admin/job/view/${x.uuid}`);
                                  }}
                                >
                                  <span>
                                    <ViewSvg />
                                  </span>
                                </button>
                                {this.state.jobStatus === JOB_TYPE_UNDER_BIDDING && (
                                  <button
                                    onClick={() => this.toggleJobId(x.uuid)}
                                    className="btn btn-primary btn-sm ml-2"
                                    type="button"
                                  >
                                    View Bids
                                  </button>
                                )}
                                {this.state.jobStatus === JOB_TYPE_UNDER_BIDDING && (
                                  <button
                                    onClick={() => this.toggleAssignAgentModal(x)}
                                    className="btn btn-primary btn-sm ml-2"
                                    type="button"
                                  >
                                    Assign Agent
                                  </button>
                                )}
                                {this.state.jobStatus === JOB_TYPE_UNDER_REVIEW && (
                                  <button
                                    onClick={() => this.handleDeleteJob(x.uuid)}
                                    className="btn btn-clear p-0"
                                  >
                                    <DeleteSvg />
                                  </button>
                                )}
                                {this.state.jobStatus === JOB_TYPE_ONGOING && (
                                  <button
                                    onClick={() => this.handleCompleteJob(x)}
                                    className="btn btn-primary btn-sm ml-2"
                                    type="button"
                                  >
                                    Complete Job
                                  </button>
                                )}
                              </td>
                            </tr>
                            <tr
                              className={classnames('nopadding', {
                                'bidlist-open-tr2': this.state.jobId === x.uuid,
                              })}
                            >
                              {this.state.jobId === x.uuid && (
                                <td colSpan="6" className="bidlist-td">
                                  <BidList
                                    isOpen={this.state.jobId === x.uuid}
                                    toggleReviewListModal={this.toggleReviewListModal}
                                    job={x}
                                    toggleTruckDetailsModal={this.toggleTruckDetailsModal}
                                  />
                                </td>
                              )}
                            </tr>
                          </Fragment>
                        ))}
                      </tbody>
                    )}
                  </table>
                  {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {records.length > 0 && (
                <Pagination
                  pageCount={this.state.totalRecords}
                  onPageChange={this.handlePageClick}
                  selectedPage={this.state.currentPage}
                  setItemsPerPage={this.handleSetPageLimit}
                  itemsPerPage={this.state.pageLimit}
                />
              )}
            </div>
          </div>
        </div>
        <BidDetailsModal
          toggle={this.closeBidDetailsModal}
          isOpen={!!this.state.viewBid}
          bidId={this.state.viewBid}
          container="job-list"
          customDuty={this.state.jobCustomDuty}
        />
        <TruckDetailsModal
          isOpen={this.state.showTruckDetailsModal}
          toggle={this.toggleTruckDetailsModal}
          truckId={this.state.truckId}
          serviceData={serviceData}
          container="job-list"
        />
        <JobReviewsListModal
          isOpen={this.state.showReviewListModal}
          toggle={this.toggleReviewListModal}
          reviewAgentId={this.state.reviewAgentId}
          container="job-list"
        />
        {this.state.showAssignAgentModal && (
          <AssignAgentToJobModal
            isOpen={this.state.showAssignAgentModal}
            toggle={this.toggleAssignAgentModal}
            container="job-list"
            jobDetails={this.state.jobDetails}
            onAssign={this.onAssignAgent}
            toggleTruckDetailsModal={this.toggleTruckDetailsModal}
            toggleReviewListModal={this.toggleReviewListModal}
          />
        )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps, null)(JobList);
