import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalRecords, onClick, currentPage }) => {
  return (
    <ReactPaginate
      previousLabel={
        <svg xmlns="http://www.w3.org/2000/svg" width="11.644" height="10.055">
          <g data-name="double arrow left pagignation">
            <g data-name="Group 2663">
              <path
                data-name="Path 17"
                d="M.181 4.591L4.576.181a.628.628 0 11.888.888L1.5 5.028l3.964 3.958a.636.636 0 010 .888.636.636 0 01-.888 0L.181 5.479a.636.636 0 010-.888z"
                fill="#878787"
              />
            </g>
            <g data-name="Group 2827">
              <path
                data-name="Path 17"
                d="M6.181 4.591l4.395-4.41a.628.628 0 11.888.888L7.5 5.028l3.964 3.958a.636.636 0 010 .888.636.636 0 01-.888 0L6.181 5.479a.636.636 0 010-.888z"
                fill="#878787"
              />
            </g>
          </g>
        </svg>
      }
      forcePage={currentPage - 1}
      nextLabel={
        <svg
          data-name="double arrow right pagignation"
          xmlns="http://www.w3.org/2000/svg"
          width="11.644"
          height="10.055"
        >
          <g data-name="Group 2663">
            <path
              data-name="Path 17"
              d="M11.464 4.591L7.069.181a.636.636 0 00-.888 0 .636.636 0 000 .888l3.958 3.959-3.958 3.958a.636.636 0 000 .888.636.636 0 00.888 0l4.395-4.4a.636.636 0 000-.883z"
              fill="#878787"
            />
          </g>
          <g data-name="Group 2827">
            <path
              data-name="Path 17"
              d="M5.464 4.591L1.069.181a.636.636 0 00-.888 0 .636.636 0 000 .888l3.958 3.959L.181 8.986a.636.636 0 000 .888.636.636 0 00.888 0l4.395-4.4a.636.636 0 000-.883z"
              fill="#878787"
            />
          </g>
        </svg>
      }
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={totalRecords}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      nextClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextLinkClassName={'page-link'}
      pageClassName={'page-item'}
      onPageChange={onClick}
    />
  );
};

export default Pagination;
