import React, { useEffect, useState } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import { object, string, array } from 'yup';
import AppLoader from '../../../components/loaders/AppLoader';
import TextInput from '../../../components/UI/TextInput';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import TextareaInput from '../../../components/UI/TextareaInput';
import { LeftArrowSvg } from '../../../helpers/CommanUI';

const AddEditJob = (props) => {
  const dispatch = useDispatch();
  const [record, setRecords] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (params.id) {
      dispatch(showLoading());

      POST('/career/careers', {career_uuid: params.id})
        .then(({ data }) => {
          setRecords(data.career);
          setReady(true);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();
        });
    } else {
      setReady(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (!ready) {
    return <AppLoader />;
  }

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
          <div className="page-title">
            <h1 className="page-heading d-flex align-items-center">
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <LeftArrowSvg />
              </span>
              {record ? 'Edit' : 'Add'} Career Job
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                    title: record ? record.title : '',
                    details: record ? record.details :'',
                    responsibilities: record ? record.responsibilities : [''],
                    requirements: record ? record.requirements : [''],
                }}
                onSubmit={(values, { resetForm }) => {
                  dispatch(showLoading());
                  const payload = { 
                    ...values,
                    responsibilities: { ...values.responsibilities },
                    requirements: { ... values.requirements }
                  };

                  if (record) {
                  // update part here
                  payload.career_uuid = record.uuid;
                  POST(`/career/updateCareer`, payload)
                    .then(({ data }) => {
                      dispatch(hideLoading());
                      dispatch(showModal('Career Job data updated', true));
                      history.goBack();
                    })
                    .catch((err) => {
                      dispatch(showModal(err.message));
                      dispatch(hideLoading());
                    });

                  } else {
                    POST('/career/createCareer', payload)
                      .then(({ data }) => {
                        dispatch(hideLoading());
                        dispatch(showModal('Career Job Added successfully', true));
                        history.goBack();
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  }
                }}
                validationSchema={object().shape({
                  title: string().required('Title is required'),
                  details: string().required('Details is required'),
                  responsibilities: array().of(string().required('Responsibilities is required')),
                  requirements: array().of(string().required('Requirements is required')),
                })}
              >
                {(renderProps) => {
                  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = renderProps;
                  return (
                    <form className="f-c-sm" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="">Title</label>
                            <TextInput
                              type="text"
                              name="title"
                              id="title"
                              className="form-control"
                              error={touched.title && errors.title}
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="">Details</label>
                            <Field 
                              name="details"
                              id="details"
                              render={({ field:{name, onChange,value}, form: { touched, errors } }) => (
                                <TextareaInput 
                                  name="details"
                                  id="details"
                                  error={touched[name] && errors[name]}
                                  onChange={onChange}
                                  value={value}
                                />
                              )}                           
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="">Responsibilities</label>
                            <FieldArray
                              name="responsibilities"
                              render={({remove, insert, push}) => (
                                <>
                                  {values.responsibilities && values.responsibilities.length > 0 && (
                                    values.responsibilities.map((responsibility, index) => (
                                      <div key={index} className="formArrayRepearer">
                                        <TextInput
                                          type="text"
                                          name={`responsibilities.${index}`}
                                          className="form-control"
                                          error={touched.responsibilities?.[index] && errors.responsibilities?.[index]}
                                          value={responsibility}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <img 
                                          src="/images/svg/dash-circle.svg" 
                                          width="30px" 
                                          onClick={() => remove(index)}
                                        />
                                      </div>
                                    ))
                                  )}
                                  <img 
                                    src="/images/svg/plus-circle.svg" 
                                    width="30px" 
                                    onClick={() => insert(values.responsibilities.length, "")}
                                  />
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="">Requirements</label>
                            <FieldArray
                              name="requirements"
                              render={({remove, insert, push}) => (
                                <>
                                  {values.requirements && values.requirements.length > 0 && (
                                    values.requirements.map((responsibility, index) => (
                                      <div key={index} className="formArrayRepearer">
                                        <TextInput
                                          type="text"
                                          name={`requirements.${index}`}
                                          className="form-control"
                                          error={touched.requirements?.[index] && errors.requirements?.[index]}
                                          value={responsibility}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <img 
                                          src="/images/svg/dash-circle.svg" 
                                          width="30px" 
                                          onClick={() => remove(index)}
                                        />
                                      </div>
                                    ))
                                  )}
                                  <img 
                                    src="/images/svg/plus-circle.svg" 
                                    width="30px" 
                                    onClick={() => insert(values.requirements.length, "")}
                                  />
                                </>
                              )}
                            />
                            </div>
                        </div>
                      </div>
                      <div className="mt-3 ">
                        <button type="submit" className="btn btn-sm btn-primary px-5">
                          {record ? 'Update' : 'Save'}
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditJob;
