import React from 'react';
import { isArray, isObject, isString, isEmpty, capitalize, has, debounce } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { DATE_FORMAT, TIME_FORMAT } from './constants';

export const PERSON_NAME = /^[a-z ,.'-]+$/i;

const convertJsonToFormData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (isString(value)) {
      formData.append(key, value);
    } else if (isObject(value)) {
      formData.append(key, value, value.name);
    } else if (isArray(value)) {
      formData.append(key, JSON.stringify(value));
    }
  });

  return formData;
};
export default convertJsonToFormData;

export const CSSSTRING = (x, sortingSequence, sortingValue) => {
  if (sortingSequence === '') {
    return '';
  } else {
    if (sortingSequence === 'asc') {
      if (sortingValue === x.value) {
        return 'sorting_asc';
      } else {
        return '';
      }
    }
    if (sortingSequence === 'desc') {
      if (sortingValue === x.value) {
        return 'sorting_desc';
      } else {
        return '';
      }
    }
  }
};

export const _dateFormatter = (data) => moment(data).format(DATE_FORMAT);
export const _timeFormatter = (data) => moment(data).format(TIME_FORMAT);

export const slugToString = (slug) => {
  return slug.replace(/_/g, ' ');
};

export const getCreatedBy = (data) => {
  let name = '';
  if (data) {
    name += data.first_name || '';
    name += data.last_name ? ` ${data.last_name}` : '';
    name = capitalize(name);
  }
  return name;
};

export const formatAllStats = (data, serviceData) => {
  const statsData = [];

  try {
    if (!isEmpty(data) && serviceData) {
      // Users stats
      if (data.usersStats) {
        let totalUsers = 0;
        const userStats = [];

        const userTypes = Object.keys(serviceData.user_types);
        // console.log('userTypes',userTypes)
        if (data.usersStats.length) {
          // if data is available map it to user types accordingly

          // this array is used to keep track of types that matched and have data
          // it is used below to show the type with value 0
          const matched = [];

          data.usersStats.forEach((item) => {
            totalUsers += item.total_count;

            userTypes.forEach((uType) => {
              if (uType === item.type) {
                userStats.push({
                  title: `${serviceData.user_types[item.type]}s`,
                  value: item.total_count,
                });
                matched.push(uType);
              }
            });
          });

          if (matched.length) {
            userTypes.forEach((uType) => {
              if (!matched.includes(uType)) {
                userStats.push({
                  title: `${serviceData.user_types[uType]}s`,
                  value: 0,
                });
              }
            });
          }
        } else {
          // if no data then display count as 0 for user types
          userTypes.forEach((uType) => {
            userStats.push({
              title: `${serviceData.user_types[uType]}s`,
              value: 0,
            });
          });
        }

        userStats.unshift({
          title: 'Total Users',
          value: totalUsers,
        });

        statsData.push({
          title: 'Users Stats',
          value: userStats,
          icon: 'users.svg',
        });
      }

      // Cleaering job stats
      if (data.clearingJobsStats) {
        let totalClearingJobs = 0;
        const clearingJobStats = [];

        const clearingJobStatus = Object.keys(serviceData.job.clearing_status);

        if (data.clearingJobsStats.length) {
          // if data is available map it accordingly
          const matched = [];

          data.clearingJobsStats.forEach((item) => {
            totalClearingJobs += item.total_count;
            // console.log('clearingJobStatus',clearingJobStatus)

            clearingJobStatus.forEach((cjStatus) => {
              if (cjStatus === item.clearing_status) {
                clearingJobStats.push({
                  title: `${serviceData.job.clearing_status[item.clearing_status]}`,
                  value: item.total_count,
                });
                matched.push(cjStatus);
              }
            });
          });

          if (matched.length) {
            clearingJobStatus.forEach((cjStatus) => {
              if (!matched.includes(cjStatus)) {
                clearingJobStats.push({
                  title: `${serviceData.job.clearing_status[cjStatus]}`,
                  value: 0,
                });
              }
            });
          }
        } else {
          // if no data then display count as 0
          clearingJobStatus.forEach((cjStatus) => {
            clearingJobStats.push({
              title: `${serviceData.job.clearing_status[cjStatus]}`,
              value: 0,
            });
          });
        }

        clearingJobStats.unshift({
          title: 'Total Clearing Jobs',
          value: totalClearingJobs,
        });

        statsData.push({
          title: 'Clearing Jobs Stats',
          value: clearingJobStats,
          icon: 'jobs.svg',
        });
      }

      // Forwarding jobs stats
      if (data.forwardingJobsStats) {
        let totalForwardingJobs = 0;
        const forwardingJobStats = [];

        const forwardingJobStatus = Object.keys(serviceData.job.forwarding_status);

        if (data.forwardingJobsStats?.length) {
          // if data is available map it accordingly
          const matched = [];

          data.forwardingJobsStats.forEach((item) => {
            totalForwardingJobs += item.total_count;
            // console.log('forwardingJobStatus',forwardingJobStatus)

            forwardingJobStatus.forEach((fjStatus) => {
              if (fjStatus === item.forwarding_status) {
                forwardingJobStats.push({
                  title: `${serviceData.job.forwarding_status[item.forwarding_status]}`,
                  value: item.total_count,
                });
                matched.push(fjStatus);
              }
            });
          });

          if (matched.length) {
            forwardingJobStatus.forEach((fjStatus) => {
              if (!matched.includes(fjStatus)) {
                forwardingJobStats.push({
                  title: `${serviceData.job.forwarding_status[fjStatus]}`,
                  value: 0,
                });
              }
            });
          }
        } else {
          // if no data then display count as 0
          forwardingJobStatus.forEach((fjStatus) => {
            forwardingJobStats.push({
              title: `${serviceData.job.forwarding_status[fjStatus]}`,
              value: 0,
            });
          });
        }

        forwardingJobStats.unshift({
          title: 'Total Transport Jobs',
          value: totalForwardingJobs,
        });

        statsData.push({
          title: 'Transport Jobs Stats',
          value: forwardingJobStats,
          icon: 'jobs.svg',
        });
      }

      // Job Disputes stats
      if (data.disputesStats) {
        let totalJobDisputes = 0;
        const jobDisputesStats = [];
        const jobDisputeStatus = Object.keys(serviceData.dispute_status);

        if (data.disputesStats?.length) {
          // if data is available map it accordingly
          const matched = [];

          data.disputesStats.forEach((item) => {
            totalJobDisputes += item.total_count;

            jobDisputeStatus.forEach((dType) => {
              if (dType == item.status) {
                jobDisputesStats.push({
                  title: `${serviceData.dispute_status[item.status]}`,
                  value: item.total_count,
                });
                matched.push(dType);
              }
            });
          });

          if (matched.length) {
            jobDisputeStatus.forEach((fjStatus) => {
              if (!matched.includes(fjStatus)) {
                jobDisputesStats.push({
                  title: `${serviceData.dispute_status[fjStatus]}`,
                  value: 0,
                });
              }
            });
          }
        } else {
          // if no data then display count as 0
          jobDisputeStatus.forEach((dType) => {
            jobDisputesStats.push({
              title: `${serviceData.dispute_status[dType]}`,
              value: 0,
            });
          });
        }

        jobDisputesStats.unshift({
          title: 'Total Disputes',
          value: totalJobDisputes,
        });

        statsData.push({
          title: 'Disputes Stats',
          value: jobDisputesStats,
          icon: 'disputes.svg',
        });
      }
      // Truck Stats
      if (data.trucksStats) {
        let totalTrucks = 0;
        const trucksStats = [];
        const truckStatus = Object.keys(serviceData.truck.status);

        if (data.trucksStats?.length) {
          // if data is available map it accordingly
          const matched = [];

          data.trucksStats.forEach((item) => {
            totalTrucks += item.total_count;
            truckStatus.forEach((tType) => {
              if (tType == item.status) {
                trucksStats.push({
                  title: `${serviceData.truck.status[item.status]}`,
                  value: item.total_count,
                });
                matched.push(tType);
              }
            });
          });

          if (matched.length) {
            truckStatus.forEach((tType) => {
              if (!matched.includes(tType)) {
                trucksStats.push({
                  title: `${serviceData.truck.status[tType]}`,
                  value: 0,
                });
              }
            });
          }
        } else {
          // if no data then display count as 0
          truckStatus.forEach((tType) => {
            trucksStats.push({
              title: `${serviceData.truck.status[tType]}`,
              value: 0,
            });
          });
        }

        trucksStats.unshift({
          title: 'Total Trucks',
          value: totalTrucks,
        });

        statsData.push({
          title: 'Trucks Stats',
          value: trucksStats,
          icon: 'truck.svg',
        });
      }
      // User Source stats
      if (data.usersSource) {
        let totalSocialRegs = 0;
        const userSourceStats = [];
        // const rsn = Object.values(serviceData.registration_social_networks);

        if (data.usersSource?.length) {
          // if data is available map it accordingly
          // const matched = [];

          const socialsCount = {};

          data.usersSource.forEach((item) => {
            // if (item.source === null) {
            //   if (has(socialsCount, 'not-social')) {
            //     const temp = socialsCount['not-social'];
            //     socialsCount['not-social'] = temp + item.source_count;
            //   } else {
            //     socialsCount['not-social'] = item.source_count;
            //   }
            // } else {

            // }

            if (has(socialsCount, item.source_other)) {
              const temp = socialsCount[item.source_other];
              socialsCount[item.source_other] = temp + item.source_count;
            } else {
              if (item.source_other) socialsCount[item.source_other] = item.source_count;
            }
          });

          const socialsCountKeys = Object.keys(socialsCount);

          socialsCountKeys.forEach((k) => {
            // if (k === 'not-social') {
            //   userSourceStats.push({
            //     title: `Not Social`,
            //     value: socialsCount[k],
            //   });
            // } else {

            // }
            totalSocialRegs += socialsCount[k];
            userSourceStats.push({
              title: `${capitalize(k)}`,
              value: socialsCount[k],
            });
            // matched.push(k);
          });

          // if (matched.length) {
          //   rsn.forEach((sn) => {
          //     if (!matched.includes(sn)) {
          //       userSourceStats.push({
          //         title: `${capitalize(sn)}`,
          //         value: 0,
          //       });
          //     }
          //   });
          // }
        } else {
          // if no data then display count as 0
          // rsn.forEach((sn) => {
          //   userSourceStats.push({
          //     title: `${capitalize(sn)}`,
          //     value: 0,
          //   });
          // });
        }

        userSourceStats.unshift({
          title: 'Total Registrations',
          value: totalSocialRegs,
        });

        statsData.push({
          title: 'User Source Stats',
          value: userSourceStats,
          icon: 'social-network.svg',
        });
      }
    }
  } catch (error) {
    console.error('error', error);
  }

  return statsData;
};

export const isMobile = () => {
  return window.matchMedia('(max-width: 540px)');
};

export const formatAmount = (amount) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat(undefined, {
    // style: 'currency',
    // currency: 'NGN',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(amount); /* $2,500.00 */
};

export const displayAmount = (amount, dollar = false) => {
  const amt = formatAmount(amount);
  if (dollar) {
    return <>&#36;{amt}</>;
  }
  return <> &#8358;{amt}</>;
};

export const getRegYearType = (data) => {
  let regYearType = 0;
  if (data?.registration_application_status) {
    regYearType = 1;
  } else if (data?.ctc_cac_1_1) {
    regYearType = 2;
  } else if (data?.cac_2 || data?.cac_7) {
    regYearType = 3;
  }

  return regYearType;
};

export const extractUserDocs = (data) => {
  const docs = {};
  if (data?.documents) {
    data.documents.forEach((doc) => {
      docs[doc['document_name']] = doc['document_value'];
    });
  }
  return docs;
};

export const getName = (data) => {
  const name = [];
  if (data?.first_name) name.push(data.first_name);
  if (data?.last_name) name.push(data.last_name);
  if (data?.type === 'clearing_agent' || data?.type === 'forwarding_agent')
    name.push(data.company_name);
  return name.join(' ');
};

export const getDisputeStatusClassname = (status, classnames) => {
  // 1: "Under Review By Operator"
  // 2: "Approved By Operator"
  // 3: "Rejected By Operator"
  // 4: "Approved By Compliance Officer"
  // 5: "Rejected By Compliance Officer"
  // 6: "Resolved By Compliance Officer"
  let cn = 'p-2 alert ';
  switch (status) {
    case 1:
      cn += 'alert-info';
      break;
    case 2:
      cn += 'alert-warning';
      break;
    case 3:
      cn += 'alert-danger';
      break;
    case 4:
      cn += 'alert-warning';
      break;
    case 5:
      cn += 'alert-danger';
      break;
    case 6:
      cn += 'alert-success';
      break;
    default:
      cn += 'alert-info';
      break;
  }
  return cn + ' ' + classnames;
};

export const addQueryParamToUrl = (history, param, value) => {
  if (history?.replace) {
    const url = new URL(window.location.href);
    if (url.searchParams.has(param)) {
      // replace param value
      url.searchParams.set(param, value);
    } else {
      // Add nwe parameter.
      url.searchParams.append(param, value);
    }
    history.replace(url.search);
  }
};

export const getQueryParamValue = (location, param) => {
  let paramVal = '';
  if (location?.search) {
    const url = new URL(window.location.href);
    paramVal = url.searchParams.get(param);
  }
  return paramVal;
};

export const asyncDebounce = (func, wait) => {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);

  return (...args) => {
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    });
  };
};

const getFullName = (user) => `${capitalize(user.first_name)} ${capitalize(user.last_name)}`;

export const getClientName = (client) => {
  const cType = client.sub_type || client.type;
  let name = '';
  if (cType === 'application_client') {
    name = getFullName(client);
  } else {
    name = capitalize(client.company_name);
  }
  return name || 'N/A';
};

export const getClientAddress = (client) => {
  const cType = client.sub_type || client.type;
  let address = '';
  if (cType === 'application_client') {
    address = client.address;
  } else {
    address = client.company_address;
  }
  return address || 'N/A';
};

export const getUserName = (user) => {
  let name = '';
  if (user.type === 'clearing_agent' || user.type === 'forwarding_agent') {
    name = capitalize(user?.company_name);
  } else if (
    user.type === 'managenmnt_operator' ||
    user.type === 'truck_inspector' ||
    user.type === 'truck_inspector' ||
    user.type === 'legal_lawer' ||
    user.type === 'work_supervisor'
  ) {
    name = getFullName(user);
  } else {
    name = getClientName(user);
  }
  return name || 'N/A';
};

export const weightFormat = (weight) => {
  if (!weight) return;
  let unit = 'tons';
  if (weight === 1) {
    unit = 'ton';
  }

  return weight + ' ' + unit;
};

export const getErrorClassNames = (error) =>
  classnames('form-control', {
    'is-invalid': !!error,
  });

export const checkAgentOperatesOnThisPort = (agentPortsOfOperations, portId) => {
  const res = agentPortsOfOperations.find(
    (p) => p.document_verified === 1 && p.port_uuid === portId,
  );
  // console.log('>>> agentPortsOfOperations, portId', agentPortsOfOperations, portId, res);

  return !!res;
};
