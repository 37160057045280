import React from 'react';
import Select from 'react-select';
import { Formik } from 'formik';
import { get } from 'lodash';
import { object, string, array, number } from 'yup';
import Chip from '@material-ui/core/Chip';
import TextInput from '../../../components/UI/TextInput';
import { emailRegex } from '../../../helpers/constants';

const payments_type = [
  { value: 'paypal', label: 'Paypal' },
  { value: 'paystack', label: 'Paystack' },
  { value: 'flutterwave', label: 'Flutterwave' },
  { value: 'remita', label: 'Remita' },
];

const ImportantSettings = ({ onSubmit, settings }) => {
  return (
    <Formik
      initialValues={{
        site_name: settings ? settings.site_name || '' : '',
        payments: settings ? settings.payments || '' : '',
        commision_percentage: settings ? settings.commision_percentage || '' : '',
        dispute_date: settings ? settings.dispute_date || '' : '',
        clearing_registration_fee: settings ? settings.clearing_registration_fee || '' : '',
        forwarding_registration_fee: settings ? settings.forwarding_registration_fee || '' : '',
        admin_email: settings ? settings.admin_email || '' : '',
        admin_contact: settings ? settings.admin_contact || '' : '',
        clearing_surcharge: settings ? settings.clearing_surcharge || '' : '',
        forwarding_surcharge: settings ? settings.forwarding_surcharge || '' : '',
        threshold: settings?.threshold || '',
        counter_bid_amount: settings?.counter_bid_amount || '',
        clearing_bid_days: settings?.clearing_bid_days || '',
        forwarding_bid_days: settings?.forwarding_bid_days || '',
        job_payment_hours: settings?.job_payment_hours || '',
        high_risk_goods: settings?.high_risk_goods || '',
        medium_risk_goods: settings?.medium_risk_goods || '',
        low_risk_goods: settings?.low_risk_goods || '',
        insurance_company_email:
          settings?.email_list?.find((e) => e.type === 'insuarance_email')?.value || '',
        insurance_cc_email: '',
        iccEmails:
          settings?.email_list?.find((e) => e.type === 'insuarance_cc_emails')?.value || [],
        accounts_team_email:
          settings?.email_list?.find((e) => e.type === 'account_email')?.value || '',

        dollar_exchange_rate: settings?.dollar_exchange_rate || '',
        min_bid_vehicle_roro: settings?.min_bid_vehicle_roro || '',
        min_bid_vehicle_container: settings?.min_bid_vehicle_container || '',
        min_bid_general_cargo: settings?.min_bid_general_cargo || '',
        min_bid_bulk_cargo: settings?.min_bid_bulk_cargo || '',
      }}
      enableReinitialize
      onSubmit={(values) => {
        // format the emails
        const emailList = [];
        if (values.iccEmails.length > 0) {
          emailList.push({
            type: 'insuarance_cc_emails',
            value: values.iccEmails,
          });
        }
        if (values.insurance_company_email) {
          emailList.push({
            type: 'insuarance_email',
            value: values.insurance_company_email,
          });
        }
        if (values.accounts_team_email) {
          emailList.push({
            type: 'account_email',
            value: values.accounts_team_email,
          });
        }

        const payload = {
          ...values,
          email_list: emailList,
        };

        delete payload.iccEmails;
        delete payload.insurance_company_email;
        delete payload.accounts_team_email;
        delete payload.insurance_cc_email;
        onSubmit(payload);
      }}
      validationSchema={object().shape({
        site_name: string().required('Site Name is required'),
        payments: array()
          .of(
            object().shape({
              value: string().required('value is required'),
              label: string().required('label is required'),
            }),
          )
          .nullable()
          .required('Payment Method is required'),
        // commision_percentage: string().required('Commision Percentage is required'),
        forwarding_registration_fee: number().required('Registration Fee is required'),
        clearing_registration_fee: number().required('Registration Fee is required'),
        clearing_surcharge: number().required('Clearing Surcharge is required'),
        forwarding_surcharge: number().required('Transport Surcharge is required'),
        dispute_date: number().required('Disputes Validity is required'),
        admin_email: string()
          .email('Enter a valid email')
          .required('Email is required'),
        admin_contact: string().required('Contact is required'),
        threshold: number().required('Payment Threshold is required'),
        counter_bid_amount: number().required('Counter Bid Amount is required'),
        clearing_bid_days: number().required('Clearing Bid Time is required'),
        forwarding_bid_days: number().required('Transport Bid Time is required'),
        job_payment_hours: number().required('Job Payment Hours is required'),
        high_risk_goods: number().required('Goods - High Risk Percentage is required'),
        medium_risk_goods: number().required('Goods - Medium Risk Percentage is required'),
        low_risk_goods: number().required('Goods - Low Risk Percentage is required'),
        insurance_company_email: string()
          .email('Enter a valid email')
          .required('Email is required'),
        insurance_cc_email: string().email('Enter a valid email'),
        // accounts_team_email: string().email('Enter a valid email'),
        dollar_exchange_rate: number().required('Dollar Exchange Rate is required'),
        min_bid_vehicle_roro: number().required('Minimum Bid Amount - RORO is required'),
        min_bid_vehicle_container: number().required(
          'Minimum Bid Amount - Vehicle Container is required',
        ),
        min_bid_general_cargo: number().required(
          'Minimum Bid Amount - General Container is required',
        ),
        min_bid_bulk_cargo: number().required('Minimum Bid Amount - Bulk Cargo is required'),
      })}
    >
      {(renderProps) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
          setFieldError,
          setFieldTouched,
          resetForm,
        } = renderProps;
        // console.log('>> VTE', { values, touched, errors });
        return (
          <form className="f-c-sm">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Site Name</label>
                  <TextInput
                    type="text"
                    name="site_name"
                    id="site_name"
                    className="form-control"
                    error={touched.site_name && errors.site_name}
                    value={values.site_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Payment Method</label>
                  <Select
                    options={payments_type}
                    isMulti={true}
                    name="payments"
                    value={values.payments}
                    // isDisabled={record}

                    onChange={(e) => {
                      // console.log(e);
                      setFieldValue('payments', e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor:
                          get(errors, 'payments') && get(touched, 'payments') ? 'red' : '#cccccc',
                        height: '50px',
                        boxShadow: 'none',
                        '&:hover': { borderColor: 'var(--bs-primary)' },
                      }),
                    }}
                  />
                  {get(errors, 'payments') && get(touched, 'payments') && (
                    <div className="invalid-feedback  d-block">{errors.payments}</div>
                  )}
                </div>
              </div>
              {/* <div className="col-12 col-md-4">
              <div className="form-group">
                <label className="">Commision Percentage</label>
                <TextInput
                  type="text"
                  name="commision_percentage"
                  id="commision_percentage"
                  className="form-control"
                  error={touched.commision_percentage && errors.commision_percentage}
                  value={values.commision_percentage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div> */}
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Clearing Registration Fee</label>
                  <TextInput
                    type="number"
                    name="clearing_registration_fee"
                    id="clearing_registration_fee"
                    className="form-control"
                    error={touched.clearing_registration_fee && errors.clearing_registration_fee}
                    value={values.clearing_registration_fee}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Transporter Registration Fee</label>
                  <TextInput
                    type="number"
                    name="forwarding_registration_fee"
                    id="forwarding_registration_fee"
                    className="form-control"
                    error={
                      touched.forwarding_registration_fee && errors.forwarding_registration_fee
                    }
                    value={values.forwarding_registration_fee}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Dispute Window Duration (in Days)</label>
                  <TextInput
                    type="number"
                    name="dispute_date"
                    id="dispute_date"
                    className="form-control"
                    error={touched.dispute_date && errors.dispute_date}
                    value={values.dispute_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Admin Email</label>
                  <TextInput
                    type="text"
                    name="admin_email"
                    id="admin_email"
                    className="form-control"
                    error={touched.admin_email && errors.admin_email}
                    value={values.admin_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Admin Contact</label>
                  <TextInput
                    type="text"
                    name="admin_contact"
                    id="admin_contact"
                    className="form-control"
                    error={touched.admin_contact && errors.admin_contact}
                    value={values.admin_contact}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Clearing Surcharge</label>
                  <TextInput
                    type="number"
                    name="clearing_surcharge"
                    id="clearing_surcharge"
                    className="form-control"
                    error={touched.clearing_surcharge && errors.clearing_surcharge}
                    value={values.clearing_surcharge}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Transport Surcharge</label>
                  <TextInput
                    type="number"
                    name="forwarding_surcharge"
                    id="forwarding_surcharge"
                    className="form-control"
                    error={touched.forwarding_surcharge && errors.forwarding_surcharge}
                    value={values.forwarding_surcharge}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Payment Threshold</label>
                  <TextInput
                    type="number"
                    name="threshold"
                    id="threshold"
                    className="form-control"
                    error={touched.threshold && errors.threshold}
                    value={values.threshold}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Counter Bid Amount</label>
                  <TextInput
                    type="number"
                    name="counter_bid_amount"
                    id="counter_bid_amount"
                    className="form-control"
                    error={touched.counter_bid_amount && errors.counter_bid_amount}
                    value={values.counter_bid_amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Clearing Bid Time (in Days)</label>
                  <TextInput
                    type="number"
                    name="clearing_bid_days"
                    id="clearing_bid_days"
                    className="form-control"
                    error={touched.clearing_bid_days && errors.clearing_bid_days}
                    value={values.clearing_bid_days}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Transport Bid Time (in Days)</label>
                  <TextInput
                    type="number"
                    name="forwarding_bid_days"
                    id="forwarding_bid_days"
                    className="form-control"
                    error={touched.forwarding_bid_days && errors.forwarding_bid_days}
                    value={values.forwarding_bid_days}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Job Payment Grace time (in Hours)</label>
                  <TextInput
                    type="number"
                    name="job_payment_hours"
                    id="job_payment_hours"
                    className="form-control"
                    error={touched.job_payment_hours && errors.job_payment_hours}
                    value={values.job_payment_hours}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Goods - High Risk Percentage</label>
                  <TextInput
                    type="number"
                    name="high_risk_goods"
                    id="high_risk_goods"
                    className="form-control"
                    error={touched.high_risk_goods && errors.high_risk_goods}
                    value={values.high_risk_goods}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Goods - Medium Risk Percentage</label>
                  <TextInput
                    type="number"
                    name="medium_risk_goods"
                    id="medium_risk_goods"
                    className="form-control"
                    error={touched.medium_risk_goods && errors.medium_risk_goods}
                    value={values.medium_risk_goods}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Goods - Low Risk Percentage</label>
                  <TextInput
                    type="number"
                    name="low_risk_goods"
                    id="low_risk_goods"
                    className="form-control"
                    error={touched.low_risk_goods && errors.low_risk_goods}
                    value={values.low_risk_goods}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Accounts Team (Finance) Email</label>
                  <TextInput
                    type="text"
                    name="accounts_team_email"
                    id="accounts_team_email"
                    className="form-control"
                    error={touched.accounts_team_email && errors.accounts_team_email}
                    value={values.accounts_team_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Insurance Company Email</label>
                  <TextInput
                    type="text"
                    name="insurance_company_email"
                    id="insurance_company_email"
                    className="form-control"
                    error={touched.insurance_company_email && errors.insurance_company_email}
                    value={values.insurance_company_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Insurance Carbon Copy Emails</label>
                  <div className="tags-input-container">
                    {values.iccEmails?.length > 0 &&
                      values.iccEmails.map((email) => (
                        <Chip
                          key={email}
                          label={email}
                          onDelete={() => {
                            const newEmails = values.iccEmails.filter((ice) => ice !== email);
                            setFieldValue('iccEmails', newEmails);
                          }}
                          className="ml-1 mb-1"
                        />
                      ))}
                    <TextInput
                      type="text"
                      name="insurance_cc_email"
                      id="insurance_cc_email"
                      error={touched.insurance_cc_email && errors.insurance_cc_email}
                      value={values.insurance_cc_email}
                      onBlur={handleBlur}
                      className="w-100 px-2"
                      containerClasses="mb-0"
                      placeholder="abc@email.com"
                      onChange={handleChange}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          const val = values.insurance_cc_email;
                          if (val) {
                            if (emailRegex.test(val)) {
                              const newEmails =
                                values.iccEmails?.length > 0 ? [...values?.iccEmails] : [];
                              newEmails.push(val);
                              setValues({
                                ...values,
                                iccEmails: newEmails,
                                insurance_cc_email: '',
                              });
                            } else {
                              setFieldTouched('insurance_cc_email');
                              setFieldError('insurance_cc_email', 'Enter a valid email');
                            }
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Dollar Exchange Rate</label>
                  <TextInput
                    type="number"
                    name="dollar_exchange_rate"
                    id="dollar_exchange_rate"
                    className="form-control"
                    error={touched.dollar_exchange_rate && errors.dollar_exchange_rate}
                    value={values.dollar_exchange_rate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Minimum Bid Amount - RORO</label>
                  <TextInput
                    type="number"
                    name="min_bid_vehicle_roro"
                    id="min_bid_vehicle_roro"
                    className="form-control"
                    error={touched.min_bid_vehicle_roro && errors.min_bid_vehicle_roro}
                    value={values.min_bid_vehicle_roro}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Minimum Bid Amount - Vehicle Container</label>
                  <TextInput
                    type="number"
                    name="min_bid_vehicle_container"
                    id="min_bid_vehicle_container"
                    className="form-control"
                    error={touched.min_bid_vehicle_container && errors.min_bid_vehicle_container}
                    value={values.min_bid_vehicle_container}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Minimum Bid Amount - General Container</label>
                  <TextInput
                    type="number"
                    name="min_bid_general_cargo"
                    id="min_bid_general_cargo"
                    className="form-control"
                    error={touched.min_bid_general_cargo && errors.min_bid_general_cargo}
                    value={values.min_bid_general_cargo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="">Minimum Bid Amount - Bulk Cargo</label>
                  <TextInput
                    type="number"
                    name="min_bid_bulk_cargo"
                    id="min_bid_bulk_cargo"
                    className="form-control"
                    error={touched.min_bid_bulk_cargo && errors.min_bid_bulk_cargo}
                    value={values.min_bid_bulk_cargo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-3 ">
              <button
                type="button"
                className="btn btn-sm btn-secondary px-5 mr-3"
                onClick={() => resetForm()}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-sm btn-primary px-5" onClick={handleSubmit}>
                Update
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ImportantSettings;
