import { Formik, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, ref, string } from 'yup';
import AppLoader from '../../../components/loaders/AppLoader';
import TextInput from '../../../components/UI/TextInput';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import config from '../../../../config';
import TextareaInput from '../../../components/UI/TextareaInput';
import convertJsonToFormData from '../../../helpers/helper';
import { LeftArrowSvg } from '../../../helpers/CommanUI';

const AddEditBlog = (props) => {
  const dispatch = useDispatch();
  const [record, setRecords] = useState(null);
  const [ready, setReady] = useState(false);
  const [images, setImages] = useState({});

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (params.id) {
      dispatch(showLoading());

      GET(`/blogs/show/${params.id}`)
        .then(({ data }) => {
          setImages({
            ...images,
            blogImage: data.blog.image
          });
          setRecords(data.blog);
          setReady(true);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();
        });
    } else {
      setReady(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (!ready) {
    return <AppLoader />;
  }

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
          <div className="page-title">
            <h1 className="page-heading d-flex align-items-center">
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <LeftArrowSvg />
              </span>
              {record ? 'Edit' : 'Add'} Blog
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  created_by: record ? record.created_by : '',
                  title: record ? record.title : '',
                  description: record ? record.description :'',
                }}
                onSubmit={(values, { resetForm }) => {
                  dispatch(showLoading());
                  const payload = { ...values };

                  if (record) {
                  // update part here
                  payload.blog_uuid = record.uuid;
                  const editformData = convertJsonToFormData(payload);
                  POST(`/blogs/update`, editformData)
                    .then(({ data }) => {
                      dispatch(hideLoading());
                      dispatch(showModal('Blog data updated', true));
                      history.goBack();
                    })
                    .catch((err) => {
                      dispatch(showModal(err.message));
                      dispatch(hideLoading());
                    });

                  } else {
                    const payload = {...values};
                    payload.status = '1';
                    const formData = convertJsonToFormData(payload);
                    POST('/blogs/store', formData)
                      .then(({ data }) => {
                        dispatch(hideLoading());
                        dispatch(showModal('Blog Added successfully', true));
                        history.goBack();
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  }
                }}
                validationSchema={object().shape({
                  title: string().required('Title is required'),
                  description: string().required('Description is required'),
                  created_by: string().required('Created By is required'),
                })}
              >
                {(renderProps) => {
                  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = renderProps;
                  return (
                    <form className="f-c-sm" onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-12 col-md-4">
                          <div className="form-group">
                          <label className="">Created By</label>
                            <TextInput
                              type="text"
                              name="created_by"
                              id="created_by"
                              className="form-control"
                              error={touched.created_by && errors.created_by}
                              value={values.created_by}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                          <label className="">Title</label>
                            <TextInput
                              type="text"
                              name="title"
                              id="title"
                              className="form-control"
                              error={touched.title && errors.title}
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Image</label>
                            {images.blogImage && (
                              <a target="_blank" rel="noopener noreferrer" href={`${config.api.URL.replace('/api/v1', '')}/${images.blogImage}`}>
                                Blog Image
                              </a>
                            )}
                            <input 
                              type="file" 
                              name="image"
                              id="image"
                              onChange={(event) => setFieldValue('image', event.target.files[0])} 
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Description</label>
                            <Field 
                              name="description"
                              id="description"
                              render={({ field:{name, onChange,value}, form: { touched, errors } }) => (
                                <TextareaInput 
                                  name="description"
                                  id="description"
                                  error={touched[name] && errors[name]}
                                  onChange={onChange}
                                  value={value}
                                />
                              )}                           
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 ">
                        <button type="submit" className="btn btn-sm btn-primary px-5">
                          {record ? 'Update' : 'Save'}
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditBlog;
