import React from 'react';
import { isEmpty } from 'lodash';
import AuthRepDocFields from './DocumentFields/AuthRep';
import FreeZoneEntity from './DocumentFields/FreeZoneEntity';
import GovernmentEntity from './DocumentFields/GovernmentEntity';
import IncorporatedCompany from './DocumentFields/IncorporatedCompany';
import IncorporatedTrustees from './DocumentFields/IncorporatedTrustees';
import Individual from './DocumentFields/Individual';
import PrivateEntity from './DocumentFields/PrivateEntity';

const ClientDocuments = ({
  formValues,
  clientType,
  repValues,
  showAuthRepDetails,
  showDocumentModal,
}) => {
  return (
    <div style={{ textAlign: 'left' }}>
      {clientType === 'application_client' && <Individual values={formValues.user || {}} />}
      {clientType === 'incorporated_company' && (
        <IncorporatedCompany values={formValues} showDoc={showDocumentModal} />
      )}
      {clientType === 'incorporated_trustee' && (
        <IncorporatedTrustees values={formValues} showDoc={showDocumentModal} />
      )}
      {clientType === 'free_zone_entity' && (
        <FreeZoneEntity values={formValues} showDoc={showDocumentModal} />
      )}
      {clientType === 'government_entity' && (
        <GovernmentEntity values={formValues} showDoc={showDocumentModal} />
      )}
      {clientType === 'government_approved_private_entity' && (
        <PrivateEntity values={formValues} showDoc={showDocumentModal} />
      )}
      {/* Authorized representative */}
      {clientType !== 'application_client' && !isEmpty(repValues) && showAuthRepDetails && (
        <AuthRepDocFields values={repValues} clientType={clientType} />
      )}
    </div>
  );
};

export default ClientDocuments;
