import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GETALL } from '../../../services/rest.service';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import { getQueryParamValue } from '../../../helpers/helper';
import { usePagination } from '../../../helpers/customHooks';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'Name', value: 'action', class: '' },
  { width: '', name: 'Email', value: 'action', class: '' },
  { width: '', name: 'Mobile', value: 'action', class: '' },
  { width: '', name: 'Message', value: 'action', class: '' },
  { width: '', name: 'Date & Time', value: 'action', class: '' },
];

const List = (props) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const getMessagesList = (page, perPage) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };
    GETALL(`/users/getContactUs`, payload)
      .then(({ data }) => {
        setTotalPages(data.total_count / perPage);
        setRecords(data.contactUs);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    getMessagesList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  return (
    <div className="page-content">
      <div className="page-title page-title-flex">
        <h1 className="page-heading">Contact Us Messages</h1>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead">
                    <tr>
                      {TABLEHEADER.map((x, index) => {
                        return (
                          <th className={x.class} width={x.width} key={index}>
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {records.length > 0 && (
                    <tbody className="tbody">
                      {records.map((x, index) => (
                        <tr key={index}>
                          <td>{x.name || 'N/A'}</td>
                          <td>{x.email}</td>
                          <td>{x.mobile}</td>
                          <td className="w-25">{x.message}</td>
                          <td>
                            <div>
                              <p>
                                <strong>{moment(x.created_at).format(DATE_FORMAT)}</strong>
                              </p>
                              <p>
                                <strong>{moment(x.created_at).format(TIME_FORMAT)}</strong>
                              </p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {records.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
