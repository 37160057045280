import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import { LeftArrowSvg } from '../../../helpers/CommanUI';
import TabsComponent from '../../../components/Tabs';
import ImportantSettings from './ImportantSettings';
import SocialMediaSettings from './SocialMediaSettings';
import AdChannelSettings from './AdChannelSettings';
import PortsOfOperation from './PortsOfOperation';
import { addQueryParamToUrl, getQueryParamValue } from '../../../helpers/helper';

const Settings = (props) => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState({});

  const getSettings = useCallback(() => {
    dispatch(showLoading());
    GET('/settings/general/getAll')
      .then(({ data }) => {
        setSettings(data);
        // console.log(data);
        dispatch(hideLoading());
      })
      .catch((err) => {
        showModal(err.message);
        dispatch(hideLoading());
      });
  }, [dispatch]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const handleSubmit = (values) => {
    // console.log('>> handleSubmit', values);
    dispatch(showLoading());
    const payload = {
      ...settings,
      ...values,
    };

    POST('/settings/general/update', payload)
      .then((data) => {
        getSettings();
        dispatch(showModal('Settings Updated successfully', true));
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const onTabChange = useCallback(
    (val) => {
      addQueryParamToUrl(props.history, 'tab', val);
    },
    [props.history],
  );

  return (
    <div className="page-content" id="settings">
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
          <div className="page-title">
            <h1 className="page-heading d-flex align-items-center">
              <span onClick={history.goBack}>
                <LeftArrowSvg /> Settings
              </span>
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
              {!settings && <p>Something went wrong!!</p>}
              <TabsComponent
                headers={[
                  {
                    label: 'Important',
                    key: 1,
                  },
                  {
                    label: 'Social Media',
                    key: 2,
                  },
                  {
                    label: 'Ad-Channels',
                    key: 3,
                  },
                  {
                    label: 'Ports & Airports of Operations',
                    key: 4,
                  },
                ]}
                childrens={[
                  <ImportantSettings
                    key="ImportantSettings"
                    settings={settings}
                    onSubmit={handleSubmit}
                  />,
                  <SocialMediaSettings
                    key="SocialMediaSettings"
                    settings={settings}
                    onSubmit={handleSubmit}
                  />,
                  <AdChannelSettings
                    key="AdChannelSettings"
                    settings={settings}
                    onSubmit={handleSubmit}
                  />,
                  <PortsOfOperation
                    key="PortsOfOperation"
                    history={props.history}
                    location={props.location}
                  />,
                ]}
                onTabChange={onTabChange}
                tabValue={parseInt(getQueryParamValue(props.location, 'tab'), 10)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
