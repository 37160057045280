import React, { useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { POST } from '../../../services/rest.service';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { showModal } from '../../../redux/error/ErrorAction';
import TextInput from '../../../components/UI/TextInput';

const PortCreateUpdateModal = (props) => {
  const { isOpen, toggle, port, refreshTabData, container, action } = props;
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values) => {
      // console.log('>> onSubmit values', values);
      dispatch(showLoading());
      let url = '/admin/portofoperation/store';

      const payload = {
        port_of_operation: values.name,
      };

      if (port) {
        payload.port_uuid = port.uuid;
        url = '/admin/portofoperation/update';
      }

      POST(url, payload)
        .then(() => {
          dispatch(hideLoading());
          dispatch(
            showModal(`Port ${action == 'create' ? 'Created' : 'Updated'} successfully`, true),
          );
          refreshTabData();
          toggle();
        })
        .catch((err) => {
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    },
    [port, action, toggle, refreshTabData, dispatch],
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={container || 'settings'} className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody style={{ padding: '0 30px 30px' }}>
        <div className="reson-reject">
          <h5 className="text-center">{action == 'create' ? 'Create' : 'Update'} Port</h5>
          <Formik
            initialValues={{ name: port?.port_of_operation || '' }}
            validationSchema={Yup.object({
              name: Yup.string()
                .trim()
                .required('Port Name is Required'),
            })}
            onSubmit={handleSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="text-left mb-4">
                  <label>Port Name</label>
                  <div className="form-group">
                    <TextInput
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      error={touched.name && errors.name}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-sm btn-primary" disabled={isSubmitting}>
                  Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PortCreateUpdateModal;
