import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import CreateNewBid from './CreateNewBid';
import SelectFromExistingBids from './SelectFromExistingBids';

const CREATE_NEW_BID = 'CREATE_NEW_BID';
const SELECT_FROM_EXISTING_BIDS = 'SELECT_FROM_EXISTING_BIDS';

const AssignAgentToJobModal = ({
  isOpen,
  toggle,
  jobDetails,
  onAssign,
  container,
  toggleReviewListModal,
  toggleTruckDetailsModal,
}) => {
  const [assignType, setAssignType] = useState(SELECT_FROM_EXISTING_BIDS);

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} container={container} className="modal-lg">
      <div className="modal-header">
        <button type="button" className="close" onClick={() => toggle()}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject">
          <h5 className="text-center mb-3">Assign Agent to Job</h5>
          <div className="col-12 text-center mb-4 fs-5 custom-radio">
            <label className="radio-container">
              <span
                className={classnames('checkmark', {
                  checked: assignType === SELECT_FROM_EXISTING_BIDS,
                })}
              ></span>
              <input
                type="radio"
                name="assignType"
                className="mx-2 fs-4"
                onChange={(e) => {
                  const { checked } = e.target;
                  if (checked) setAssignType(SELECT_FROM_EXISTING_BIDS);
                }}
                checked={assignType === SELECT_FROM_EXISTING_BIDS}
              />
              Select From Existing Bids
            </label>
            <label className="radio-container">
              <span
                className={classnames('checkmark', {
                  checked: assignType === CREATE_NEW_BID,
                })}
              ></span>
              <input
                type="radio"
                name="assignType"
                className="mx-2 fs-5"
                onChange={(e) => {
                  const { checked } = e.target;
                  if (checked) setAssignType(CREATE_NEW_BID);
                }}
                checked={assignType === CREATE_NEW_BID}
              />
              Create New Bid
            </label>
          </div>
          {assignType === CREATE_NEW_BID && (
            <CreateNewBid jobDetails={jobDetails} onAssign={onAssign} />
          )}
          {assignType === SELECT_FROM_EXISTING_BIDS && (
            <SelectFromExistingBids
              jobDetails={jobDetails}
              onAssign={onAssign}
              toggleReviewListModal={toggleReviewListModal}
              toggleTruckDetailsModal={toggleTruckDetailsModal}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AssignAgentToJobModal;
