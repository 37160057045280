import React, { useState, useCallback } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TabPanel = (props) => {
  const { children, value } = props;

  return (
    <div key={`tab-content-${value}`} className="tabs-content-container">
      {children[value]}
    </div>
  );
};

const TabsComponent = (props) => {
  const { headers, childrens, onTabChange, tabValue } = props;
  const [tab, setTab] = useState(tabValue || 0);

  const handleTabChange = useCallback(
    (e, val) => {
      setTab(val);
      if (onTabChange) onTabChange(val);
    },
    [onTabChange],
  );

  if (headers?.length === 0) return null;

  return (
    <>
      <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs" variant="scrollable">
        {headers.map((header, index) => (
          <Tab label={header.label} key={header.key} {...a11yProps(index)} />
        ))}
      </Tabs>
      <TabPanel value={tab}>{childrens}</TabPanel>
    </>
  );
};

export default TabsComponent;
