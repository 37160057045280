import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CSSSTRING, getQueryParamValue } from '../../../helpers/helper';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GETALL, GET } from '../../../services/rest.service';
import { ActionButton } from '../../../helpers/CommanUI';
import config from '../../../../config';
import { usePagination } from '../../../helpers/customHooks';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'Title', value: 'action', class: '' },
  { width: '', name: 'Description', value: 'action', class: '' },
  { width: '', name: 'Created By', value: 'action', class: '' },
  { width: '', name: 'Image', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

const BlogList = (props) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  // const [sortingSequence, setSortingSequence] = useState('desc');
  // const [sortingValue, setSortingValue] = useState('createdAt');
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const getBlogList = (page, perPage) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };
    GETALL(`/blogs/index`, payload)
      .then(({ data }) => {
        setTotalPages(data.total_count / perPage);
        setRecords(data.blogs);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    getBlogList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  const handleDelete = (id) => {
    dispatch(showLoading());
    GET(`/blogs/delete/${id}`)
      .then(() => {
        dispatch(hideLoading());
        dispatch(showModal('Blog deleted successfully', true));
        getBlogList(selectedPage, itemsPerPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  return (
    <div className="page-content">
      <div className="page-title page-title-flex">
        <h1 className="page-heading">Blog</h1>
        <span
          onClick={() => {
            history.push('/admin/blog/add');
          }}
          className="btn btn-sm btn-primary"
        >
          Add Blog
        </span>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead">
                    <tr>
                      {TABLEHEADER.map((x, index) => {
                        return (
                          <th className={x.class} width={x.width} key={index}>
                            {x.name}
                            {/* {x.value !== 'action' && x.value !== 'status' && (
                                <span
                                  className={x.value !== 'action' && x.value !== 'status' ? `sort-icon ${CSSSTRING(x, sortingSequence, sortingValue)}` : 'sort-icon'}
                                  onClick={() => {
                                    if (x.value !== 'action' && x.value !== 'status') {
                                      this.toggleSortingOrder(x.value);
                                    }
                                  }}
                                ></span>
                              )} */}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {records.length > 0 && (
                    <tbody className="tbody">
                      {records.map((x, index) => (
                        <tr key={index}>
                          <td className="blogColumn">{x.title || 'N/A'}</td>
                          <td className="blogDescriptionColumn">
                            <div>{x.description}</div>
                          </td>
                          <td className="blogColumn">{x.created_by}</td>
                          <td className="blogImgColumn">
                            <img
                              src={`${config.api.URL.replace('/api/v1', '')}/${x.image}`}
                              alt={x.title}
                            />
                          </td>
                          <td>
                            <ActionButton
                              name="blog"
                              id={x.uuid}
                              editRoute={`/admin/blog/edit/${x.uuid}`}
                              onDeleteRecord={handleDelete}
                              hideViewBtn
                              hideSuspendBtn
                              hideRemoveSuspensionBtn
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {records.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogList;
