import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import swal from 'sweetalert';
import { isEmpty, capitalize } from 'lodash';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { LeftArrowSvg } from '../../../helpers/CommanUI';
import ShowClientDocuments from '../../../components/ClientVerification/ShowClientDocument';
import ShowAgentsData from './ShowAgentsData';
import DisplayDocument from '../../../components/DisplayDocument';
import { displayVMData } from '../../../components/DisplayVerifymeData';
import SuspensionModal from '../Subscribers/SuspensionModal';
import { GET, POST } from '../../../services/rest.service';
import {
  displayAmount,
  extractUserDocs,
  isMobile,
  getName,
  _dateFormatter,
  getUserName,
} from '../../../helpers/helper';
import DocumentModal from '../../../components/DocumentModal';
import CollapseWrapper from '../../../components/CollapseWrapper';
import { usePagination } from '../../../helpers/customHooks';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'Suspension Reason', value: 'action', class: '' },
  { width: '', name: 'Suspension Fine', value: 'action', class: '' },
  { width: '', name: 'Suspended By', value: 'action', class: '' },
  { width: '', name: 'Suspension Date & Time', value: 'action', class: '' },
];

const JOBS_TABLEHEADER = [
  { width: '', name: 'Job Id', value: 'action', class: '' },
  { width: '', name: 'Description', value: 'action', class: '' },
  { width: '', name: 'Created At', value: 'action', class: '' },
  { width: '', name: 'Status', value: 'action', class: '' },
];

const UserDetails = (props) => {
  const {
    match: { params },
  } = props;
  // user id from url
  const USER_ID = params?.id;

  const dispatch = useDispatch();

  const [records, setRecords] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [details, setDetails] = useState({});

  const suspensionPagination = usePagination();
  const jobsPagination = usePagination();

  const [jobRecords, setJobRecords] = useState([]);
  const [images, setImages] = useState({});
  const [formValues, setFormValues] = useState({});
  const [userId, setUserId] = useState(null);
  const [documentToShow, setDocumentToShow] = useState(null);

  const [showSuspensionModal, setShowSuspensionModal] = useState(false);
  const toggleSuspensionModal = () => setShowSuspensionModal((s) => !s);

  const { user } = useSelector((state) => state.user);
  const showDoc = (docc) => setDocumentToShow(docc || '');

  const serviceData = JSON.parse(localStorage.getItem('globalEnum'));

  const getUserDetails = (id) => {
    dispatch(showLoading());
    GET(`/users/getDetails/${id}`)
      .then(({ data }) => {
        // console.log(data);
        setUserDetails(data.user);
        setDetails(data);
        if (data.user.type == 'clearing_agent' || data.user.type == 'forwarding_agent') {
          if (!isEmpty(data.documents)) {
            const item = extractUserDocs(data);

            setImages({
              ...images,
              incorporation_certificate: item.incorporation_certificate,
              memorandum_article_association: item.memorandum_article_association,
              corporate_address_proof: item.corporate_address_proof,
              registration_certificate: item.registration_certificate,
              registration_application_status: item.registration_application_status,
              ctc_cac_1_1: item.ctc_cac_1_1,
              cac_2: item.cac_2,
              cac_7: item.cac_7,
            });
            const modifiedItem = {
              ...item,
              incorporation_certificate: null,
              memorandum_article_association: null,
              corporate_address_proof: null,
              registration_certificate: null,
              registration_application_status: null,
              ctc_cac_1_1: null,
              cac_2: null,
              cac_7: null,
            };
            // console.log(modifiedItem);
            setFormValues(modifiedItem);
          } else {
            setFormValues({});
          }
        } else {
          const item = extractUserDocs(data);

          if (!isEmpty(item)) {
            setImages({
              ...images,
              national_identity_card: item.national_identity_card,
            });
            const modifiedItem = {
              ...item,
              national_identity_card: null,
            };
            // console.log(modifiedItem);
            setFormValues(modifiedItem);
          } else {
            setFormValues({});
          }
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
        setUserDetails({});
      });
  };

  const getSuspensionHistory = (userId, page, perPage) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
      sort_by: 'created_at',
      sort_type: 'desc',
    };
    GET(`/admin/getSuspensionLog/${userId}`, payload)
      .then(({ data }) => {
        suspensionPagination.setTotalPages(data.total_count / perPage);
        setRecords(data.suspension_log);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const getUserJobs = (userId, page, perPage) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
      sort_by: 'created_at',
      sort_type: 'desc',
      user_uuid: userId,
    };
    POST(`/jobs/getJobsByUser`, payload)
      .then(({ data }) => {
        jobsPagination.setTotalPages(data.total_count / perPage);
        setJobRecords(data.jobs);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  // fetch user details on mount
  useEffect(() => {
    if (USER_ID) {
      getUserDetails(USER_ID);
    }
  }, [USER_ID]);

  // fetch suspension history on mount & whenever user changes page
  useEffect(() => {
    if (USER_ID) {
      getSuspensionHistory(
        USER_ID,
        suspensionPagination.selectedPage,
        suspensionPagination.itemsPerPage,
      );
    }
  }, [USER_ID, suspensionPagination.selectedPage, suspensionPagination.itemsPerPage]);

  useEffect(() => {
    if (USER_ID) {
      getUserJobs(USER_ID, jobsPagination.selectedPage, jobsPagination.itemsPerPage);
    }
  }, [USER_ID, jobsPagination.selectedPage, jobsPagination.itemsPerPage]);

  const handleSelected = ({ selected }) => {
    // console.log('selected', selected);
    suspensionPagination.setSelectedPage(selected + 1);
  };

  const handleSuspensionPageCountChange = (e) => {
    handleSelected({ selected: 0 });
    suspensionPagination.setItemsPerPage(e.target.value);
  };

  const handleJobSelected = ({ selected }) => {
    // console.log('selected', selected);
    jobsPagination.setSelectedPage(selected + 1);
  };

  const handleJobsPageCountChange = (e) => {
    handleSelected({ selected: 0 });
    jobsPagination.setItemsPerPage(e.target.value);
  };

  const refreshPageData = () => {
    getUserDetails(USER_ID);
    getSuspensionHistory(
      USER_ID,
      suspensionPagination.selectedPage,
      suspensionPagination.itemsPerPage,
    );
  };

  const handleSuspend = (u) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to suspend ${getName(u)}?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        toggleSuspensionModal();
        setUserId(u.uuid);
      }
    });
  };

  const handleRemoveSuspension = (u) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to remove suspension?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        POST(`/admin/activateUser`, { uuid: u.uuid })
          .then(() => {
            dispatch(hideLoading());
            dispatch(showModal('User Activated successfully', true));
            refreshPageData();
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
          });
      }
    });
  };

  return (
    <div className="page-content" id="user-details">
      <div className="page-title page-title-flex">
        <h1 className="page-heading d-flex align-items-center">
          <span onClick={() => props?.history.goBack()}>
            <LeftArrowSvg />
            User Details
          </span>
        </h1>
      </div>
      <div className="card px-3 py-3">
        <>
          {userDetails.type == 'clearing_agent' || userDetails.type == 'forwarding_agent' ? (
            <>
              <div className="row">
                <div className="col-lg-6 col-sm-12 px-3">
                  <h4
                    style={{ fontSize: '32px', color: '#000', fontWeight: '600' }}
                    className="mx-2"
                  >
                    {capitalize(userDetails.company_name)}
                  </h4>
                  <h6 className="mx-2">{userDetails?.user_id}</h6>
                  <DisplayDocument
                    label="Email"
                    value={userDetails?.email}
                    type="text"
                    textColClassName="document mx-2"
                  />
                  <DisplayDocument
                    label="Mobile"
                    value={userDetails?.mobile_number}
                    type="text"
                    textColClassName="document mx-2"
                  />
                </div>

                <div className="col-lg-6 col-sm-12 px-3"></div>
              </div>
              <hr />
              <ShowAgentsData
                showDoc={showDoc}
                images={images}
                formValues={formValues}
                userType={userDetails.type}
                details={details}
              />
            </>
          ) : (
            <div className="row">
              <div className="col-lg-6 col-sm-12 px-3">
                <h4 style={{ fontSize: '32px', color: '#000', fontWeight: '600' }} className="mx-2">
                  {getUserName(userDetails)}
                </h4>
                <h6 className="mx-2">{userDetails?.user_id}</h6>
                <DisplayDocument
                  label="Email"
                  value={userDetails?.email}
                  type="text"
                  textColClassName="document mx-2"
                />
                <DisplayDocument
                  label="Mobile"
                  value={userDetails?.mobile_number}
                  type="text"
                  textColClassName="document mx-2"
                />
                <div className="mb-5">
                  <ShowClientDocuments
                    formValues={{
                      ...formValues,
                      user: userDetails,
                    }}
                    clientType={userDetails.sub_type || userDetails.type}
                    repValues={null}
                    showAuthRepDetails={false}
                    showDocumentModal={(doc) => showDoc(doc)}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 px-3">
                {isMobile()?.matches && <hr />}
                {userDetails?.verify_me_data?.length > 0 && (
                  <h3 className="text-center">NIN Details</h3>
                )}
                {userDetails?.verify_me_data?.length > 0 &&
                  userDetails.verify_me_data.map((vm) => displayVMData(vm, true))}
              </div>
            </div>
          )}
        </>
        {/* Jobs History */}
        <CollapseWrapper heading={<h3>Jobs History</h3>}>
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead">
                        <tr>
                          {JOBS_TABLEHEADER.map((x, index) => {
                            return (
                              <th className={x.class} width={x.width} key={index}>
                                {x.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      {jobRecords.length > 0 && (
                        <tbody className="tbody">
                          {jobRecords.map((x, index) => (
                            <tr key={index}>
                              <td>{x.job_id}</td>
                              <td>
                                <h6>
                                  {x.category &&
                                    serviceData &&
                                    capitalize(serviceData.job.category[x.category])}
                                </h6>
                                <p>
                                  {x.type &&
                                    serviceData &&
                                    capitalize(serviceData.job.vehicle_type[x.type])}
                                </p>
                                <p>
                                  {x.type_of_cargo &&
                                    serviceData &&
                                    capitalize(serviceData.job.type_of_cargo[x.type_of_cargo])}
                                </p>
                              </td>
                              <td>{_dateFormatter(x.created_at)}</td>
                              <td>
                                <div>
                                  {serviceData &&
                                    serviceData.job.clearing_status[
                                      x.forwarding_status || x.clearing_status
                                    ]}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {jobRecords.length <= 0 && (
                      <h3 className={'empty-message'}>Records Not Found</h3>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {jobRecords.length > 0 && (
                  <Pagination
                    pageCount={jobsPagination.totalPages}
                    onPageChange={handleJobSelected}
                    selectedPage={jobsPagination.selectedPage}
                    setItemsPerPage={handleJobsPageCountChange}
                    itemsPerPage={jobsPagination.itemsPerPage}
                  />
                )}
              </div>
            </div>
          </div>
        </CollapseWrapper>
        <hr />
        {/* Suspension History */}
        <CollapseWrapper heading={<h3>Suspension History</h3>}>
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead">
                        <tr>
                          {TABLEHEADER.map((x, index) => {
                            return (
                              <th className={x.class} width={x.width} key={index}>
                                {x.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      {records.length > 0 && (
                        <tbody className="tbody">
                          {records.map((x, index) => (
                            <tr key={index}>
                              <td>
                                <strong>{x.suspension_reason || 'N/A'}</strong>
                              </td>
                              <td>
                                <strong>{displayAmount(x.suspension_fine) || 'N/A'}</strong>
                              </td>
                              <td>
                                <strong>
                                  {serviceData?.user_types?.[x.suspended_by] || 'N/A'}
                                </strong>
                              </td>
                              <td>
                                <div>
                                  <p>
                                    <strong>{moment(x.created_at).format(DATE_FORMAT)}</strong>
                                  </p>
                                  <p>
                                    <strong>{moment(x.created_at).format(TIME_FORMAT)}</strong>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {records.length > 0 && (
                  <Pagination
                    pageCount={suspensionPagination.totalPages}
                    onPageChange={handleSelected}
                    selectedPage={suspensionPagination.selectedPage}
                    setItemsPerPage={handleSuspensionPageCountChange}
                    itemsPerPage={suspensionPagination.itemsPerPage}
                  />
                )}
              </div>
            </div>
          </div>
        </CollapseWrapper>
        {/* Action Buttons */}
        <div className="buttonsec text-center">
          {userDetails.identity_verified === 1 && (
            <button
              className="btn btn-primary btn-sm mr-2"
              type="button"
              onClick={() => handleSuspend(userDetails)}
            >
              Suspend
            </button>
          )}
          {userDetails.identity_verified === 3 && (
            <button
              className="btn btn-primary btn-sm mr-2"
              type="button"
              onClick={() => handleRemoveSuspension(userDetails)}
            >
              Remove Suspension
            </button>
          )}
        </div>
        {showSuspensionModal && (
          <SuspensionModal
            isOpen={showSuspensionModal}
            toggle={() => toggleSuspensionModal()}
            userId={userId}
            refreshTabData={refreshPageData}
            container="user-details"
          />
        )}
        {/* Modal to Display Document */}
        {documentToShow && (
          <DocumentModal
            isOpen={documentToShow}
            toggle={() => setDocumentToShow('')}
            documentToShow={documentToShow}
            container="user-details"
          />
        )}
      </div>
    </div>
  );
};

export default UserDetails;
