import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { capitalize } from 'lodash';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import MuiTextField from '@material-ui/core/TextField';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import {
  addQueryParamToUrl,
  getQueryParamValue,
  asyncDebounce,
  getUserName,
} from '../../../helpers/helper';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { usePagination } from '../../../helpers/customHooks';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'Date and time of activity', value: 'action', class: '' },
  { width: '', name: 'Activity By', value: 'action', class: '' },
  { width: '', name: 'Activity Name', value: 'action', class: '' },
  { width: '', name: 'Activity On', value: 'action', class: '' },
];

const TYPE_USER = 'User';
const TYPE_JOB = 'Job';
const TYPE_BID = 'Bid';
const TYPE_TRUCK = 'Truck';
const TYPE_DISPUTE = 'Dispute';

const getActivityType = (data) => {
  if (!data) return null;
  return data.subject_type.split('\\')[4];
};

const List = (props) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState(
    getQueryParamValue(props.location, 'selectedUserUUID') || null,
  );

  const getActivitiesList = (page, perPage, userId) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
      sort_by: 'created_at',
      sort_type: 'desc',
      user_uuid: userId || '',
    };
    POST(`/users/getActivityLogList`, payload)
      .then(({ data }) => {
        setTotalPages(data.total_count / perPage);
        setRecords(data.activities);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    getActivitiesList(selectedPage, itemsPerPage, filterUserId);
  }, [selectedPage, itemsPerPage, filterUserId]);

  const handleActivityLinkClick = (activity) => {
    const activityType = getActivityType(activity);
    const properties = JSON.parse(activity.properties);

    switch (activityType) {
      case TYPE_USER:
        props.history.push(`/admin/user/details/${activity.subject_id}`);
        break;
      case TYPE_JOB:
        props.history.push(`/admin/job/view/${activity.subject_id}`);
        break;
      case TYPE_BID:
        props.history.push(`/admin/job/view/${properties.job_uuid}`);
        break;
      case TYPE_TRUCK:
        props.history.push(
          `/admin/user/${properties.agent_uuid}/edit-truck/${properties.truck_uuid}`,
        );
        break;
      case TYPE_DISPUTE:
        props.history.push(`/admin/disputes/details?id=${properties.dispute_uuid}`);
        break;
      default:
        break;
    }
  };

  const handleSelectUser = (event, value) => {
    handleSelected({ selected: 0 });
    addQueryParamToUrl(props.history, 'selectedUser', value?.user_id || '');
    addQueryParamToUrl(props.history, 'selectedUserUUID', value?.uuid || '');
    setFilterUserId(value?.uuid || '');
  };

  const searchUserIdName = async (e) => {
    dispatch(showLoading());
    try {
      const resp = await POST('/users/getUsers', {
        search: String(e.target.value).trim(),
      });
      // console.log(data);
      setUserList(resp?.data.users);
      dispatch(hideLoading());
    } catch (error) {
      setUserList([]);
      dispatch(showModal(error.message));
      dispatch(hideLoading());
    }
  };

  const onSearchIpChange = asyncDebounce(searchUserIdName, 1000);

  const defaultSelection = getQueryParamValue(props.location, 'selectedUser')
    ? {
        user_id: getQueryParamValue(props.location, 'selectedUser'),
      }
    : null;

  const getName = (user, type) => {
    if (!user) return 'N/A';
    if (type === 'causer') {
      return (
        (user.causer_name && String(user.causer_name).trim()) ||
        (user.causer_company_name && String(user.causer_company_name).trim()) ||
        'N/A'
      );
    } else {
      return (
        (user.name && String(user.name).trim()) ||
        (user.company_name && String(user.company_name).trim()) ||
        'N/A'
      );
    }
  };

  return (
    <div className="page-content">
      <div className="page-title page-title-flex">
        <h1 className="page-heading">Activity Logs</h1>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-4">
                  <h6>Filter Logs By User</h6>
                  <MuiAutocomplete
                    options={userList}
                    getOptionLabel={(option) => `${option.user_id}`}
                    onChange={handleSelectUser}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        placeholder="Search with User Name or Id"
                        onChange={onSearchIpChange}
                        variant="outlined"
                      />
                    )}
                    value={defaultSelection}
                    renderOption={(option) => (
                      <li>{`${option.user_id} - ${getUserName(option)}`}</li>
                    )}
                    filterOptions={(x) => x}
                  />
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead">
                    <tr>
                      {TABLEHEADER.map((x, index) => {
                        return (
                          <th className={x.class} width={x.width} key={index}>
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {records.length > 0 && (
                    <tbody className="tbody">
                      {records.map((x, index) => {
                        const properties = JSON.parse(x.properties);

                        const activityBy = (
                          <>
                            <p>Name: {getName(properties, 'causer')}</p>
                            <p>ID: {`${properties?.causer_id || properties.agent_id || '--'}`}</p>
                          </>
                        );

                        const activityType = getActivityType(x);
                        let activityOn = '';

                        switch (activityType) {
                          case TYPE_USER:
                            activityOn = (
                              <>
                                <p>Name: {getName(properties)}</p>
                                <p>
                                  {`${activityType} : ${properties?.user_id ||
                                    x.subject_id ||
                                    '--'}`}
                                </p>
                              </>
                            );
                            break;
                          case TYPE_JOB:
                          case TYPE_BID:
                            activityOn += `Job : ${properties.job_id || '--'}`;
                            break;
                          case TYPE_TRUCK:
                            activityOn += `${activityType} : ${properties.truck_id || '--'}`;
                            break;
                          case TYPE_DISPUTE:
                            activityOn += `${activityType} : ${properties.dispute_uuid || '--'}`;
                            break;
                          default:
                            break;
                        }

                        return (
                          <tr key={index}>
                            <td>
                              <div>
                                <p>{moment(x.created_at).format(DATE_FORMAT)}</p>
                                <p>{moment(x.created_at).format(TIME_FORMAT)}</p>
                              </div>
                            </td>
                            <td>
                              {x.causer_id ? (
                                <div
                                  onClick={() =>
                                    props.history.push(`/admin/user/details/${x.causer_id}`)
                                  }
                                  className="link"
                                >
                                  {activityBy}
                                </div>
                              ) : (
                                <div>{activityBy}</div>
                              )}
                            </td>
                            <td>{capitalize(x.log_name) || '--'}</td>
                            <td>
                              {x.subject_id ? (
                                <span className="link" onClick={() => handleActivityLinkClick(x)}>
                                  {activityOn}
                                </span>
                              ) : (
                                <span>{activityOn}</span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {records.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
