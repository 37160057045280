import React from 'react';
import BidList from './BidList';

const SelectFromExistingBids = ({
  jobDetails,
  onAssign,
  toggleReviewListModal,
  toggleTruckDetailsModal,
}) => {
  return (
    <div className="mx-3">
      <h3>Select Agent From Existing Bids</h3>
      <div className="select-bid-container">
        <BidList
          isOpen={true}
          toggleReviewListModal={toggleReviewListModal}
          job={jobDetails}
          toggleTruckDetailsModal={toggleTruckDetailsModal}
          showAcceptBid={true}
          onBidAccept={onAssign}
        />
      </div>
    </div>
  );
};

export default SelectFromExistingBids;
