import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { upperCase, capitalize } from 'lodash';
import AppLoader from '../../../components/loaders/AppLoader';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET } from '../../../services/rest.service';
import { LeftArrowSvg } from '../../../helpers/CommanUI';
import config from '../../../../config';
import { getCreatedBy, _dateFormatter } from '../../../helpers/helper';

const ViewJob = (props) => {
  const dispatch = useDispatch();
  const [record, setRecords] = useState(null);
  const [ready, setReady] = useState(false);
  const serviceData = JSON.parse(localStorage.getItem('globalEnum'));

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (params.id) {
      dispatch(showLoading());

      GET(`/jobs/getDetails/${params.id}`)
        .then(({ data }) => {
          setRecords(data.job);
          setReady(true);

          dispatch(hideLoading());
        })
        .catch((err) => {
          props.history.goBack();
          dispatch(hideLoading());
          dispatch(showModal(err.message));
        });
    } else {
      setReady(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!ready) {
    return <AppLoader />;
  }

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-10 col-xl-10 mx-auto">
          <div className="page-title page-title-flex">
            <h1 className="page-heading d-flex align-items-center">
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <LeftArrowSvg />
              </span>
              Jobs Details
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4 mb-2">
                  <h6 className="mb-0">ID</h6>
                  <p className="mb-md-0">
                    <b>{record.job_id || 'N/A'}</b>
                  </p>
                </div>
                {record.job_type_tags && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Type</h6>
                    <p>
                      {record.job_type_tags.length > 0 &&
                        record.job_type_tags.map((t) => (
                          <span
                            key={t}
                            className={`alert alert-${t === 'clearing' ? 'info' : 'success'}`}
                            style={{ marginRight: 10, padding: 10 }}
                          >
                            {t === 'forwarding' ? 'Transport' : upperCase(t)}
                          </span>
                        ))}
                    </p>
                  </div>
                )}
                {record.client && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Created By</h6>
                    <p>{getCreatedBy(record.client)}</p>
                  </div>
                )}
                {record.created_at && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Created At</h6>
                    <p>{_dateFormatter(record.created_at)}</p>
                  </div>
                )}
                {record.clearing_status && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Clearing Status</h6>
                    <p>
                      <span className="alert alert-info" style={{ padding: 10 }}>
                        {capitalize(serviceData.job.clearing_status[record.clearing_status])}
                      </span>
                    </p>
                  </div>
                )}
                {record.forwarding_status && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Transport Job Status</h6>
                    <p>
                      <span className="alert alert-success" style={{ padding: 10 }}>
                        {capitalize(serviceData.job.forwarding_status[record.forwarding_status])}
                      </span>
                    </p>
                  </div>
                )}
                {record.category && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6 className="mb-0">Category</h6>
                    <p>{capitalize(serviceData.job.category[record.category])}</p>
                  </div>
                )}
                {record.type && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Type of vehicle</h6>
                    <p> {capitalize(serviceData.job.vehicle_type[record.type])}</p>
                  </div>
                )}
                {record.type_of_cargo && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Type of cargo</h6>
                    <p>{capitalize(serviceData.job.type_of_cargo[record.type_of_cargo])}</p>
                  </div>
                )}
                {record.lading_link && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Landing Bill</h6>
                    <p>
                      <a
                        href={`${config.api.URL.replace('/api/v1', '')}/${record.lading_link}`}
                        target="__blank"
                      >
                        Landing Bill
                      </a>
                    </p>
                  </div>
                )}
                {record.paar_link && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>PAAR (Pre-arrival assessment report)</h6>
                    <p>
                      <a
                        href={`${config.api.URL.replace('/api/v1', '')}/${record.paar_link}`}
                        target="__blank"
                      >
                        PAAR
                      </a>
                    </p>
                  </div>
                )}
                {record.no_of_containers && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Number of Containers</h6>
                    <p>{record.no_of_containers}</p>
                  </div>
                )}
                {record.tonnage && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Tonnage</h6>
                    <p>{record.tonnage}</p>
                  </div>
                )}
                {record.weight && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Weight</h6>
                    <p>{record.weight}</p>
                  </div>
                )}
                {record.winning_bid && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Winning Bid</h6>
                    <p>{record.winning_bid}</p>
                  </div>
                )}
                {record.destination && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Destination</h6>
                    <p>{record.destination}</p>
                  </div>
                )}
                {record.vehicles && record.vehicles.length > 0 && (
                  <div className="col-12 col-md-6 col-xl-4 mb-2">
                    <h6>Number of vehicles</h6>
                    <p>{record.vehicles.length}</p>
                  </div>
                )}
                {record.vehicles && record.vehicles.length > 0 && (
                  <div className="col-12 col-md-12 col-xl-12 mb-2">
                    <h6>Vehicle Details</h6>
                    <p>
                      {record.vehicles.length > 0 && (
                        <table className="table">
                          <thead className="thead">
                            <tr>
                              <th>Brand</th>
                              <th>Model</th>
                              <th>Year</th>
                              <th>Vehicle State</th>
                            </tr>
                          </thead>
                          <tbody>
                            {record.vehicles.map((v) => (
                              <tr key={v.uuid}>
                                <td>{v.brand}</td>
                                <td>{v.model}</td>
                                <td>{v.year}</td>
                                <td>{v.vehicle_state}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewJob;
