import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';

const CollapseWrapper = (props) => {
  const { heading, children, headingClassName } = props;
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen((s) => !s);

  const classNames = classnames('accordion-header collapse-header', headingClassName);

  return (
    <div className="accordion accordion-flush">
      <div className="accordion-item">
        <h2 className={classNames}>
          <button className="accordion-button collapsed" type="button" onClick={toggle}>
            {heading}
          </button>
        </h2>
        <Collapse isOpen={isOpen} className="accordion-collapse collapse">
          {children}
        </Collapse>
      </div>
    </div>
  );
};

export default CollapseWrapper;
