import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { shuffle, isEmpty } from 'lodash';
import { Collapse } from 'reactstrap';
import { startOfYear, endOfYear } from 'date-fns';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { ButtonGroup, Button } from 'reactstrap';
import { POST, GET } from '../../../services/rest.service';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { showModal } from '../../../redux/error/ErrorAction';
import { formatAllStats } from '../../../helpers/helper';
import DateRangeModal from '../../../components/DateRange/DateRangeModal';
import { DATE_FORMAT } from '../../../helpers/constants';
import AppLoader from '../../../components/loaders/AppLoader';

const dateFormat = 'YYYY-MM-DD';

const colors = [
  '#fa8c16',
  '#13c2c2',
  '#ff4d4f',
  '#52c41a',
  '#fadb14',
  '#1890ff',
  '#722ed1',
  '#eb2f96',
  '#2f54eb',
  '#8c8c8c',
  '#1f1f1f',
];

const defaultRange = {
  startDate: startOfYear(new Date()),
  endDate: endOfYear(new Date()),
  key: 'range',
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [stats, setStats] = useState([]);
  const [statsDateRange, setStatsDateRange] = useState(defaultRange);
  const [cardId, setCardId] = useState(() => {
    let initialState = [0, 1, 2, 3, 4, 5];
    const accordiansOpen = localStorage.getItem('dashboardAccordiansOpen');
    if (accordiansOpen) {
      initialState = JSON.parse(accordiansOpen);
    }
    return initialState;
  });

  const [viewType, setViewType] = useState('kpi');
  const [showDateRange, setShowDateRange] = useState(false);

  const toggle = (id) => {
    let tempCaardId = [...cardId];
    let index = tempCaardId.findIndex((x) => x === id);
    if (index > -1) {
      tempCaardId.splice(index, 1);
    } else {
      tempCaardId.push(id);
    }
    setCardId(tempCaardId);
    localStorage.setItem('dashboardAccordiansOpen', JSON.stringify(tempCaardId));
  };

  // console.log('statsDateRange', statsDateRange);

  useEffect(() => {
    if (statsDateRange?.startDate && statsDateRange?.endDate) {
      dispatch(showLoading());

      const { startDate, endDate } = statsDateRange;

      const payload = {
        start: moment(startDate).format(dateFormat),
        end: moment(endDate).format(dateFormat),
      };

      POST('/admin/getAllStats', payload)
        .then(({ data }) => {
          // console.log('data',data);
          GET(`/public/global/vars`)
            .then(({ data: serviceData }) => {
              const statsData = formatAllStats(data, serviceData);
              setStats(statsData);
              dispatch(hideLoading());
            })
            .catch((err) => {
              dispatch(showModal(err.message));
              dispatch(hideLoading());
            });
        })
        .catch((err) => {
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    }
  }, [statsDateRange]);

  const onDateRangeChange = (val) => {
    if (val?.range) {
      setStatsDateRange(val.range);
    } else {
      setStatsDateRange(defaultRange);
    }
    setShowDateRange(false);
  };

  if (isEmpty(user)) {
    return <AppLoader />;
  }

  return (
    <div className="page-content" id="dashboard">
      <div className="page-title page-title-flex">
        <h1 className="page-heading">Dashboard</h1>
        <div className="page-title-date-range">
          <div className="page-title-dv">
            <ButtonGroup className="btn-group">
              <Button
                size="sm"
                active={viewType === 'kpi'}
                onClick={() => setViewType('kpi')}
                title="KPI View"
              >
                <img src={`/images/svg/bar-chart.svg`} width="20" alt="KPI View" />
              </Button>
              <Button
                size="sm"
                active={viewType === 'data'}
                onClick={() => setViewType('data')}
                title="Data View"
              >
                <img src={`/images/svg/grid.svg`} width="20" alt="Data View" />
              </Button>
            </ButtonGroup>
          </div>
          <div>
            <span onClick={() => setShowDateRange(true)} className="date-range-btn">
              Select Date Range
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <h6>
            Showing results for date range:{' '}
            <span className="color-light-green">
              {moment(statsDateRange?.startDate).format(DATE_FORMAT)} -{' '}
              {moment(statsDateRange?.endDate).format(DATE_FORMAT)}
            </span>
          </h6>
        </div>
        {stats.length > 0 ? (
          stats.map((statsCategory, scIndex) => {
            if (viewType === 'data') {
              return (
                <div className="col-12 col-md-12 col-lg-12" key={`stats-category-${scIndex}`}>
                  <div className="accordion accordion-flush">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          onClick={() => toggle(scIndex)}
                        >
                          <img
                            src={`/images/svg/${statsCategory.icon}`}
                            width="30"
                            alt=""
                            style={{ marginRight: 10 }}
                          />
                          <span className="dashboard-stats-heading">{statsCategory.title}</span>
                        </button>
                      </h2>
                      <Collapse
                        isOpen={cardId.includes(scIndex)}
                        className="accordion-collapse collapse"
                      >
                        <div className="row">
                          {statsCategory.value.length &&
                            statsCategory.value.map((stat, sIndex) => (
                              <div className="col-12 col-md-6 col-lg-3" key={`stat-${sIndex}`}>
                                <div className="card">
                                  <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div style={{ marginRight: 20 }}>
                                        <h6 className="mb-0">{stat.title}</h6>
                                      </div>
                                      <div>
                                        <h4 className="mb-0">{stat.value}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </Collapse>
                      <hr />
                    </div>
                  </div>
                </div>
              );
            } else if (viewType === 'kpi') {
              return (
                <div
                  className={
                    statsCategory.value.length > 5 ? 'col-12' : 'col-md-6 col-lg-6 col-sm-12'
                  }
                  key={`stats-category-${scIndex}`}
                >
                  <h2>
                    <img
                      src={`/images/svg/${statsCategory.icon}`}
                      width="30"
                      alt=""
                      style={{ marginRight: 10 }}
                    />
                    <span className="dashboard-stats-heading">{statsCategory.title}</span>
                  </h2>
                  <div
                    style={{
                      height: '300px',
                      margin: '20px auto',
                    }}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={600}
                        height={300}
                        data={statsCategory.value}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="title"
                          interval={0}
                          angle={statsCategory.value.length > 5 ? -5 : 0}
                        />
                        <YAxis />
                        <Tooltip formatter={(value) => [value, 'Count']} />
                        <Bar dataKey="value" fill="#36b7b7" maxBarSize={80}>
                          {statsCategory.value.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={shuffle(colors)[index]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <hr />
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="col-12" style={{ textAlign: 'center', padding: 25 }}>
            <span>No data available for the provided date range</span>
          </div>
        )}
      </div>
      <DateRangeModal
        onChange={onDateRangeChange}
        isOpen={showDateRange}
        ranges={defaultRange}
        toggle={() => setShowDateRange(false)}
        container="dashboard"
        format={DATE_FORMAT}
      />
    </div>
  );
};

export default Dashboard;
