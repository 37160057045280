import React from 'react';

const DisplayDocument = (props) => {
  const {
    label,
    onClick,
    type,
    value,
    fileColClassName,
    fileClassName,
    textColClassName,
    textClassName,
    nolabel = false,
    hasDocument = true,
    documentLabel,
  } = props;

  if (type === 'file') {
    return (
      <div className={fileColClassName || ''}>
        <div className={fileClassName || ''}>
          {nolabel === false && (
            <p>
              <strong>{`${label}: `}</strong>
            </p>
          )}
          {hasDocument === true && (
            <span style={{ color: '#337ab7', cursor: 'pointer' }} onClick={onClick}>
              <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
              {` ${documentLabel || label}`}
            </span>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={textColClassName || ''}>
        {nolabel === false && (
          <p>
            <strong className={textClassName || ''}>{`${label}:`}</strong>
          </p>
        )}
        <span>{value}</span>
      </div>
    );
  }
};

export default DisplayDocument;
