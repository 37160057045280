import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Collapse } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { get, isEmpty, round } from 'lodash';
import { POST } from '../../../services/rest.service';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import StaticStarUI from '../../../components/UI/StaticStarUI';
import { displayAmount } from '../../../helpers/helper';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import Pagination from '../../../components/Pagination';
import { usePagination } from '../../../helpers/customHooks';

const BidList = (props) => {
  const {
    isOpen,
    toggleReviewListModal,
    job,
    toggleTruckDetailsModal,
    showAcceptBid = false,
    onBidAccept,
  } = props;

  const dispatch = useDispatch();
  const [bidList, setBidList] = useState(null);

  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    setTotalPages,
  } = usePagination();

  const handleAcceptBid = (id1, id2) => {
    // accept the bid
    const bidAcceptPayload = {
      job_uuid: id1,
      bid_uuid: id2,
    };
    dispatch(showLoading());
    POST(`/jobs/acceptBid`, bidAcceptPayload)
      .then(({ data }) => {
        // console.log('>> data',data);
        dispatch(hideLoading());
        if (onBidAccept) onBidAccept();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const handleSelected = ({ selected }) => {
    // console.log('bids selected', selected);
    setSelectedPage(selected + 1);
  };

  // bids fetch effect
  useEffect(() => {
    if (job.uuid && isOpen) {
      dispatch(showLoading());
      const payload = {
        job_uuid: job.uuid,
        per_page: itemsPerPage,
        page: selectedPage,
        sort_by: 'offer_price',
        sort_type: 'asc',
      };
      POST(`/bids/getByJob`, payload)
        .then(({ data }) => {
          // console.log('data', data);
          if (isEmpty(data.bids)) {
            setBidList([]);
            setSelectedPage(1);
            setTotalPages(0);
          } else {
            setTotalPages(data.total_count / itemsPerPage);
            setBidList(data.bids);
          }
          dispatch(hideLoading());
        })
        .catch((err) => {
          setBidList([]);
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    }
  }, [job, isOpen, selectedPage, itemsPerPage]);

  const handlePageCountChange = (e) => {
    handleSelected({ selected: 0 });
    setItemsPerPage(e.target.value);
  };

  return (
    <Collapse isOpen={isOpen} className="">
      <div className="tab-pane active" id="resp-tab1">
        <div className="table-responsive m-0">
          <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="bid-list-table">
            <thead>
              <tr>
                <th>Agent ID</th>
                <th>Jobs Completed</th>
                <th>Agent Rating</th>
                <th>Bid Value</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {bidList?.length > 0 &&
                bidList.map((bidData, bidIndex) => (
                  <tr key={`bid-${bidIndex}`}>
                    <td>
                      {get(bidData, 'agent.user_id', 'N/A')}
                      {bidData.accepted_at && <span className="alert-success p-2">Accepted</span>}
                    </td>
                    <td>
                      <span>{bidData?.agent?.agent_total_jobs || 0}</span>
                    </td>
                    <td>
                      <StaticStarUI
                        rating={
                          get(bidData, 'agent.agent_total_ratings')
                            ? round(get(bidData, 'agent.agent_average_ratings'), 1)
                            : 0
                        }
                      />
                      <div style={{ marginBottom: '20px' }}>
                        <a
                          onClick={() => {
                            toggleReviewListModal(get(bidData, 'agent.uuid'));
                          }}
                          className="detailsdiv color-secondary"
                        >
                          Read Review
                        </a>
                      </div>
                    </td>
                    <td>
                      <span>{displayAmount(bidData.offer_price) || 0}</span>
                    </td>
                    <td>
                      <div>{moment(bidData.created_at).format(DATE_FORMAT)}</div>
                      <div>{moment(bidData.created_at).format(TIME_FORMAT)}</div>
                    </td>
                    <td>
                      <div className="wrap-div">
                        {job.is_forwarding_only && (
                          <button
                            onClick={() => toggleTruckDetailsModal(bidData.truck_uuid)}
                            className="btn btn-primary btn-sm ml-2 mb-2"
                            type="button"
                          >
                            View Truck Details
                          </button>
                        )}
                        {showAcceptBid && !bidData.accepted_at && (
                          <button
                            onClick={() => handleAcceptBid(job.uuid, bidData.uuid)}
                            className="btn btn-primary btn-sm ml-2"
                            type="button"
                          >
                            Accept Bid
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {bidList?.length <= 0 && <p className="mt-3 fs-6 text-center">No Bids Found!</p>}
        </div>
        {bidList?.length > 0 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={handleSelected}
            selectedPage={selectedPage}
            setItemsPerPage={handlePageCountChange}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>
    </Collapse>
  );
};

export default React.memo(BidList);
