import { Formik, getIn } from 'formik';
import { capitalize, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import AppLoader from '../../../components/loaders/AppLoader';
import TextInput from '../../../components/UI/TextInput';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import convertJsonToFormData from '../../../helpers/helper';
import config from '../../../../config';
const AddTruck = (props) => {
  const dispatch = useDispatch();
  const [record, setRecords] = useState(null);
  const [ready, setReady] = useState(false);

  const [images, setImages] = useState({});

  const [truckData, setTruckData] = useState({});

  useEffect(() => {
    const {
      match: { params },
    } = props;

    const truckEnums = JSON.parse(localStorage.getItem('globalEnum'));
    setTruckData(truckEnums.truck);

    if (params.id) {
      dispatch(showLoading());

      POST(`/trucks/getDetails`, { truck_uuid: params.id })
        .then(({ data }) => {
          // console.log('data', data.truck);
          setImages({
            ...images,
            road_worthiness_certificate: data.truck.road_worthiness_certificate,
            third_party_insurance: data.truck.third_party_insurance,
            vehicle_registration: data.truck.vehicle_registration,
            ownership_proof: data.truck.ownership_proof,
            comercial_vehicle_license: data.truck.comercial_vehicle_license,
            hackney_permit: data.truck.comercial_vehicle_license,
          });
          setRecords(data.truck);
          setReady(true);

          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();
        });
    } else {
      setReady(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!ready || isEmpty(truckData)) {
    return <AppLoader />;
  }
  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-10 col-xl-7 mx-auto">
          <div className="page-title">
            <h1 className="page-heading d-flex align-items-center">
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <svg
                  className="mr-2"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 31.494 31.494"
                  width="28"
                  height="28"
                >
                  <path
                    d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
    c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
    c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                  />
                </svg>
              </span>
              {record ? 'Edit' : 'Create'} Truck
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  reg_number: record ? record.reg_number || '' : '',
                  make: record ? record.make || '' : '',
                  model: record ? record.model || '' : '',
                  year: record ? record.year || '' : '',
                  size: record ? record.size || '' : '',
                  no_of_axel: record ? record.no_of_axel || '' : '',
                  no_of_hooks: record ? record.no_of_hooks || '' : '',
                  npa_safety_certificate_no: record ? record.npa_safety_certificate_no || '' : '',
                  road_worthiness_certificate: null,
                  third_party_insurance: null,
                  vehicle_registration: null,
                  ownership_proof: null,
                  comercial_vehicle_license: null,
                  hackney_permit: null,
                }}
                onSubmit={(values, { resetForm }) => {
                  const {
                    match: { params },
                  } = props;
                  dispatch(showLoading());
                  const payload = { ...values };
                  payload.agent_uuid = params.agentId;
                  if (record) {
                    payload.truck_uuid = record.uuid;
                    // console.log(payload);
                    let editformData = convertJsonToFormData(payload);
                    POST(`/trucks/update`, editformData)
                      .then(({ data }) => {
                        dispatch(hideLoading());
                        dispatch(showModal('Truck data updated', true));
                        history.goBack();
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  } else {
                    let formData = convertJsonToFormData(payload);
                    POST('/trucks/create', formData)
                      .then(({ data }) => {
                        dispatch(hideLoading());
                        dispatch(showModal('Truck created successfully', true));
                        history.goBack();
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  }
                }}
                validationSchema={object().shape({
                  reg_number: string().required('Reg number is required'),
                  make: string().required('Make is required'),
                  model: string().required('Model is required'),
                  year: string().required('Year is required'),
                  size: string().required('Please select size'),
                  no_of_axel: string().required('Please select no of axel'),
                  no_of_hooks: string().required('Please select no of hooks'),
                  npa_safety_certificate_no: string().required('Please select no of hooks'),
                })}
              >
                {(renderProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  } = renderProps;
                  return (
                    <form className="f-c-sm" onSubmit={handleSubmit}>
                      <div className="row">
                        <Fragment>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="">Reg Number</label>
                              <TextInput
                                type="text"
                                name="reg_number"
                                id="reg_number"
                                className="form-control"
                                error={touched.reg_number && errors.reg_number}
                                value={values.reg_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="">Make</label>
                              <TextInput
                                type="text"
                                name="make"
                                id="make"
                                className="form-control"
                                error={touched.make && errors.make}
                                value={values.make}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="">model</label>
                              <TextInput
                                type="text"
                                name="model"
                                id="model"
                                className="form-control"
                                error={touched.model && errors.model}
                                value={values.model}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="">Year</label>
                              <TextInput
                                type="text"
                                name="year"
                                id="year"
                                className="form-control"
                                error={touched.year && errors.year}
                                value={values.year}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="">Size</label>

                              <select
                                className={`form-control ${
                                  getIn(touched, 'size') && getIn(errors, 'size')
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                value={values.size}
                                onBlur={handleBlur}
                                id="size"
                                onChange={handleChange}
                              >
                                <option value="">Select</option>
                                {Object.keys(truckData.size).map((key) => (
                                  <option value={key} key={key}>
                                    {capitalize(truckData.size[key])}
                                  </option>
                                ))}
                              </select>
                              {getIn(touched, 'size') && getIn(errors, 'size') ? (
                                <div className="invalid-feedback">
                                  {getIn(touched, 'size') && getIn(errors, 'size')}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="">No of Axel</label>
                              <select
                                className={`form-control ${
                                  getIn(touched, 'no_of_axel') && getIn(errors, 'no_of_axel')
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                value={values.no_of_axel}
                                onBlur={handleBlur}
                                id="no_of_axel"
                                onChange={handleChange}
                              >
                                <option value="">Select</option>
                                {Object.keys(truckData.axel).map((key) => (
                                  <option value={key} key={key}>
                                    {capitalize(truckData.axel[key])}
                                  </option>
                                ))}
                              </select>

                              {getIn(touched, 'no_of_axel') && getIn(errors, 'no_of_axel') ? (
                                <div className="invalid-feedback ">
                                  {getIn(touched, 'no_of_axel') && getIn(errors, 'no_of_axel')}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="">No of Hooks</label>
                              <select
                                className={`form-control ${
                                  getIn(touched, 'no_of_hooks') && getIn(errors, 'no_of_hooks')
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                value={values.no_of_hooks}
                                onBlur={handleBlur}
                                id="no_of_hooks"
                                onChange={handleChange}
                              >
                                <option value="">Select</option>

                                {truckData.hooks.map((x, index) => (
                                  <option key={index} value={x}>
                                    {x}
                                  </option>
                                ))}
                              </select>

                              {getIn(touched, 'no_of_hooks') && getIn(errors, 'no_of_hooks') ? (
                                <div className="invalid-feedback ">
                                  {getIn(touched, 'no_of_hooks') && getIn(errors, 'no_of_hooks')}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="">Safety Certificate No</label>
                              <TextInput
                                type="text"
                                name="npa_safety_certificate_no"
                                id="npa_safety_certificate_no"
                                className="form-control"
                                error={
                                  touched.npa_safety_certificate_no &&
                                  errors.npa_safety_certificate_no
                                }
                                value={values.npa_safety_certificate_no}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            {images.road_worthiness_certificate ? (
                              <>
                                <div className="form-group">
                                  <label>Select Road Worthiness Certificate</label>

                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${config.api.URL.replace('/api/v1', '')}/${
                                      images.road_worthiness_certificate
                                    }`}
                                  >
                                    Road Worthiness Certificate
                                  </a>

                                  <input
                                    type="file"
                                    name="road_worthiness_certificate"
                                    onChange={(event) =>
                                      setFieldValue(
                                        'road_worthiness_certificate',
                                        event.target.files[0],
                                      )
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="form-group">
                                <label>Select Road Worthiness Certificate</label>

                                <input
                                  type="file"
                                  name="road_worthiness_certificate"
                                  onChange={(event) =>
                                    setFieldValue(
                                      'road_worthiness_certificate',
                                      event.target.files[0],
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-md-6">
                            {images.third_party_insurance ? (
                              <>
                                <div className="form-group">
                                  <label>Select Third Party Insurance</label>

                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${config.api.URL.replace('/api/v1', '')}/${
                                      images.third_party_insurance
                                    }`}
                                  >
                                    Third Party Insurance
                                  </a>

                                  <input
                                    type="file"
                                    name="third_party_insurance"
                                    onChange={(event) =>
                                      setFieldValue('third_party_insurance', event.target.files[0])
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="form-group">
                                <label>Select Third Party Insurance</label>

                                <input
                                  type="file"
                                  name="third_party_insurance"
                                  onChange={(event) =>
                                    setFieldValue('third_party_insurance', event.target.files[0])
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-md-6">
                            {images.vehicle_registration ? (
                              <>
                                <div className="form-group">
                                  <label>Select Vehicle Registration </label>

                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${config.api.URL.replace('/api/v1', '')}/${
                                      images.vehicle_registration
                                    }`}
                                  >
                                    Vehicle Registration
                                  </a>

                                  <input
                                    type="file"
                                    name="vehicle_registration"
                                    onChange={(event) =>
                                      setFieldValue('vehicle_registration', event.target.files[0])
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="form-group">
                                <label>Select Vehicle Registration </label>

                                <input
                                  type="file"
                                  name="vehicle_registration"
                                  onChange={(event) =>
                                    setFieldValue('vehicle_registration', event.target.files[0])
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-md-6">
                            {images.ownership_proof ? (
                              <>
                                <div className="form-group">
                                  <label>Select Ownership Proof </label>

                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${config.api.URL.replace('/api/v1', '')}/${
                                      images.ownership_proof
                                    }`}
                                  >
                                    Ownership Proof
                                  </a>

                                  <input
                                    type="file"
                                    name="ownership_proof"
                                    onChange={(event) =>
                                      setFieldValue('ownership_proof', event.target.files[0])
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="form-group">
                                <label>Select Ownership Proof </label>

                                <input
                                  type="file"
                                  name="ownership_proof"
                                  onChange={(event) =>
                                    setFieldValue('ownership_proof', event.target.files[0])
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-md-6">
                            {images.comercial_vehicle_license ? (
                              <>
                                <div className="form-group">
                                  <label>Select Comercial Vehicle License </label>

                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${config.api.URL.replace('/api/v1', '')}/${
                                      images.comercial_vehicle_license
                                    }`}
                                  >
                                    Comercial Vehicle License
                                  </a>

                                  <input
                                    type="file"
                                    name="comercial_vehicle_license"
                                    onChange={(event) =>
                                      setFieldValue(
                                        'comercial_vehicle_license',
                                        event.target.files[0],
                                      )
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="form-group">
                                <label>Select Comercial Vehicle License </label>

                                <input
                                  type="file"
                                  name="comercial_vehicle_license"
                                  onChange={(event) =>
                                    setFieldValue(
                                      'comercial_vehicle_license',
                                      event.target.files[0],
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-md-6">
                            {images.hackney_permit ? (
                              <>
                                <div className="form-group">
                                  <label>Select Hackney Permit </label>

                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${config.api.URL.replace('/api/v1', '')}/${
                                      images.hackney_permit
                                    }`}
                                  >
                                    Hackney Permit
                                  </a>

                                  <input
                                    type="file"
                                    name="hackney_permit"
                                    onChange={(event) =>
                                      setFieldValue('hackney_permit', event.target.files[0])
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="form-group">
                                <label>Select Hackney Permit </label>

                                <input
                                  type="file"
                                  name="hackney_permit"
                                  onChange={(event) =>
                                    setFieldValue('hackney_permit', event.target.files[0])
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </Fragment>
                      </div>
                      <div className="mt-3">
                        <button type="submit" className="btn btn-sm btn-primary px-5">
                          {record ? 'Update' : 'Save'}
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTruck;
