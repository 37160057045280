import { useCallback, useState } from 'react';
import { addQueryParamToUrl } from './helper';

export const usePagination = (defaultSelectedPage, defaultPerPage, history) => {
  const [selectedPage, setSelectedPage] = useState(defaultSelectedPage || 1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultPerPage || 10);
  const [totalPages, setTotalPages] = useState(0);

  const handleSelected = useCallback(
    ({ selected }) => {
      const page = selected + 1;
      setSelectedPage(page);
      addQueryParamToUrl(history, 'page', page);
    },
    [history],
  );

  const handlePageCountChange = useCallback(
    (e) => {
      const val = e.target.value;
      handleSelected({ selected: 0 });
      addQueryParamToUrl(history, 'perPage', val);
      setItemsPerPage(val);
    },
    [handleSelected, history],
  );

  return {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  };
};
