import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppLoader from '../../../components/loaders/AppLoader';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { LeftArrowSvg } from '../../../helpers/CommanUI';

const ViewJob = (props) => {
  const dispatch = useDispatch();
  const [record, setRecords] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (params.id) {
      dispatch(showLoading());

      POST('/career/careers', {career_uuid: params.id})
        .then(({ data }) => {
          setRecords(data.career);
          setReady(true);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();
        });
    } else {
      setReady(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (!ready) {
    return <AppLoader />;
  }

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
          <div className="page-title">
            <h1 className="page-heading d-flex align-items-center">
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <LeftArrowSvg />
              </span>
              <span>Job Details</span>
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
            <h2>Title: <i>{record.title}</i></h2>
            <h3>Details:</h3>
            <p>{record.details}</p>
            <h3>Key Responsibilities:</h3>
            <ul>
              {
                record.responsibilities && 
                  record.responsibilities.length > 0 && 
                    record.responsibilities.map((rp, RPIndex)=>(<li key={`responsibility-${RPIndex}`}>{rp}</li>))
              }
            </ul>
            <h3>Requirements:</h3>
            <ul>
              {
                record.requirements && 
                  record.requirements.length > 0 && 
                    record.requirements.map((rq, RQIndex)=>(<li key={`requirement-${RQIndex}`}>{rq}</li>))
              }
            </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewJob;
