/* REST */
import { GET, POST } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import history from '../../helpers/history';
import { hideLoading } from '../loader/LoaderActions';
import { refreshToken, trackMouseAndKeyboardActivity } from '../../services/refreshToken';

/* ACTIONS */
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const USER = 'USER';
export const UPDATE_USER = 'UPDATE_USER';

/* LOGIN */
export const login = (payload) => {
  return (dispatch) => {
    POST('/auth/login', payload)
      .then(({ data }) => {
        if (data?.user?.type === 'application_super_admin') {
          localStorage.setItem('_GATKN', data._token);
          localStorage.setItem('OPQW', 'no');

          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              isLoggedIn: true,
              user: data.user,
              message: 'logged in successfully',
            },
          });

          // initialize token refresh interval
          refreshToken();
          trackMouseAndKeyboardActivity();
        } else {
          dispatch({
            type: LOGIN_FAILURE,
            payload: {
              isLoggedIn: false,
              user: {},
              type: undefined,
              message: 'Not Authorized',
            },
          });
          dispatch(showModal('Not Authorized', false));
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        const message = err?.message || 'Something went wrong!';
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            isLoggedIn: false,
            user: {},
            type: undefined,
            message: message,
          },
        });
        dispatch(hideLoading());
        dispatch(showModal(message));
      });
  };
};

export const getLoggedInUser = () => {
  return (dispatch) => {
    const url = `/users/me`;
    GET(url)
      .then(({ data }) => {
        dispatch({
          type: USER,
          payload: {
            user: data,
            type: data.type,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            user: {},
            type: undefined,
            isLoggedIn: false,
            message: err.message,
          },
        });
        localStorage.removeItem('_GATKN');
        dispatch(showModal('Something went wrong. Please log in again'));

        history.push('/');
      });
  };
};
