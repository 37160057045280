import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import DisplayDocument from './DisplayDocument';
import { useDispatch } from 'react-redux';
import { displayAmount } from '../helpers/helper';
import { showModal } from '../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../redux/loader/LoaderActions';
import { POST } from '../services/rest.service';

const DocumentModal = ({ isOpen, toggle, bidId, container, customDuty }) => {
  const [bid, setBid] = useState({});
  const dispatch = useDispatch();

  const getBidDetails = (id) => {
    dispatch(showLoading());
    if (id) {
      const payload = {
        bid_uuid: id,
      };
      POST(`/bids/getBidDetails`, payload)
        .then(({ data }) => {
          // console.log(data.bid);
          setBid(data.bid);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    }
  };

  useEffect(() => {
    if (bidId) getBidDetails(bidId);
  }, [bidId]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody style={{ padding: '0 30px 30px' }}>
        <div className="reson-reject">
          <h5 className="text-center">View Bid</h5>
          <div>
            {bid.job_type === 'clearing' ? (
              <>
                <DisplayDocument
                  label="Clearing Agent ID"
                  value={bid?.agent?.user_id || 'N/A'}
                  type="text"
                  textColClassName="document"
                />
                <DisplayDocument
                  label="Custom Duty"
                  value={displayAmount(customDuty)}
                  type="text"
                  textColClassName="document"
                />
                <DisplayDocument
                  label="Agency and other charges"
                  value={displayAmount(bid.clearing_expenses)}
                  type="text"
                  textColClassName="document"
                />
                <DisplayDocument
                  label="Surcharge"
                  value={displayAmount(bid.surcharge)}
                  type="text"
                  textColClassName="document"
                />
              </>
            ) : (
              <>
                <DisplayDocument
                  label="Transporter ID"
                  value={bid?.agent?.user_id || 'N/A'}
                  type="text"
                  textColClassName="document"
                />
                <DisplayDocument
                  label="Fright Cost"
                  value={displayAmount(bid.fright_cost)}
                  type="text"
                  textColClassName="document"
                />
                <DisplayDocument
                  label="Surcharge"
                  value={displayAmount(bid.surcharge)}
                  type="text"
                  textColClassName="document"
                />
              </>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DocumentModal;
