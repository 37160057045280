import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppLoader from '../../../components/loaders/AppLoader';
import Sidebar from '../../../partials/aside/Sidebar';
// Partials
import Header from '../../../partials/header/Header';
import Dashboard from '../Dashboard/Dashboard';
import JobList from '../JobsManagement/JobList';
import ViewJob from '../JobsManagement/ViewJob';
import AddTruck from '../TruckManagement/AddTruck';
import TruckList from '../TruckManagement/TruckList';
import AddUser from '../UserManagement/AddUser';
import UserList from '../UserManagement/UserList';
// import ClearingAgent from '../AgentManagement/ClearingAgent';
// import FowardingAgent from '../AgentManagement/FowardingAgent';
// import AgentDetails from '../AgentManagement/AgentDetails';
import Settings from '../SettingsManagement/Settings';
import Dispute from '../Dipsutes/Dispute';
import DisputeDetails from '../Dipsutes/DisputeDetails';
import TestimonialList from '../TestimonialManagement/TestimonialList';
import AddEditTestimonial from '../TestimonialManagement/AddEditTestimonial';
import BlogList from '../Blogmanagement/BlogList';
import AddEditBlog from '../Blogmanagement/AddEditBlog';
import ContactUsList from '../ContactUsMessages/List';
import CareerJobsList from '../CareerJobs/JobList';
import AddEditCareerJob from '../CareerJobs/AddEditJob';
import ViewCareerJob from '../CareerJobs/ViewJob';
import CareerApplicationsList from '../CareerApplications/ApplicationsList';
import CareerApplicationView from '../CareerApplications/ViewApplication';
// Application User and Deleted Users List
import SubscribersList from '../Subscribers/List';
import FeedbacksList from '../Feedbacks/List';
import UserDetails from '../UserManagement/Details';
import ChangePassword from '../ChangePassword';
import ActivityLogs from '../ActivityLogs';
import ApprovedUsers from '../ApprovedUsers';

const Layout = (props) => {
  const user = useSelector((state) => state.user);

  const { url } = props.match;

  if (isEmpty(user)) {
    return <AppLoader />;
  }

  return (
    <Fragment>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <Switch>
          <Route exact path={`${url}/dashboard`} component={Dashboard} />

          {/* Category Management */}
          <Route exact path={`${url}/users`} component={UserList} />
          <Route exact path={`${url}/user/add`} component={AddUser} />
          <Route exact path={`${url}/user/edit/:id`} component={AddUser} />
          <Route exact path={`${url}/user/details/:id`} component={UserDetails} />

          {/* Jobs Management */}
          <Route exact path={`${url}/jobs`} component={JobList} />
          <Route exact path={`${url}/job/view/:id`} component={ViewJob} />

          {/* Agent Management */}
          {/* <Route exact path={`${url}/clearingagent/approval`} component={ClearingAgent} />
          <Route exact path={`${url}/fowardingagent/approval`} component={FowardingAgent} />
          <Route exact path={`${url}/agent/details/:id`} component={AgentDetails} /> */}

          {/* Truck Routes */}
          <Route exact path={`${url}/trucks`} component={TruckList} />

          <Route exact path={`${url}/user/:agentId/truck-list`} component={TruckList} />
          <Route exact path={`${url}/user/:agentId/add-truck`} component={AddTruck} />
          <Route exact path={`${url}/user/:agentId/edit-truck/:id`} component={AddTruck} />
          {/** Settings */}
          <Route exact path={`${url}/settings`} component={Settings} />
          {/** Disputes */}
          <Route exact path={`${url}/disputes`} component={Dispute} />
          <Route exact path={`${url}/disputes/details`} component={DisputeDetails} />
          {/* Testimonials */}
          <Route exact path={`${url}/testimonials`} component={TestimonialList} />
          <Route exact path={`${url}/testimonial/add`} component={AddEditTestimonial} />
          <Route exact path={`${url}/testimonial/edit/:id`} component={AddEditTestimonial} />
          {/* Blog */}
          <Route exact path={`${url}/blogs`} component={BlogList} />
          <Route exact path={`${url}/blog/add`} component={AddEditBlog} />
          <Route exact path={`${url}/blog/edit/:id`} component={AddEditBlog} />
          {/* Contact us List */}
          <Route exact path={`${url}/contactus-list`} component={ContactUsList} />
          {/* Career Jobs list */}
          <Route exact path={`${url}/careers`} component={CareerJobsList} />
          <Route exact path={`${url}/career/add`} component={AddEditCareerJob} />
          <Route exact path={`${url}/career/edit/:id`} component={AddEditCareerJob} />
          <Route exact path={`${url}/career/view/:id`} component={ViewCareerJob} />
          {/* Career Jobs list */}
          <Route exact path={`${url}/career-applications`} component={CareerApplicationsList} />
          <Route
            exact
            path={`${url}/career-application/view/:id`}
            component={CareerApplicationView}
          />
          {/* Subscribers list */}
          <Route exact path={`${url}/subscribers`} component={SubscribersList} />

          {/* Feedbacks list */}
          <Route exact path={`${url}/feedbacks`} component={FeedbacksList} />

          {/* Change Password */}
          <Route exact path={`${url}/change-password`} component={ChangePassword} />

          {/* Activity Logs */}
          <Route exact path={`${url}/activity-logs`} component={ActivityLogs} />

          {/* Approved Users */}
          <Route exact path={`${url}/users/approved`} component={ApprovedUsers} />

          <Redirect from={url} to={`${url}/dashboard`} />
        </Switch>
      </div>
    </Fragment>
  );
};

export default Layout;
