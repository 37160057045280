export const user_types = [
  { value: 'managenmnt_operator', label: 'Operator', route: 'operator' },
  { value: 'application_client', label: 'Individual Importer', route: 'client' },
  { value: 'application_corporate_client', label: 'Corporate Importer', route: 'client' },
  { value: 'forwarding_agent', label: 'Transporter', route: 'forwarding-agent' },
  { value: 'clearing_agent', label: 'Clearing Agent', route: 'clearing-agent' },
  { value: 'work_supervisor', label: 'Supervisor', route: 'supervisor' },
  { value: 'legal_lawer', label: 'Compliance Officer', route: 'supervisor' },
  { value: 'truck_inspector', label: 'Trucker Inspector', route: 'truck-inspector' },
  // { value: 'truck_driver', label: 'Truck Driver', route: 'supervisor' },
  // { value: 'application_super_admin', label: 'Application Super Admin', route: 'admin' },
];

export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const emailRegex = /^[a-zA-Z0-9_]([\.-]?[a-zA-Z0-9_])*@[a-zA-Z0-9_]([\.-]?[a-zA-Z0-9_])*(\.[a-zA-Z_]{2,})+$/;

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,16}$/;
