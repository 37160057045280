import React from 'react';
import swal from 'sweetalert';
import history from './history';

export const ViewSvg = () => {
  return (
    <svg id="view" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="Group_93" data-name="Group 93">
        <g id="Group_90" data-name="Group 90">
          <g id="Ellipse_20" data-name="Ellipse 20" fill="none" stroke="#36b7b7" strokeWidth="1.2">
            <circle cx="15" cy="15" r="15" stroke="none" />
            <circle cx="15" cy="15" r="14.4" fill="none" />
          </g>
        </g>
      </g>
      <g id="Group_98" data-name="Group 98" transform="translate(6 9)">
        <g id="Group_95" data-name="Group 95">
          <g id="Group_94" data-name="Group 94">
            <path
              id="Path_60"
              data-name="Path 60"
              d="M265.293,255.633C265.125,255.4,261.118,250,256,250s-9.126,5.4-9.294,5.633a.623.623,0,0,0,0,.733c.168.23,4.176,5.634,9.294,5.634s9.126-5.4,9.294-5.634A.62.62,0,0,0,265.293,255.633ZM256,260.758c-3.77,0-7.036-3.586-8-4.759.965-1.173,4.224-4.758,8-4.758s7.035,3.586,8,4.759C263.036,257.173,259.778,260.758,256,260.758Z"
              transform="translate(-246.586 -250)"
              fill="#36b7b7"
              stroke="#36b7b7"
              strokeWidth="0.1"
            />
          </g>
        </g>
        <g id="Group_97" data-name="Group 97" transform="translate(6.618 3.205)">
          <g id="Group_96" data-name="Group 96">
            <path
              id="Path_61"
              data-name="Path 61"
              d="M256,253.205a2.795,2.795,0,1,0,2.8,2.795A2.8,2.8,0,0,0,256,253.205Zm0,4.658A1.864,1.864,0,1,1,257.862,256,1.865,1.865,0,0,1,256,257.863Z"
              transform="translate(-253.204 -253.205)"
              fill="#36b7b7"
              stroke="#36b7b7"
              strokeWidth="0.2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export const SwicthToggle = (props) => {
  const { onText = 'on', offText = 'off', id, statusValue, changeStatus } = props;

  return (
    <div className="switch">
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          defaultChecked={statusValue}
          onChange={changeStatus}
        />
        <label className="custom-control-label" htmlFor={id}></label>
        <span data-text-on={onText} data-text-off={offText} className="switch-label"></span>
      </div>
    </div>
  );
};

export const DeleteSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
      <g data-name="dlt listing">
        <g data-name="Ellipse 20" fill="none" stroke="#e63757" strokeWidth="1.2">
          <circle cx="15" cy="15" r="15" stroke="none" />
          <circle cx="15" cy="15" r="14.4" />
        </g>
      </g>
      <g data-name="Group 1982" transform="translate(-31.249 -30.995)" fill="#e63757">
        <rect
          data-name="Rectangle 896"
          width="1.005"
          height="5.532"
          rx=".502"
          transform="translate(44.579 44.311)"
        />
        <rect
          data-name="Rectangle 897"
          width="1.005"
          height="5.532"
          rx=".502"
          transform="translate(47.335 44.311)"
        />
        <path
          data-name="Path 3197"
          d="M47.591 38.995h-2.264a1.34 1.34 0 00-1.34 1.34v.825h-3.235a.5.5 0 100 1h.893l.487 8.77a2.176 2.176 0 002.176 2.056h4.308a2.176 2.176 0 002.176-2.056l.487-8.77h.893a.5.5 0 100-1H48.93v-.825a1.34 1.34 0 00-1.34-1.34zm-2.6 1.34a.335.335 0 01.338-.335h2.261a.335.335 0 01.335.335v.825h-2.932zm5.276 1.83l-.483 8.716a1.173 1.173 0 01-1.172 1.107H44.3a1.172 1.172 0 01-1.172-1.107l-.482-8.716z"
        />
      </g>
    </svg>
  );
};

export const AddSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
      <g id="add" transform="translate(0.419 0.319)">
        <g
          id="Ellipse_21"
          data-name="Ellipse 21"
          transform="translate(-0.419 -0.319)"
          fill="none"
          stroke="#0079a7"
          strokeWidth="2"
        >
          <circle cx="20" cy="20" r="20" stroke="none" />
          <circle cx="20" cy="20" r="19" fill="none" />
        </g>
        <path
          id="Path_43"
          data-name="Path 43"
          d="M909.8,154.729a1.48,1.48,0,0,1-1.479-1.479v-5.094h-5.094a1.479,1.479,0,1,1,0-2.958h5.094v-5.094a1.479,1.479,0,0,1,2.958,0V145.2h5.093a1.479,1.479,0,1,1,0,2.958h-5.093v5.094A1.481,1.481,0,0,1,909.8,154.729Z"
          transform="translate(-890.117 -126.998)"
          fill="#0079a7"
          stroke="#fff"
          strokeWidth="0.4"
        />
      </g>
    </svg>
  );
};

export const EditSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    <g id="edit" transform="translate(-0.12 0.143)">
      <g id="Ellipse_20" data-name="Ellipse 20">
        <circle
          id="Ellipse_136"
          data-name="Ellipse 136"
          cx="15"
          cy="15"
          r="15"
          transform="translate(0.121 -0.143)"
          fill="none"
        />
        <circle
          id="Ellipse_137"
          data-name="Ellipse 137"
          cx="14.4"
          cy="14.4"
          r="14.4"
          transform="translate(0.721 0.457)"
          fill="none"
          stroke="#f6c343"
          strokeWidth="1.2"
        />
      </g>
      <path
        id="Path_3198"
        data-name="Path 3198"
        d="M9.815,20.785a.625.625,0,0,1-.6-.767l.856-3.571a.7.7,0,0,1,.167-.3L16.7,9.671a2.543,2.543,0,0,1,3.6,3.6l-6.481,6.481a.631.631,0,0,1-.293.162l-3.574.855A.546.546,0,0,1,9.815,20.785Zm1.468-3.923-.629,2.461,2.358-.561,5.216-5.169-1.84-1.838ZM18.51,10.17a1.268,1.268,0,0,0-.918.384l-.322.318,1.839,1.845.321-.322a1.306,1.306,0,0,0,0-1.843A1.3,1.3,0,0,0,18.51,10.17Z"
        fill="#f6c343"
      />
    </g>
  </svg>
);

export const ActionButton = (props) => {
  const {
    name,
    editRoute,
    onDeleteRecord,
    id,
    showPopUp,
    hideDeleteButton,
    hideEditBtn,
    hideViewBtn,
    onView,
    hideSuspendBtn,
    onSuspend,
    hideRemoveSuspensionBtn,
    onRemoveSuspension,
  } = props;
  const handleEdit = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to change ${name}?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        if (editRoute) {
          history.push(editRoute);
        }
      }
    });
  };
  const handleDelete = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to delete ${name}?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        if (id) {
          if (id !== '1') {
            onDeleteRecord(id);
          }
        }
      }
    });
  };

  const handleSuspend = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to suspend ${name}?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        if (id) {
          onSuspend(id);
        }
      }
    });
  };

  const handleRemoveSuspension = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to remove suspension?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        if (id) {
          onRemoveSuspension(id);
        }
      }
    });
  };

  return (
    <>
      {!hideViewBtn && (
        <button className="btn btn-clear p-0 mr-2" type="button" title="view" onClick={onView}>
          <span>
            <ViewSvg />
          </span>
        </button>
      )}
      {!hideEditBtn && (
        <button
          data-toggle="modal"
          className="btn btn-clear p-0"
          onClick={() => {
            if (showPopUp === false) {
              history.push(editRoute);
            } else {
              handleEdit();
            }
          }}
          title="edit"
        >
          <EditSvg />
        </button>
      )}
      {!hideDeleteButton && (
        <button
          data-toggle="modal"
          className="btn btn-clear p-0 ml-2"
          onClick={() => {
            handleDelete();
          }}
          title="delete"
        >
          <DeleteSvg />
        </button>
      )}
      {!hideSuspendBtn && (
        <button
          data-toggle="modal"
          className="btn btn-primary btn-sm ml-2"
          onClick={() => {
            handleSuspend();
          }}
          title="suspend"
        >
          Suspend
        </button>
      )}
      {!hideRemoveSuspensionBtn && (
        <button
          data-toggle="modal"
          className="btn btn-primary btn-sm ml-2"
          onClick={() => {
            handleRemoveSuspension();
          }}
          title="suspend"
        >
          Remove Suspension
        </button>
      )}
    </>
  );
};

export const LeftArrowSvg = () => (
  <svg
    className="mr-2"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 31.494 31.494"
    width="28"
    height="28"
  >
    <path
      d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
        c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
        c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
    />
  </svg>
);
