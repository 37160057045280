import React from 'react';

const AppLoader = (props) => (
  <div className="loading-overlay">
    <h2>{props.title}</h2>
    <div className="spinner">
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  </div>
);

export default AppLoader;
