import React, { useState, useCallback, useEffect } from 'react';
import { capitalize } from 'lodash';
import { useDispatch } from 'react-redux';
import { EditSvg } from '../../../helpers/CommanUI';
import PortCreateUpdateModal from './PortCreateUpdateModal';
import { GET } from '../../../services/rest.service';
import { getQueryParamValue } from '../../../helpers/helper';
import { usePagination } from '../../../helpers/customHooks';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'Port Name', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

const PortsOfOperation = (props) => {
  const dispatch = useDispatch();

  const [updatePort, setUpdatePort] = useState(null);
  const [portModalAction, setPortModal] = useState('');
  const [ports, setPorts] = useState([]);

  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const getPorts = useCallback(
    (page, perPage) => {
      dispatch(showLoading());
      const params = {
        page: page,
        per_page: perPage,
        sort_by: 'created_at',
        sort_type: 'desc',
      };
      GET('/ports/portofoperations', params)
        .then(({ data }) => {
          setTotalPages(Math.ceil(data.total_count / perPage));
          setPorts(data.portofoperations);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    },
    [dispatch, setTotalPages],
  );

  const gp = useCallback(() => getPorts(selectedPage, itemsPerPage), [
    getPorts,
    selectedPage,
    itemsPerPage,
  ]);

  useEffect(() => {
    getPorts(selectedPage, itemsPerPage);
  }, [getPorts, selectedPage, itemsPerPage]);

  const closePortModal = useCallback(() => {
    setPortModal('');
    setUpdatePort(null);
  }, []);

  const handleCreatePort = useCallback(() => {
    setUpdatePort(null);
    setPortModal('create');
  }, []);

  const handleUpdatePort = useCallback((p) => {
    setUpdatePort(p);
    setPortModal('update');
  }, []);

  return (
    <div>
      <div className="page-content">
        <div className="page-title text-right">
          <button type="button" onClick={handleCreatePort} className="btn btn-sm btn-primary">
            Add New Port
          </button>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead">
                      <tr>
                        {TABLEHEADER.map((x, index) => {
                          return (
                            <th className={x.class} width={x.width} key={index}>
                              {x.name}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    {ports.length > 0 && (
                      <tbody className="tbody">
                        {ports.map((port) => (
                          <tr key={port.uuid}>
                            <td>{capitalize(port.port_of_operation) || 'N/A'}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-clear p-0 mt-1"
                                onClick={() => handleUpdatePort(port)}
                              >
                                <EditSvg width={40} height={40} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                  {ports.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {ports.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <PortCreateUpdateModal
        isOpen={!!portModalAction}
        action={portModalAction}
        toggle={closePortModal}
        port={updatePort}
        refreshTabData={gp}
      />
    </div>
  );
};

export default PortsOfOperation;
