import { Formik, getIn } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { object, ref, string } from 'yup';
import Input from 'react-phone-number-input/input';
import swal from 'sweetalert';
import AppLoader from '../../../components/loaders/AppLoader';
import TextInput from '../../../components/UI/TextInput';
import { user_types } from '../../../helpers/constants';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import config from '../../../../config';
import { LeftArrowSvg } from '../../../helpers/CommanUI';
import PasswordField from '../../../components/Password';

const AddUser = (props) => {
  const dispatch = useDispatch();
  const [record, setRecords] = useState(null);
  const [ready, setReady] = useState(false);
  const [images, setImages] = useState({});
  const [user_status, setUser_status] = useState(1);

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (params.id) {
      dispatch(showLoading());

      GET(`/users/getDetails/${params.id}`)
        .then(({ data }) => {
          if (data.user.type == 'forwarding_agent' || data.user.type == 'clearing_agent') {
            const item = data.documents;
            if (data.user.identity_verified == 1) {
              setUser_status(1);
            } else if (data.user.identity_verified == 0) {
              setUser_status(0);
            } else {
              setUser_status(2);
            }
            // console.log(item);
            setImages({
              ...images,
              incorporation_certificate: item.incorporation_certificate,
              tax_clearance_certificate: item.tax_clearance_certificate,
              memorandum_article_association: item.memorandum_article_association,
              corporate_address_proof: item.corporate_address_proof,
              registration_certificate: item.registration_certificate,
              registration_application_status: item.registration_application_status,
              ctc_cac_1_1: item.ctc_cac_1_1,
              cac_2: item.cac_2,
              cac_7: item.cac_7,
            });
            const modifiedItem = {
              ...data.user,
              rc_no: item.rc_no,
              registration_certificate_no: item.registration_certificate_no,
              tax_identification_no: item.tax_identification_no,
            };
            // console.log(modifiedItem);

            setRecords(modifiedItem);
          } else {
            setRecords(data.user);
          }

          setReady(true);

          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();
        });
    } else {
      setReady(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //approve user
  const hanndleApprove = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Approve?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        const payload = {
          user_uuid: record.uuid,
        };
        POST(`/users/approveDocuments`, payload)
          .then(({ data }) => {
            dispatch(showModal('User Approve Sucessfull!!', true));
            dispatch(hideLoading());
            setUser_status(1);

            // history.push('/admin/users');
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
            // history.goBack();
          });
      }
    });
  };
  //reject user
  const hanndleReject = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Rejected?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        const payloadR = {
          user_uuid: record.uuid,
        };
        POST(`/users/rejectDocuments`, payloadR)
          .then(({ data }) => {
            dispatch(showModal('User Rejected Sucessfull!!', true));
            dispatch(hideLoading());
            setUser_status(2);

            // history.push('/admin/users');
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
            // history.goBack();
          });
      }
    });
  };
  if (!ready) {
    return <AppLoader />;
  }
  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
          <div className="page-title">
            <h1 className="page-heading d-flex align-items-center">
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <LeftArrowSvg />
              </span>
              {record ? 'Edit' : 'Create'} User
            </h1>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  // name: record ? record.name : '',
                  // email: record ? record.email : '',
                  // password: record ? record.password : '',
                  // type: record ? user_types.find((x) => x.value === record.type) || '' : '',
                  // selectedType: record ? record.type : '',
                  // editRecord: record ? true : false,

                  first_name: record ? record.first_name || '' : '',
                  last_name: record ? record.last_name || '' : '',
                  address: record ? record.address || '' : '',
                  mobile_number: record ? record.mobile_number || '' : '',
                  company_name: record ? record.company_name || '' : '',
                  company_address: record ? record.company_address || '' : '',

                  email: record ? record.email || '' : '',
                  password: record ? record.password || '' : '',
                  type: record ? user_types.find((x) => x.value === record.type) || '' : '',
                  selectedType: record ? record.type : 'application_client',
                  editRecord: record ? true : false,

                  clientSelected: record
                    ? record.type !== 'application_corporate_client' ||
                      record.type !== 'forwarding_agent' ||
                      record.type !== 'clearing_agent'
                      ? true
                      : false
                    : true,
                }}
                onSubmit={(values, { resetForm }) => {
                  dispatch(showLoading());
                  const payload = {
                    first_name:
                      values.selectedType !== 'application_corporate_client' ||
                      values.selectedType !== 'clearing_agent' ||
                      values.selectedType !== 'forwarding_agent'
                        ? values.first_name
                        : '',
                    last_name:
                      values.selectedType !== 'application_corporate_client' ||
                      values.selectedType !== 'clearing_agent' ||
                      values.selectedType !== 'forwarding_agent'
                        ? values.last_name
                        : '',
                    address:
                      values.selectedType !== 'application_corporate_client' ||
                      values.selectedType !== 'clearing_agent' ||
                      values.selectedType !== 'forwarding_agent'
                        ? values.address
                        : '',
                    mobile_number: values.mobile_number,
                    company_name:
                      values.selectedType === 'application_corporate_client' ||
                      values.selectedType === 'clearing_agent' ||
                      values.selectedType === 'forwarding_agent'
                        ? values.company_name
                        : '',
                    company_address:
                      values.selectedType === 'application_corporate_client' ||
                      values.selectedType === 'clearing_agent' ||
                      values.selectedType === 'forwarding_agent'
                        ? values.company_address
                        : '',
                    source: '',
                    source_other: '',
                    email: values.email,
                    password: values.password,
                    type: values.selectedType,
                  };
                  if (record) {
                    payload.user_uuid = record.uuid;

                    delete payload.source;
                    delete payload.source_other;
                    delete payload.type;
                    // delete payload.password;

                    POST(`/users/update`, payload)
                      .then(({ data }) => {
                        dispatch(hideLoading());
                        dispatch(showModal('User data updated', true));
                        history.goBack();
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  } else {
                    POST('/users/create', payload)
                      .then(({ data }) => {
                        dispatch(hideLoading());
                        dispatch(showModal('User created successfully', true));

                        history.push('/admin/users');
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  }
                }}
                validationSchema={object().shape({
                  first_name: string().when('clientSelected', {
                    is: true,
                    then: string().required('First name is required'),
                  }),
                  last_name: string().when('clientSelected', {
                    is: true,
                    then: string().required('Last name is required'),
                  }),

                  address: string().when('clientSelected', {
                    is: true,
                    then: string().required('Address is required'),
                  }),

                  company_name: string().when('clientSelected', {
                    is: false,
                    then: string().required('Company name is required'),
                  }),
                  company_address: string().when('clientSelected', {
                    is: false,
                    then: string().required('Address is required'),
                  }),

                  email: string()
                    .email('Enter a valid email')
                    .required('Email is required'),

                  mobile_number: string().required('Mobile No is required'),

                  password: string().when('editRecord', {
                    is: false,
                    then: string()
                      .min(8, 'Password must contain at least 8 characters')
                      .required('Password is required'),
                    otherwise: string().min(8, 'Password must contain at least 8 characters'),
                  }),
                  confirm_password: string().when('editRecord', {
                    is: false,
                    then: string()
                      .required('Please confirm your password')
                      .oneOf([ref('password')], 'Passwords does not match'),
                  }),
                  selectedType: string().when('editRecord', {
                    is: false,
                    then: string().required('Please select user type'),
                  }),
                })}
              >
                {(renderProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  } = renderProps;
                  return (
                    <form className="f-c-sm" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">User Type</label>
                            <Select
                              options={user_types}
                              isMulti={false}
                              name="selectedService"
                              value={values.type}
                              isDisabled={record}
                              onChange={(e) => {
                                if (!record) {
                                  setFieldValue('type', e);
                                  setFieldValue('selectedType', e ? e.value : '');
                                  if (e) {
                                    if (
                                      e.value === 'application_corporate_client' ||
                                      e.value === 'forwarding_agent' ||
                                      e.value === 'clearing_agent'
                                    ) {
                                      setFieldValue('clientSelected', false);
                                    } else {
                                      setFieldValue('clientSelected', true);
                                    }
                                  }
                                }
                              }}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  borderColor:
                                    get(errors, 'selectedType') && get(touched, 'selectedType')
                                      ? 'red'
                                      : '#cccccc',
                                  height: '50px',
                                  boxShadow: 'none',
                                  '&:hover': { borderColor: 'var(--bs-primary)' },
                                }),
                              }}
                            />{' '}
                            {get(errors, 'selectedType') && get(touched, 'selectedType') && (
                              <div className="invalid-feedback  d-block">{errors.selectedType}</div>
                            )}
                          </div>
                        </div>
                        {values.clientSelected ? (
                          <Fragment>
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="">First Name</label>
                                <TextInput
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  className="form-control"
                                  error={touched.first_name && errors.first_name}
                                  value={values.first_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="">Last Name</label>
                                <TextInput
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  className="form-control"
                                  error={touched.last_name && errors.last_name}
                                  value={values.last_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="">Address</label>
                                <TextInput
                                  type="text"
                                  name="address"
                                  id="address"
                                  className="form-control"
                                  error={touched.address && errors.address}
                                  value={values.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="">Company Name</label>
                                <TextInput
                                  type="text"
                                  name="company_name"
                                  id="company_name"
                                  className="form-control"
                                  error={touched.company_name && errors.company_name}
                                  value={values.company_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="">Company Address</label>
                                <TextInput
                                  type="text"
                                  name="company_address"
                                  id="company_address"
                                  className="form-control"
                                  error={touched.company_address && errors.company_address}
                                  value={values.company_address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                          </Fragment>
                        )}

                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Email</label>
                            <TextInput
                              type="email"
                              name="email"
                              id="email"
                              classemail="form-control"
                              error={touched.email && errors.email}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={record}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Mobile</label>

                            <Input
                              value={values.mobile_number}
                              id="mobile_number"
                              className={`form-control ${
                                getIn(touched, 'mobile_number') && getIn(errors, 'mobile_number')
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              onBlur={handleBlur}
                              error={
                                getIn(touched, 'mobile_number') && getIn(errors, 'mobile_number')
                              }
                              onChange={(number) => {
                                setFieldValue('mobile_number', number);
                              }}
                            />
                            {touched.mobile_number && errors.mobile_number && (
                              <div className="invalid-feedback d-block">{errors.mobile_number}</div>
                            )}
                          </div>
                        </div>

                        <Fragment>
                          <div className="col-12 col-md-4">
                            <div className="form-group">
                              <label className="">Password</label>

                              <PasswordField
                                id="password"
                                type="password"
                                name={'password'}
                                placeholder="Password"
                                error={touched.password && errors.password}
                                password={values.password}
                                showPassword={values.showP}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                toggleShowPw={() =>
                                  setFieldValue('showP', values.showP ? false : true)
                                }
                              />
                            </div>
                          </div>
                          {!record && (
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="">Confirm Password</label>
                                <PasswordField
                                  id="confirm_password"
                                  type="password"
                                  name={'confirm_password'}
                                  placeholder="Confirm Password"
                                  error={touched.confirm_password && errors.confirm_password}
                                  password={values.confirm_password}
                                  showPassword={values.showCP}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  toggleShowPw={() =>
                                    setFieldValue('showCP', values.showCP ? false : true)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Fragment>
                      </div>

                      <div className="mt-3 ">
                        <button type="submit" className="btn btn-sm btn-primary px-5">
                          {record ? 'Update' : 'Save'}
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>

          {!isEmpty(images) && (
            <div className="card">
              <div className="card-body">
                <form className="f-c-sm">
                  <div className="row">
                    {record &&
                    (record.type == 'forwarding_agent' || record.type == 'clearing_agent') ? (
                      <>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>Certificate of incorporation:</label>
                            {images.incorporation_certificate && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${
                                  images.incorporation_certificate
                                }`}
                              >
                                Certificate of incorporation
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">RC Number:</label>
                            <label style={{ color: '#36b7b7' }} className="">
                              {record.rc_no}
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Tax Identification Number:</label>
                            <label style={{ color: '#36b7b7' }} className="">
                              {record.tax_identification_no}
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>Tax Clearance Certificate:</label>
                            {images.tax_clearance_certificate && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${
                                  images.tax_clearance_certificate
                                }`}
                              >
                                Tax Clearance Certificate
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>Memorandum and Articles of Association:</label>
                            {images.memorandum_article_association && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${
                                  images.memorandum_article_association
                                }`}
                              >
                                TMemorandum and Articles of Association
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>
                              Proof of corporate address(Utility bills or Tax payment receipt):
                            </label>
                            {images.corporate_address_proof && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${
                                  images.corporate_address_proof
                                }`}
                              >
                                Proof of corporate address
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>Certificate of registration:</label>
                            {images.registration_certificate && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${
                                  images.registration_certificate
                                }`}
                              >
                                Certificate of registratione
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label className="">Certificate of registration number:</label>
                            <label style={{ color: '#36b7b7' }} className="">
                              {record.registration_certificate_no}
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>Status Report (Application for Registration):</label>
                            {images.registration_application_status && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${
                                  images.registration_application_status
                                }`}
                              >
                                Status Report (Application for Registration)
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>CTC of Form CAC 1.1 (Application for Registration):</label>
                            {images.ctc_cac_1_1 && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${
                                  images.ctc_cac_1_1
                                }`}
                              >
                                CTC of Form CAC 1.1 (Application for Registration)
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>
                              Form CAC 2 (Statement of Share Capital and Return of Allotment):
                            </label>
                            {images.cac_2 && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${images.cac_2}`}
                              >
                                Form CAC 2 (Statement of Share Capital and Return of Allotment)
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>Form CAC 7 (Particulars of Directors):</label>
                            {images.cac_7 && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.api.URL.replace('/api/v1', '')}/${images.cac_7}`}
                              >
                                Form CAC 7 (Particulars of Directors)
                              </a>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="row col-12">
                    <div className="mt-3 col-2">
                      <button
                        type="button"
                        disabled={record && record.registration_fee_paid ? false : true}
                        className="btn btn-sm btn-success px-5"
                        onClick={hanndleApprove}
                      >
                        Approve
                      </button>
                    </div>
                    <div className="mt-3 col-2">
                      <button
                        type="button"
                        disabled={record && record.registration_fee_paid ? false : true}
                        className="btn btn-sm btn-danger px-5"
                        onClick={hanndleReject}
                      >
                        Reject
                      </button>
                    </div>
                    <div className="mt-3 col-md-4">
                      <div className="form-group">
                        <label style={{ color: '#36b7b7' }}>
                          {user_status == 0
                            ? 'User Not Verified!'
                            : user_status == 1
                            ? 'User Approved!'
                            : 'User Rejected'}
                        </label>
                      </div>
                    </div>
                    <div className="mt-3 col-md-4">
                      <div className="form-group">
                        <label style={{ color: '#36b7b7' }}>
                          {record && record.registration_fee_paid
                            ? 'User Paid Succesful!'
                            : 'User Payment Pending'}
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddUser;
