import React from 'react';
import DisplayDocument from '../../DisplayDocument';

const DisplayFields = ({ values, showDoc }) => {
  return (
    <div className="mx-2">
      <DisplayDocument
        label="Official gazetted establishment law"
        onClick={() => showDoc(values.official_gazetted_establishment_law)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.official_gazetted_establishment_law ? true : false}
      />
      <DisplayDocument
        label="Corporate Address"
        type="text"
        textColClassName="document"
        value={values.corporate_address}
      />
      <DisplayDocument
        label="Website"
        type="text"
        textColClassName="document"
        value={values.website_url}
      />
      <DisplayDocument label="Email" type="text" textColClassName="document" value={values.email} />
    </div>
  );
};

export default DisplayFields;
