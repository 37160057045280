import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import ErrorSucessModal from './app/components/ErrorSucessModal';
// HOCs
import AppLoader from './app/components/loaders/AppLoader';
// Components
import Login from './app/containers/Login';
import Page404 from './app/containers/Page404';
import history from './app/helpers/history';
import { hideModal } from './app/redux/error/ErrorAction';
import {
  refreshToken,
  logoutAfterInactivity,
  trackMouseAndKeyboardActivity,
} from './app/services/refreshToken';
import { getLoggedInUser } from './app/redux/user/UserActions';
import { GET } from './app/services/rest.service';

import AdminLayoutAsync from './app/containers/admin/layout/Layout';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('_GATKN') ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const getGlobalData = () => {
  GET(`/public/global/vars`)
    .then(({ data }) => {
      localStorage.setItem('globalEnum', JSON.stringify(data));
    })
    .catch((err) => {
      // getGlobalData();
    });
};

const AppRoutes = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const loader = useSelector((state) => state.loading);

  useEffect(() => {
    getGlobalData();
    if (localStorage.getItem('_GATKN')) {
      // refresh immidiately & initialize token refresh interval
      const getUser = () => dispatch(getLoggedInUser());
      refreshToken(true, getUser);
      trackMouseAndKeyboardActivity();
    }
    // check for inactivity and logout if inactive
    logoutAfterInactivity();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggle = () => {
    dispatch(hideModal());
  };

  return (
    <div className="layout">
      {modal.isshow && <ErrorSucessModal onToggle={handleToggle} {...modal} />}
      {loader.isLoading && <AppLoader title={loader.title} />}
      <Router history={history}>
        <Switch>
          <Route path={'/'} exact component={Login} />
          <PrivateRoute path={'/admin'} component={AdminLayoutAsync} />
          <Route component={Page404} />
        </Switch>
      </Router>
    </div>
  );
};

export default AppRoutes;
