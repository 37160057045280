import React from 'react';
import ReactPaginate from './UI/Pagination';

const selectOptions = [10, 20, 30, 40, 50];

const Pagination = (props) => {
  const { pageCount, onPageChange, selectedPage, setItemsPerPage, itemsPerPage } = props;

  return (
    <div className="pagination-row">
      <select
        className="page-count-select"
        id="source"
        name="source"
        value={itemsPerPage}
        onChange={setItemsPerPage}
        title="Page Count"
      >
        {selectOptions.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      <ReactPaginate totalRecords={pageCount} onClick={onPageChange} currentPage={selectedPage} />
    </div>
  );
};

export default Pagination;
