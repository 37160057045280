import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppLoader from '../../../components/loaders/AppLoader';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { LeftArrowSvg } from '../../../helpers/CommanUI';
import config from '../../../../config';

const ViewApplication = (props) => {
  const dispatch = useDispatch();
  const [applicationData, setApplicationData] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [ready, setReady] = useState(false);

  const fetchData = async (applocationId)=>{
    try {
      // fetch Applocation details
      const applicationResp = await POST('/career/viewApplication', {application_uuid: applocationId});
      if(applicationResp?.data?.application?.career_uuid){
        // fetch Job Details
        const  careerResp = await POST('/career/careers', {career_uuid: applicationResp.data.application.career_uuid});
        return {
          careerResp: careerResp.data.career,
          applicationResp: applicationResp.data.application,
        }
      }
      return { applicationResp: applicationResp.data.application }
    } catch (error) {
      return error;
    }
  }
  
  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (params.id) {
      dispatch(showLoading());
      // 
      fetchData(params.id)
      .then((response)=>{
        if(response.applicationResp){
          setApplicationData(response.applicationResp);
        }

        if(response.careerResp){
          setJobData(response.careerResp);
        }
        setReady(true);
        dispatch(hideLoading());
      })
      .catch((err)=>{
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();        
      });
    } else {
      setReady(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!ready) {
    return <AppLoader />;
  }

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
          <div className="page-title">
            <h1 className="page-heading d-flex align-items-center">
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <LeftArrowSvg />
              </span>
              <span>Application Details</span>
            </h1>
          </div>
          <div className="card">
            <div className="card-body applicationDetails">
              {jobData && (<p><h2>Job Title: <i>{jobData.title}</i></h2></p>)}
              <br/>
              {applicationData && (
                <div className="applicantDetails">
                  <p>Name: <b>{applicationData.name}</b></p>
                  <p>Email: <b>{applicationData.email}</b></p>
                  <p>Mobile: <b>{applicationData.mobile}</b></p>
                  <p>
                    <a 
                      href={`${config.api.URL.replace('/api/v1', '')}/${applicationData.resume}`} 
                      target="__blank"
                      >
                        <b>Resume</b>
                      </a>
                    </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewApplication;
