import React, { Component } from 'react';
import queryString from 'query-string';
import { capitalize } from 'lodash';
import { connect } from 'react-redux';
import { ActionButton } from '../../../helpers/CommanUI';
import { user_types } from '../../../helpers/constants';
import {
  CSSSTRING,
  _dateFormatter,
  _timeFormatter,
  addQueryParamToUrl,
  getQueryParamValue,
  getUserName,
} from '../../../helpers/helper';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { DELETE, POST } from '../../../services/rest.service';
// import { ActionButton } from '../Customer/CustomerList';
import Select from 'react-select';
import SuspensionModal from '../Subscribers/SuspensionModal';
import Pagination from '../../../components/Pagination';
import SearchBar from '../../../components/SearchBar';

const TABLEHEADER = [
  { width: '', name: 'ID', value: 'action', class: '' },
  { width: '', name: 'Name', value: 'action', class: '' },
  { width: '', name: 'Email', value: 'action', class: '' },
  { width: '', name: 'Mobile', value: 'action', class: '' },
  { width: '', name: 'Address', value: 'action', class: '' },
  { width: '', name: 'Created At', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

const STATUS_COLUMN = { width: '', name: 'Status', value: 'action', class: '' };

const identityStatusClassname = (status, classnames) => {
  let cn = 'p-2 alert ';
  switch (status) {
    case 0:
      cn += 'alert-info';
      break;
    case 1:
      cn += 'alert-success';
      break;
    case 2:
      cn += 'alert-danger';
      break;
    case 3:
      cn += 'alert-warning';
      break;
  }
  return cn + ' ' + classnames;
};

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      currentPage: getQueryParamValue(this.props.location, 'page') || 0,
      pageLimit: getQueryParamValue(this.props.location, 'perPage') || 10,
      totalRecords: 0,
      isSearching: false,
      sortingSequence: 'desc',
      sortingValue: 'createdAt',
      region: null,
      acceptsMarketing: false,
      userType: user_types[0],
      userId: null,
      showSuspensionModal: false,
      searchText: getQueryParamValue(this.props.location, 'searchText') || '',
    };
  }
  componentDidMount() {
    const queryParams = this.props.location.search;
    let { page } = queryString.parse(queryParams);

    if (page) {
      this.getPage(page);
    } else {
      this.getPage(1);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      const queryParams = this.props.location.search;
      let { page } = queryString.parse(queryParams);

      if (page) {
        this.getPage(page);
      } else {
        this.getPage(1);
      }
    }
  }

  getPage = (page) => {
    const { dispatch } = this.props;
    const queryParams = this.props.location.search;

    let { searchText, type } = queryString.parse(queryParams);

    const { pageLimit } = this.state;
    const searchname = searchText || '';

    dispatch(showLoading());
    let user_type = '';
    let wholeType = {};

    if (user_types.find((x) => x.value === type)) {
      user_type = user_types.find((x) => x.value === type).value;
      wholeType = user_types.find((x) => x.value === type);
    } else {
      user_type = user_types[0].value;
      wholeType = user_types[0];
    }

    const payload = {
      user_type: user_type,
      page: Number(page),
      per_page: pageLimit,
    };

    if (searchText) payload.search = searchText;

    POST(`/users/getUsers`, payload)
      .then(({ data }) => {
        // console.log(data);
        this.setState({
          records: data.users,
          totalRecords: Math.ceil(data.total_count / pageLimit),
          isSearching: false,
          currentPage: Number(page),
          searchText: searchname,
          userType: wholeType,
        });

        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());

        dispatch(showModal(err.message));
      });
  };

  toggleSortingOrder = (column) => {
    if (column !== 'action' && column !== 'status') {
      let seq = '';
      if (this.state.sortingValue === column) {
        seq = this.state.sortingSequence === 'asc' ? 'desc' : 'asc';
      } else {
        seq = 'asc';
      }
      this.setState(
        {
          sortingSequence: seq,
          sortingValue: column,
        },
        () => {
          this.getPage(this.state.currentPage);
        },
      );
    }
  };

  handlePageClick = (e) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.page = e.selected + 1;

    history.push({
      pathname: `/admin/users`,
      search: queryString.stringify(parsed),
    });
  };

  handleSetPageLimit = (e) => {
    const val = e.target.value;
    addQueryParamToUrl(history, 'perPage', val);
    addQueryParamToUrl(history, 'page', 1);
    this.setState({
      pageLimit: val,
      currentPage: 1,
    });
  };

  handleDelete = (id) => {
    const { dispatch } = this.props;
    dispatch(showLoading());

    DELETE(`/users/${id}`)
      .then(() => {
        dispatch(hideLoading());
        dispatch(showModal('User deleted successfully', true));
        this.getPage(this.state.currentPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  changeStatus = (value) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.type = value;
    parsed.page = 1;
    history.push({
      pathname: `/admin/users`,
      search: queryString.stringify(parsed),
    });
  };

  handleSuspend = (id) => {
    this.setState({
      userId: id,
      showSuspensionModal: true,
    });
  };

  handleRemoveSuspension = (u) => {
    const { dispatch } = this.props;
    POST(`/admin/activateUser`, { uuid: u.uuid })
      .then(() => {
        dispatch(hideLoading());
        dispatch(showModal('User Activated successfully', true));
        this.getPage(this.state.currentPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  handleSearch = (text) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.searchText = text;
    parsed.page = 1;
    history.push({
      pathname: `/admin/users`,
      search: queryString.stringify(parsed),
    });
  };

  handleSearchClear = () => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.searchText = '';
    parsed.page = 1;
    history.push({
      pathname: `/admin/users`,
      search: queryString.stringify(parsed),
    });
  };

  render() {
    const { records, sortingSequence, sortingValue } = this.state;

    const hasSuspensionFeature =
      this.state.userType.value === 'application_corporate_client' ||
      this.state.userType.value === 'application_client' ||
      this.state.userType.value === 'clearing_agent' ||
      this.state.userType.value === 'forwarding_agent';

    const tableHeaders = [...TABLEHEADER];
    // for these users we need to show status column also
    if (hasSuspensionFeature) {
      tableHeaders.splice(6, 0, STATUS_COLUMN);
    }
    const serviceData = JSON.parse(localStorage.getItem('globalEnum'));

    return (
      <div className="page-content" id="user-mgt">
        <div className="page-title page-title-flex">
          <h1 className="page-heading">Users</h1>
          <span
            onClick={() => {
              history.push('/admin/user/add');
            }}
            className="btn btn-sm btn-primary"
          >
            Create User
          </span>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group mb-3">
                      <label>User Type</label>
                      <Select
                        options={user_types}
                        isMulti={false}
                        name="selectedService"
                        value={this.state.userType}
                        onChange={(e) => {
                          // console.log('e', e);
                          this.setState(
                            {
                              userType: e,
                            },
                            () => {
                              this.changeStatus(this.state.userType.value);
                            },
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group mb-3">
                      <label>Search User</label>
                      <SearchBar
                        id="search"
                        onSearch={this.handleSearch}
                        onClearSearchText={this.handleSearchClear}
                        value={this.state.searchText}
                        className="height-38px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead">
                      <tr>
                        {tableHeaders.map((x, index) => {
                          return (
                            <th className={x.class} width={x.width} key={index}>
                              {x.name}
                              {x.value !== 'action' && x.value !== 'status' && (
                                <span
                                  className={
                                    x.value !== 'action' && x.value !== 'status'
                                      ? `sort-icon ${CSSSTRING(x, sortingSequence, sortingValue)}`
                                      : 'sort-icon'
                                  }
                                  onClick={() => {
                                    if (x.value !== 'action' && x.value !== 'status') {
                                      this.toggleSortingOrder(x.value);
                                    }
                                  }}
                                ></span>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    {records.length > 0 && (
                      <tbody>
                        {records.map((x, index) => (
                          <tr key={index}>
                            <td>
                              <strong>{x.user_id || 'N/A'}</strong>
                            </td>
                            <td>{getUserName(x)}</td>
                            <td>{x.email}</td>
                            <td>{x.mobile_number}</td>
                            <td>{x.address || x.company_address}</td>
                            <td>
                              <p className="mb-1">{_dateFormatter(x.created_at)}</p>
                              <p className="mb-1">{_timeFormatter(x.created_at)}</p>
                            </td>
                            {hasSuspensionFeature && (
                              <td>
                                <p
                                  className={identityStatusClassname(
                                    x.identity_verified,
                                    'text-center',
                                  )}
                                >
                                  {capitalize(serviceData?.identity_verified[x.identity_verified])}
                                </p>
                              </td>
                            )}
                            <td>
                              {x.type === 'forwarding_agent' && (
                                <button
                                  className="btn btn-primary btn-sm mr-2"
                                  type="button"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/admin/user/${x.uuid}/truck-list`,
                                      state: { agentName: x.company_name },
                                    });
                                  }}
                                >
                                  All Truck
                                </button>
                              )}
                              <ActionButton
                                name={getUserName(x)}
                                id={x.uuid}
                                editRoute={`/admin/user/edit/${x.uuid}`}
                                onDeleteRecord={this.handleDelete}
                                hideViewBtn
                                onSuspend={this.handleSuspend}
                                hideSuspendBtn={!hasSuspensionFeature || x.identity_verified === 3}
                                hideRemoveSuspensionBtn={
                                  !hasSuspensionFeature || x.identity_verified !== 3
                                }
                                onRemoveSuspension={() => this.handleRemoveSuspension(x)}
                              />
                              {hasSuspensionFeature && (
                                <button
                                  data-toggle="modal"
                                  className="btn btn-primary btn-sm ml-2"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/admin/user/details/${x.uuid}`,
                                      state: { agentName: x.company_name },
                                    });
                                  }}
                                  title="suspend"
                                >
                                  View Details
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                  {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {records.length > 0 && (
                <Pagination
                  pageCount={this.state.totalRecords}
                  onPageChange={this.handlePageClick}
                  selectedPage={this.state.currentPage}
                  setItemsPerPage={this.handleSetPageLimit}
                  itemsPerPage={this.state.pageLimit}
                />
              )}
            </div>
            {this.state.showSuspensionModal && (
              <SuspensionModal
                isOpen={this.state.showSuspensionModal}
                toggle={() =>
                  this.setState((s) => {
                    return {
                      ...s,
                      showSuspensionModal: !s.showSuspensionModal,
                    };
                  })
                }
                userId={this.state.userId}
                refreshTabData={() => this.getPage(this.state.currentPage)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps, null)(UserList);
