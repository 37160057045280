import React, { Fragment } from 'react';
import { Formik, FieldArray, getIn, ErrorMessage } from 'formik';
import { object, string, array } from 'yup';
import { v4 as uuidv4 } from 'uuid';
import TextInput from '../../../components/UI/TextInput';

const SocialMediaSettings = ({ onSubmit, settings }) => {
  return (
    <Formik
      initialValues={{
        social_media: settings
          ? settings.social_media || [{ value: '', label: '', icon: '', id: uuidv4() }]
          : [{ value: '', label: '', icon: '', id: uuidv4() }],
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={object().shape({
        social_media: array().of(
          object().shape({
            value: string().required('Social Media Name is required'),
            label: string().required('Social Media Link is required'),
            icon: string().required('Social Media Icon(Font-Awesome Icon) is required'),
          }),
        ),
      })}
    >
      {(renderProps) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        } = renderProps;
        // console.log('>> VTE', { values, touched, errors });
        return (
          <form className="f-c-sm">
            <div className="row">
              <FieldArray
                name={`social_media`}
                render={(arrayHelpers) => (
                  <>
                    {values.social_media?.length > 0 &&
                      values.social_media.map((social, index) => (
                        <Fragment key={index}>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="">Social Media Name</label>
                              <TextInput
                                type="text"
                                name={`social_media.${index}.label`}
                                className="form-control"
                                error={getIn(touched, 'label') && getIn(errors, 'label')}
                                value={social.label}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                containerClasses="mb-0"
                              />
                              <p className="text-danger">
                                <ErrorMessage name={`social_media.${index}.label`} />
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="">Social Media Link</label>
                              <TextInput
                                type="text"
                                name={`social_media.${index}.value`}
                                className="form-control"
                                error={getIn(touched, 'value') && getIn(errors, 'value')}
                                value={social.value}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                containerClasses="mb-0"
                              />
                              <p className="text-danger">
                                <ErrorMessage name={`social_media.${index}.value`} />
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="">Social Media Icon(font-awesome icon)</label>
                              <TextInput
                                type="text"
                                name={`social_media.${index}.icon`}
                                className="form-control"
                                error={getIn(touched, 'icon') && getIn(errors, 'icon')}
                                value={social.icon}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                containerClasses="mb-0"
                              />
                              <p className="text-danger">
                                <ErrorMessage name={`social_media.${index}.icon`} />
                              </p>
                            </div>
                          </div>
                          {values.social_media.length !== 0 &&
                            values.social_media.length - 1 === index && (
                              <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary px-5 settings-action-btn"
                                  onClick={(e) => {
                                    const value = 1;
                                    if (value >= 1) {
                                      let tempSocial = {};
                                      tempSocial.value = '';
                                      tempSocial.label = '';
                                      tempSocial.icon = '';
                                      tempSocial.id = uuidv4();

                                      setFieldValue('social_media', [
                                        ...values.social_media,
                                        tempSocial,
                                      ]);
                                    }
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            )}

                          {values.social_media.length > 1 && (
                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                              <button
                                type="button"
                                className="btn btn-sm btn-danger px-5 px-5 settings-action-btn"
                                onClick={(e) => {
                                  const id = social.id;
                                  // console.log(id);
                                  // console.log(values.social_media);
                                  if (id) {
                                    let tempSocial = [];
                                    tempSocial = values.social_media.filter((obj) => obj.id !== id);
                                    setFieldValue('social_media', tempSocial);
                                  }
                                }}
                              >
                                x
                              </button>
                            </div>
                          )}
                        </Fragment>
                      ))}
                  </>
                )}
              />
            </div>
            <hr />
            <div className="mt-3 ">
              <button
                type="button"
                className="btn btn-sm btn-secondary px-5 mr-3"
                onClick={() => resetForm()}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-sm btn-primary px-5" onClick={handleSubmit}>
                Update
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default SocialMediaSettings;
