import React, { Fragment } from 'react';
import { Formik, FieldArray, getIn, ErrorMessage } from 'formik';
import { object, string, array } from 'yup';
import { v4 as uuidv4 } from 'uuid';
import TextInput from '../../../components/UI/TextInput';

const AdChannelSettings = ({ onSubmit, settings }) => {
  return (
    <Formik
      initialValues={{
        ad_channel: settings?.ad_channel || [
          { label: '', id: uuidv4(), sub_channels: [{ label: '', id: uuidv4() }] },
        ],
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={object().shape({
        ad_channel: array().of(
          object().shape({
            label: string().required('Main Channel Value is required'),
            sub_channels: array().of(
              object().shape({
                label: string().required('Sub Channel Value is required'),
              }),
            ),
          }),
        ),
      })}
    >
      {(renderProps) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        } = renderProps;
        // console.log('>> VTE', { values, touched, errors });
        return (
          <form className="f-c-sm">
            <div className="row">
              <div className="col-12">
                <h3>How did you hear about us?</h3>
                <FieldArray
                  name={`ad_channel`}
                  render={(arrayHelpers) => (
                    <>
                      {values.ad_channel?.length > 0 &&
                        values.ad_channel.map((mainChannel, index) => (
                          <Fragment key={`main_channel-${index}`}>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group">
                                  <label className="">Main Channel {index + 1}</label>
                                  <TextInput
                                    type="text"
                                    name={`ad_channel.${index}.label`}
                                    className="form-control"
                                    error={getIn(touched, 'label') && getIn(errors, 'label')}
                                    value={mainChannel.label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    containerClasses="mb-0"
                                  />
                                  <p className="text-danger">
                                    <ErrorMessage name={`ad_channel.${index}.label`} />
                                  </p>
                                </div>
                              </div>

                              {values.ad_channel.length !== 0 &&
                                values.ad_channel.length - 1 === index && (
                                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary px-5 settings-action-btn"
                                      onClick={(e) => {
                                        let tempMainChannel = {
                                          label: '',
                                          id: uuidv4(),
                                          sub_channels: [
                                            {
                                              label: '',
                                              id: uuidv4(),
                                            },
                                          ],
                                        };

                                        setFieldValue('ad_channel', [
                                          ...values.ad_channel,
                                          tempMainChannel,
                                        ]);
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}

                              {values.ad_channel.length > 1 && (
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger px-5 settings-action-btn"
                                    onClick={(e) => {
                                      const id = mainChannel.id;
                                      if (id) {
                                        let tempMainChannel = [];
                                        tempMainChannel = values.ad_channel.filter(
                                          (obj) => obj.id !== id,
                                        );
                                        setFieldValue('ad_channel', tempMainChannel);
                                      }
                                    }}
                                  >
                                    x
                                  </button>
                                </div>
                              )}
                            </div>
                            <FieldArray
                              name={`ad_channel.${index}.sub_channels`}
                              render={(arrayHelpers) => (
                                <>
                                  {mainChannel.sub_channels?.length > 0 &&
                                    mainChannel.sub_channels.map((subChannel, index2) => (
                                      <div className="pl-4 row" key={`sub_channel-${index2}`}>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                          <div className="form-group">
                                            <label className="">Sub Channel {index2 + 1}</label>
                                            <TextInput
                                              type="text"
                                              name={`ad_channel.${index}.sub_channels.${index2}.label`}
                                              className="form-control"
                                              error={
                                                getIn(touched, 'label') && getIn(errors, 'label')
                                              }
                                              value={subChannel.label}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              containerClasses="mb-0"
                                            />
                                            <p className="text-danger">
                                              <ErrorMessage
                                                name={`ad_channel.${index}.sub_channels.${index2}.label`}
                                              />
                                            </p>
                                          </div>
                                        </div>

                                        {mainChannel.sub_channels.length !== 0 &&
                                          mainChannel.sub_channels.length - 1 === index2 && (
                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-primary px-5 settings-action-btn"
                                                onClick={(e) => {
                                                  let tempSubChannel = {
                                                    label: '',
                                                    id: uuidv4(),
                                                  };

                                                  setFieldValue(
                                                    `ad_channel.${index}.sub_channels`,
                                                    [...mainChannel.sub_channels, tempSubChannel],
                                                  );
                                                }}
                                              >
                                                +
                                              </button>
                                            </div>
                                          )}

                                        {mainChannel.sub_channels.length > 1 && (
                                          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger px-5 settings-action-btn"
                                              onClick={(e) => {
                                                const id = subChannel.id;
                                                if (id) {
                                                  let tempSubChannel = [];
                                                  tempSubChannel = mainChannel.sub_channels.filter(
                                                    (obj) => obj.id !== id,
                                                  );
                                                  setFieldValue(
                                                    `ad_channel.${index}.sub_channels`,
                                                    tempSubChannel,
                                                  );
                                                }
                                              }}
                                            >
                                              x
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                </>
                              )}
                            />
                            <hr />
                          </Fragment>
                        ))}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="mt-3 ">
              <button
                type="button"
                className="btn btn-sm btn-secondary px-5 mr-3"
                onClick={() => resetForm()}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-sm btn-primary px-5" onClick={handleSubmit}>
                Update
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AdChannelSettings;
