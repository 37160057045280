import React, { useState } from 'react';
import { withGetScreen } from 'react-getscreen';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import config from '../../../config';
import history from '../../helpers/history';
import { LOGOUT } from '../../redux/user/UserActions';

export const Header = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = async () => {
    localStorage.removeItem('_GATKN');
    history.replace('/');
    dispatch({
      type: LOGOUT,
    });
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const sideBar = () => {
    const isSidebarOpen = localStorage.getItem('OPQW') === 'no' ? false : true;
    if (!isSidebarOpen) {
      localStorage.setItem('OPQW', 'yes');
      document.body.classList.add('sidebar-mini');
    } else {
      localStorage.setItem('OPQW', 'no');

      document.body.classList.remove('sidebar-mini');
    }
  };

  return (
    <header className="header">
      <div className="header-container">
        <nav className="nav justify-content-between">
          <div className="d-flex">
            <button className="btn" onClick={() => sideBar()}>
              <svg
                id="menu"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="19"
                viewBox="0 0 20 19"
              >
                <rect
                  id="Rectangle_8"
                  data-name="Rectangle 8"
                  width="20"
                  height="2"
                  rx="1"
                  fill="#000"
                />
                <rect
                  id="Rectangle_9"
                  data-name="Rectangle 9"
                  width="20"
                  height="2"
                  rx="1"
                  transform="translate(0 9)"
                  fill="#000"
                />
                <rect
                  id="Rectangle_10"
                  data-name="Rectangle 10"
                  width="15"
                  height="2"
                  rx="1"
                  transform="translate(0 17)"
                  fill="#000"
                />
              </svg>
            </button>
            <div className="navbar-logo">
              <span
                onClick={() => {
                  if (config.portal === 'design') {
                    history.push('/admin/dashboard');
                  }
                }}
                className="navbar-brand c-pointer"
              >
                <img src={'/images/logo-white.png'} alt="" className="img-fluid" />
              </span>
            </div>
          </div>
          <div className="nav-footer">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                caret
                className="nav-link dropdown-no-icon dropdown-toggle p-0 bg-transparent border-0 shadow-none mx-auto"
              >
                <img
                  src={(user.avatar && user.avatar.mediaUrl) || '/images/avatar-1.png'}
                  alt=""
                  className="nav-avatar ml-2"
                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => history.push('/admin/change-password')}>
                  <span className="d-menu-icon">
                    <svg
                      data-name="My Profile"
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                    >
                      <path
                        data-name="Path 3092"
                        d="M6.994 6.067H3.35A3.355 3.355 0 000 9.417a.928.928 0 00.927.927h8.486a.928.928 0 00.927-.927 3.355 3.355 0 00-3.346-3.35zm2.443 3.35a.023.023 0 01-.023.023H.927a.023.023 0 01-.023-.023A2.45 2.45 0 013.35 6.971h3.647a2.45 2.45 0 012.44 2.446z"
                      />
                      <path
                        data-name="Path 3093"
                        d="M5.17 5.622a2.811 2.811 0 10-2.811-2.811A2.814 2.814 0 005.17 5.622zm0-4.718a1.907 1.907 0 11-1.907 1.907A1.91 1.91 0 015.17.904z"
                      />
                    </svg>
                  </span>
                  Change Password
                </DropdownItem>

                <DropdownItem onClick={() => handleLogout()}>
                  <span>
                    <span className="d-menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.762" height="10.726">
                        <g data-name="Group 849">
                          <g data-name="Group 848">
                            <path
                              data-name="Path 1523"
                              d="M5.363 9.832H1.341a.446.446 0 01-.446-.446V1.341a.447.447 0 01.446-.447h4.022a.447.447 0 100-.894H1.341A1.343 1.343 0 000 1.341v8.044a1.342 1.342 0 001.341 1.34h4.022a.447.447 0 100-.894z"
                            />
                          </g>
                        </g>
                        <g data-name="Group 851">
                          <g data-name="Group 850">
                            <path
                              data-name="Path 1524"
                              d="M10.629 5.045L7.912 2.364A.447.447 0 107.285 3l1.941 1.917h-5.2a.447.447 0 000 .894h5.2L7.285 7.728a.447.447 0 10.627.636l2.717-2.681a.446.446 0 000-.636z"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Logout
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </nav>
      </div>
    </header>
  );
};

const options = { mobileLimit: 1080 };

export default withRouter(withGetScreen(Header, options));
