import React from 'react';
import DisplayDocument from '../../DisplayDocument';

const DisplayFields = ({ values, showDoc }) => {
  return (
    <div className="mx-2">
      <DisplayDocument
        label="Operating License issued by NEPZA"
        onClick={() => showDoc(values.nepza_operating_license)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.nepza_operating_license ? true : false}
      />
      <DisplayDocument
        label="CTC of Notice of Situation"
        onClick={() => showDoc(values.ctc_situation_notice)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.ctc_situation_notice ? true : false}
      />
      <DisplayDocument
        label="Particulars of Directors"
        onClick={() => showDoc(values.director_particulars)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.director_particulars ? true : false}
      />
      <DisplayDocument
        label="NEPZA Certificate of Registration"
        onClick={() => showDoc(values.nepza_registration_certificate)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.nepza_registration_certificate ? true : false}
      />
      <DisplayDocument
        label="Statement of Share Capital"
        onClick={() => showDoc(values.share_capital_statement)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.share_capital_statement ? true : false}
      />
      <DisplayDocument
        label="Return of Allotment"
        onClick={() => showDoc(values.return_allotement_statement)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.return_allotement_statement ? true : false}
      />
      <DisplayDocument
        label="NEPZA Number"
        type="text"
        textColClassName="document"
        value={values.nepza_no}
      />
      <DisplayDocument
        label="Memorandum and Articles of Association"
        onClick={() => showDoc(values.memorandum_article_association)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.memorandum_article_association ? true : false}
      />
    </div>
  );
};

export default DisplayFields;
