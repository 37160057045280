import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getQueryParamValue } from '../../../helpers/helper';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { ActionButton } from '../../../helpers/CommanUI';
import config from '../../../../config';
import { usePagination } from '../../../helpers/customHooks';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'Career Job Title', value: 'action', class: '' },
  { width: '', name: 'Name', value: 'action', class: '' },
  { width: '', name: 'Email', value: 'action', class: '' },
  { width: '', name: 'Mobile', value: 'action', class: '' },
  { width: '', name: 'Resume', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

const ApplicationsList = (props) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const getApplicationsList = (page, perPage) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };
    POST(`/career/viewApplication`, payload)
      .then(({ data }) => {
        setTotalPages(data.count / perPage);
        setRecords(data.application);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    getApplicationsList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  const handleDelete = (id) => {
    dispatch(showLoading());
    POST(`/career/deleteApplication`, { application_uuid: id })
      .then(() => {
        dispatch(hideLoading());
        dispatch(showModal('Application deleted successfully', true));
        getApplicationsList(selectedPage, itemsPerPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  return (
    <div className="page-content">
      <div className="page-title page-title-flex">
        <h1 className="page-heading">Applications</h1>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead">
                    <tr>
                      {TABLEHEADER.map((x, index) => {
                        return (
                          <th className={x.class} width={x.width} key={index}>
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {records.length > 0 && (
                    <tbody className="tbody">
                      {records.map((x, index) => (
                        <tr key={`application-${index}`}>
                          <td>{x?.career?.title || 'N/A'}</td>
                          <td>{x.name}</td>
                          <td>{x.email}</td>
                          <td>{x.mobile}</td>
                          <td>
                            <a
                              href={`${config.api.URL.replace('/api/v1', '')}/${x.resume}`}
                              target="__blank"
                            >
                              <b>Resume</b>
                            </a>
                          </td>
                          <td>
                            <ActionButton
                              name="application"
                              id={x.uuid}
                              onDeleteRecord={handleDelete}
                              hideEditBtn={true}
                              onView={() =>
                                history.push(`/admin/career-application/view/${x.uuid}`)
                              }
                              hideSuspendBtn
                              hideRemoveSuspensionBtn
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {records.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsList;
