import { capitalize, debounce, get } from 'lodash';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ActionButton, LeftArrowSvg } from '../../../helpers/CommanUI';
import { user_types } from '../../../helpers/constants';
import { CSSSTRING, addQueryParamToUrl, getQueryParamValue } from '../../../helpers/helper';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { DELETE, GET, POST } from '../../../services/rest.service';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'reg number', value: 'action', class: '' },
  { width: '', name: 'make', value: 'action', class: '' },
  { width: '', name: 'model', value: 'action', class: '' },
  { width: '', name: 'year', value: 'action', class: '' },
  { width: '', name: 'size', value: 'action', class: '' },
  { width: '', name: 'Truck Owner', value: 'action', class: '' },
  { width: '', name: 'no of hooks', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      currentPage: getQueryParamValue(this.props.location, 'page') || 0,
      pageLimit: getQueryParamValue(this.props.location, 'perPage') || 10,
      totalRecords: 0,
      isSearching: false,
      searchparams: '',
      sortingSequence: 'desc',
      sortingValue: 'createdAt',
      region: null,
      acceptsMarketing: false,
      userType: user_types[0],
    };
  }
  componentDidMount() {
    const queryParams = this.props.location.search;
    let { page } = queryString.parse(queryParams);

    if (page) {
      this.getPage(page);
    } else {
      this.getPage(1);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      const queryParams = this.props.location.search;
      let { page } = queryString.parse(queryParams);

      if (page) {
        this.getPage(page);
      } else {
        this.getPage(1);
      }
    }
  }

  getPage = (page) => {
    const { dispatch } = this.props;
    const {
      match: { params },
    } = this.props;
    const queryParams = this.props.location.search;
    let { search } = queryString.parse(queryParams);
    const { searchparams, pageLimit } = this.state;
    const searchname = search || searchparams || '';
    dispatch(showLoading());
    let apiEndPoint = `/trucks/getAllTrucks`;
    if (params.agentId) {
      const payData = {
        agent_uuid: params.agentId,
      };
      POST(`/trucks/getTruckByAgent`, payData)
        .then(({ data }) => {
          this.setState({
            records: data.trucks,
            totalRecords: Math.ceil(data.total_count / pageLimit),
            isSearching: false,
            currentPage: Number(page),
            searchparams: searchname,
          });
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();
        });
    } else {
      GET(apiEndPoint, { page: Number(page), per_page: pageLimit })
        .then(({ data }) => {
          this.setState({
            records: data.trucks,
            totalRecords: Math.ceil(data.total_count / pageLimit),
            isSearching: false,
            currentPage: Number(page),
            searchparams: searchname,
          });
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
          history.goBack();
        });
    }
  };
  debouncesearch = debounce((e) => {
    const {
      match: { params },
    } = this.props;
    const parsed = queryString.parse(this.props.location.search);
    parsed.search = this.state.searchparams;
    parsed.page = 1;
    if (this.state.searchparams !== ' ') {
      history.push({
        pathname: params.agentId ? `/admin/user/${params.agentId}/truck-list` : `/admin/trucks`,
        search: queryString.stringify(parsed),
      });
    } else {
      history.push({
        pathname: params.agentId ? `/admin/user/${params.agentId}/truck-list` : `/admin/trucks`,
        search: queryString.stringify(parsed),
      });
    }
  }, 1000);
  handleChange = (e) => {
    this.setState({ searchparams: e.target.value, isSearching: true });
    this.debouncesearch();
  };

  toggleSortingOrder = (column) => {
    if (column !== 'action' && column !== 'status') {
      let seq = '';
      if (this.state.sortingValue === column) {
        seq = this.state.sortingSequence === 'asc' ? 'desc' : 'asc';
      } else {
        seq = 'asc';
      }
      this.setState(
        {
          sortingSequence: seq,
          sortingValue: column,
        },
        () => {
          this.getPage(this.state.currentPage);
        },
      );
    }
  };

  handlePageClick = (e) => {
    const {
      match: { params },
    } = this.props;
    const parsed = queryString.parse(this.props.location.search);
    parsed.page = e.selected + 1;

    history.push({
      pathname: params.agentId ? `/admin/user/${params.agentId}/truck-list` : `/admin/trucks`,
      search: queryString.stringify(parsed),
    });
  };

  handleSetPageLimit = (e) => {
    const val = e.target.value;
    addQueryParamToUrl(history, 'perPage', val);
    addQueryParamToUrl(history, 'page', 1);
    this.setState({
      pageLimit: val,
      currentPage: 1,
    });
  };

  handleDelete = (id) => {
    const { dispatch } = this.props;
    dispatch(showLoading());

    DELETE(`/trucks/${id}`)
      .then(() => {
        dispatch(hideLoading());
        dispatch(showModal('Truck deleted successfully', true));
        this.getPage(this.state.currentPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  render() {
    const { records, sortingSequence, sortingValue } = this.state;
    const { location } = this.props;
    const {
      match: { params },
    } = this.props;
    const truckEnums = JSON.parse(localStorage.getItem('globalEnum')).truck;

    return (
      <div className="page-content">
        <div className="page-title page-title-flex">
          <h1 className="page-heading d-flex align-items-center">
            {params.agentId && (
              <span
                onClick={() => {
                  history.goBack();
                }}
              >
                <LeftArrowSvg />
              </span>
            )}
            Truck List {params.agentId && `(Agent : ${get(location, 'state.agentName', 'N/A')})`}
          </h1>

          {params.agentId && (
            <span
              onClick={() => {
                history.push(`/admin/user/${params.agentId}/add-truck`);
              }}
              className="btn btn-sm btn-primary"
            >
              Add Truck
            </span>
          )}
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead">
                      <tr>
                        {TABLEHEADER.map((x, index) => {
                          return (
                            <th className={x.class} width={x.width} key={index}>
                              {x.name}
                              {x.value !== 'action' && x.value !== 'status' && (
                                <span
                                  className={
                                    x.value !== 'action' && x.value !== 'status'
                                      ? `sort-icon ${CSSSTRING(x, sortingSequence, sortingValue)}`
                                      : 'sort-icon'
                                  }
                                  onClick={() => {
                                    if (x.value !== 'action' && x.value !== 'status') {
                                      this.toggleSortingOrder(x.value);
                                    }
                                  }}
                                ></span>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    {records.length > 0 && (
                      <tbody>
                        {records.map((x, index) => (
                          <tr key={index}>
                            <td>{x.reg_number || 'N/A'}</td>
                            <td>{x.make || 'N/A'}</td>
                            <td>{x.model || 'N/A'}</td>
                            <td>{x.year || 'N/A'}</td>
                            <td>{truckEnums.size[x.size] || 'N/A'}</td>
                            <td>{capitalize(x?.agent?.company_name) || 'N/A'}</td>
                            <td>{x.no_of_hooks || 'N/A'}</td>
                            <td>
                              <ActionButton
                                name="truck"
                                id={x.uuid}
                                editRoute={`/admin/user/${params.agentId ||
                                  x.agent_uuid}/edit-truck/${x.uuid}`}
                                onDeleteRecord={this.handleDelete}
                                hideViewBtn
                                hideSuspendBtn
                                hideRemoveSuspensionBtn
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                  {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {records.length > 0 && (
                <Pagination
                  pageCount={this.state.totalRecords}
                  onPageChange={this.handlePageClick}
                  selectedPage={this.state.currentPage}
                  setItemsPerPage={this.handleSetPageLimit}
                  itemsPerPage={this.state.pageLimit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps, null)(UserList);
