import React, { useState, useEffect } from 'react';
import { ViewSvg } from '../../../helpers/CommanUI';
import { CSSSTRING, getQueryParamValue } from '../../../helpers/helper';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { capitalize } from 'lodash';
import { useDispatch } from 'react-redux';
import { usePagination } from '../../../helpers/customHooks';
import Pagination from '../../../components/Pagination';

const TABLEHEADER = [
  { width: '', name: 'Job ID', value: 'action', class: '' },
  { width: '', name: 'Job Category', value: 'action', class: '' },
  { width: '', name: 'Job Type', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

const Dispute = (props) => {
  const dispatch = useDispatch();
  const serviceData = JSON.parse(localStorage.getItem('globalEnum'));
  //   console.log(serviceData);
  const [records, setRecords] = useState([]);
  const [sortingSequence, setSortingSequence] = useState('desc');
  const [sortingValue, setSortingValue] = useState('createdAt');
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  useEffect(() => {
    getDisputesList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  const getDisputesList = (page, perPage) => {
    // console.log('type', type);
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };
    POST(`/jobs/getAllDisputes`, payload)
      .then(({ data }) => {
        // console.log('data', data);
        setTotalPages(data.totalRecords / perPage);
        setRecords(data.dispute);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const toggleSortingOrder = (column) => {
    // if (column !== 'action' && column !== 'status') {
    //   let seq = '';
    //   if (this.state.sortingValue === column) {
    //     seq = this.state.sortingSequence === 'asc' ? 'desc' : 'asc';
    //   } else {
    //     seq = 'asc';
    //   }
    //   this.setState(
    //     {
    //       sortingSequence: seq,
    //       sortingValue: column,
    //     },
    //     () => {
    //       this.getPage(this.state.currentPage);
    //     },
    //   );
    // }
  };

  return (
    <div className="page-content">
      <div className="page-title page-title-flex">
        <h1 className="page-heading">Disputes</h1>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead">
                    <tr>
                      {TABLEHEADER.map((x, index) => {
                        return (
                          <th className={x.class} width={x.width} key={index}>
                            {x.name}
                            {x.value !== 'action' && x.value !== 'status' && (
                              <span
                                className={
                                  x.value !== 'action' && x.value !== 'status'
                                    ? `sort-icon ${CSSSTRING(x, sortingSequence, sortingValue)}`
                                    : 'sort-icon'
                                }
                                onClick={() => {
                                  if (x.value !== 'action' && x.value !== 'status') {
                                    toggleSortingOrder(x.value);
                                  }
                                }}
                              ></span>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {records.length > 0 && (
                    <tbody>
                      {records.map((x, index) => (
                        <tr key={index}>
                          <td>
                            {x.job.job_id}-{x.job.is_forwarding_only ? 'F' : 'C'}
                          </td>
                          <td>
                            <h6>{capitalize(serviceData.job.category[x.job.category]) || 'N/A'}</h6>
                            <p>
                              {x.job.type && capitalize(serviceData.job.vehicle_type[x.job.type])}
                            </p>
                            <p>
                              {x.job.type_of_cargo &&
                                capitalize(serviceData.job.type_of_cargo[x.job.type_of_cargo])}
                            </p>
                            {/* <p className="truncate-3 mb-0">{x.description || 'N/A'}</p> */}
                          </td>
                          <td>
                            <p
                              className={`${
                                x.job.is_forwarding_only
                                  ? 'alert  alert-info mt-2 mb-2 p-2'
                                  : 'alert  alert-success mt-2 mb-2 p-2'
                              }`}
                            >
                              {x.job.is_forwarding_only ? 'Transport Service' : 'Clearing Service'}
                            </p>
                          </td>
                          <td>
                            <button
                              className="btn btn-clear p-0 mr-2"
                              type="button"
                              onClick={() => {
                                props.history.push(`/admin/disputes/details?id=${x.uuid}`);
                              }}
                            >
                              <span>
                                <ViewSvg />
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {records.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dispute;
