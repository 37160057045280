import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  _dateFormatter,
  _timeFormatter,
  addQueryParamToUrl,
  getQueryParamValue,
  getUserName,
} from '../../../helpers/helper';
import history from '../../../helpers/history';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import Select from 'react-select';
import Pagination from '../../../components/Pagination';
import { usePagination } from '../../../helpers/customHooks';
import SearchBar from '../../../components/SearchBar';

export const user_types = [
  { value: 'application_client', label: 'Individual Importer', route: 'client' },
  { value: 'application_corporate_client', label: 'Corporate Importer', route: 'client' },
  { value: 'forwarding_agent', label: 'Transporter', route: 'forwarding-agent' },
  { value: 'clearing_agent', label: 'Clearing Agent', route: 'clearing-agent' },
];
const defaultUserType = {
  value: 'application_client',
  label: 'Individual Importer',
  route: 'client',
};

const tableHeaders = [
  { width: '', name: 'ID', value: 'action', class: '' },
  { width: '', name: 'Name', value: 'action', class: '' },
  { width: '', name: 'Email', value: 'action', class: '' },
  { width: '', name: 'Mobile', value: 'action', class: '' },
  { width: '', name: 'Address', value: 'action', class: '' },
  { width: '', name: 'Created At', value: 'action', class: '' },
  { width: '', name: 'Action', value: 'action', class: '' },
];

const ApprovedUsers = (props) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [userType, setUserType] = useState(() => {
    const ut = user_types.find((a) => a.value === getQueryParamValue(props.location, 'utype'));
    return ut || defaultUserType;
  });
  const [searchText, setSearchText] = useState(
    getQueryParamValue(props.location, 'searchText') || '',
  );

  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const getUsers = (page, limit, uType, st) => {
    dispatch(showLoading());
    const payload = {
      user_type: uType?.value || '',
      page: page,
      per_page: limit,
      identity_verified: 1,
    };

    if (st) payload.search = st;

    POST(`/users/getUsers`, payload)
      .then(({ data }) => {
        setRecords(data.users);
        setTotalPages(data.total_count / itemsPerPage);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  useEffect(() => {
    getUsers(selectedPage, itemsPerPage, userType, searchText);
  }, [selectedPage, itemsPerPage, userType, searchText]);

  const handleUserTypeChange = (val) => {
    setUserType(val);
    addQueryParamToUrl(props.history, 'utype', val?.value);
  };

  const handleSearch = (text) => {
    const value = text || '';
    addQueryParamToUrl(props.history, 'searchText', value);
    setSearchText(value);
  };

  const handleSearchClear = () => {
    if (searchText) {
      setSearchText('');
      setRecords([]);
      setSelectedPage(1);
      setTotalPages(0);
      addQueryParamToUrl(props.history, 'searchText', '');
    }
  };

  return (
    <div className="page-content" id="user-mgt">
      <div className="page-title page-title-flex approved-users-heading">
        <h1 className="page-heading mb-0">Approved</h1>
        <span className="ml-3 w-25">
          <Select
            options={user_types}
            isMulti={false}
            name="usertype"
            className="user-type-select"
            value={userType}
            onChange={handleUserTypeChange}
            classNamePrefix="user-type-select"
          />
        </span>
        <span className="ml-sm-3">
          <SearchBar
            id="search"
            onSearch={handleSearch}
            onClearSearchText={handleSearchClear}
            value={searchText}
            placeholder="Search User"
          />
        </span>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead">
                    <tr>
                      {tableHeaders.map((x, index) => {
                        return (
                          <th className={x.class} width={x.width} key={index}>
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {records.length > 0 && (
                    <tbody>
                      {records.map((x, index) => (
                        <tr key={index}>
                          <td>
                            <strong>{x.user_id || 'N/A'}</strong>
                          </td>
                          <td>{getUserName(x)}</td>
                          <td>{x.email}</td>
                          <td>{x.mobile_number}</td>
                          <td>{x.address || x.company_address}</td>
                          <td>
                            <p className="mb-1">{_dateFormatter(x.created_at)}</p>
                            <p className="mb-1">{_timeFormatter(x.created_at)}</p>
                          </td>
                          <td>
                            <button
                              data-toggle="modal"
                              className="btn btn-primary btn-sm ml-2"
                              onClick={() => {
                                history.push({
                                  pathname: `/admin/user/details/${x.uuid}`,
                                  state: { agentName: x.company_name },
                                });
                              }}
                              title="suspend"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {records.length <= 0 && <h3 className={'empty-message'}>Records Not Found</h3>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {records.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedUsers;
