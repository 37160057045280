import React from 'react';
import DisplayDocument from '../../DisplayDocument';

const DisplayFields = ({ values }) => {
  return (
    <div className="mx-2">
      <DisplayDocument
        label="First Name"
        type="text"
        textColClassName="document"
        value={values.first_name}
      />
      <DisplayDocument
        label="Last Name"
        type="text"
        textColClassName="document"
        value={values.last_name}
      />
      <DisplayDocument
        label="Email address"
        type="text"
        textColClassName="document"
        value={values.email}
      />
      <DisplayDocument
        label="Phone number"
        type="text"
        textColClassName="document"
        value={values.mobile_number}
      />
      <DisplayDocument
        label="Date of birth"
        type="text"
        textColClassName="document"
        value={values.dob}
      />
      <DisplayDocument
        label="Nationality"
        type="text"
        textColClassName="document"
        value={values.nationality}
      />
      <DisplayDocument
        label="National Identity No"
        type="text"
        textColClassName="document"
        value={values.national_identity_no}
      />
      <DisplayDocument
        label="Residential Address"
        type="text"
        textColClassName="document"
        value={values.address}
      />
    </div>
  );
};

export default DisplayFields;
