import React from 'react';
import { withGetScreen } from 'react-getscreen';
import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link';

// instructions for adding side menu
// 1. add one similar entry in the below full_sidebar array
// 2. icon - first download svg icon and place it inside public/svg folder (if new icon else just use the icon name)
// 3. add the icon-name to the file _icon.scss at line:25 - $icons variable

const full_sidebar = [
  {
    icon: 'dashboard',
    label: <span>Dashboard</span>,
    to: '/admin/dashboard',
  },
  {
    icon: 'users',
    label: <span>Users</span>,
    to: '/admin/users',
  },
  {
    icon: 'userCheck',
    label: <span>Approved Users</span>,
    to: '/admin/users/approved',
  },
  {
    icon: 'jobs',
    label: <span>Jobs</span>,
    to: '/admin/jobs',
  },
  {
    icon: 'truck',
    label: <span>Trucks</span>,
    to: '/admin/trucks',
  },
  // {
  //   icon: 'userCheck',
  //   label: <span>ClearingAgent Approval</span>,
  //   to: '/admin/clearingagent/approval',
  // },
  // {
  //   icon: 'userCheck',
  //   label: <span>FowardingAgent Approval</span>,
  //   to: '/admin/fowardingagent/approval',
  // },
  {
    icon: 'settings',
    label: <span>Settings</span>,
    to: '/admin/settings',
  },
  {
    icon: 'disputes',
    label: <span>Disputes</span>,
    to: '/admin/disputes',
  },
  {
    icon: 'testimonials',
    label: <span>Testimonials</span>,
    to: '/admin/testimonials',
  },
  {
    icon: 'blog',
    label: <span>Blogs</span>,
    to: '/admin/blogs',
  },
  {
    icon: 'envelope',
    label: <span>Contact Us List</span>,
    to: '/admin/contactus-list',
  },
  {
    icon: 'job',
    label: <span>Career Jobs</span>,
    to: '/admin/careers',
  },
  {
    icon: 'files',
    label: <span>Career Applications</span>,
    to: '/admin/career-applications',
  },
  {
    icon: 'list-ul',
    label: <span>Subscribers</span>,
    to: '/admin/subscribers',
  },
  {
    icon: 'list-ul',
    label: <span>Feedbacks</span>,
    to: '/admin/feedbacks',
  },
  {
    icon: 'list-ul',
    label: <span>Activity Logs</span>,
    to: '/admin/activity-logs',
  },
];

const SideBar = (props) => {
  const closeSidebar = () => {
    if (props.isMobile()) {
      document.body.classList.remove('sidebar-mini');
      localStorage.setItem('OPQW', 'no');
    }
  };
  return (
    <nav className="left-sidebar" id="sidebar">
      <MetisMenu
        content={full_sidebar}
        LinkComponent={RouterLink}
        clasNameLink="nav-item sidebar-right-scroll"
        classNameContainer="sidebar-menu"
        iconNamePrefix="icon-"
        activeLinkFromLocation={true}
        onSelected={() => closeSidebar()}
      />
      <div className="mt-auto"></div>
    </nav>
  );
};

const options = { mobileLimit: 1080 };

export default withGetScreen(SideBar, options);
